
import {stateRegistry} from 'microkernel';

class PatchDealerLinks {
	constructor() {
		if (SETUPS.get('scope.nemo.editorial.path.entryservice.links.dealer') === true) {
			this._initialize();
			this._onStateUpdate = this._onStateUpdate.bind(this);
			this._patchDealerLinks = this._patchDealerLinks.bind(this);
			this._markLinksWithPlaceholderAsProcessable = this._markLinksWithPlaceholderAsProcessable.bind(this);
			this._processLinksWithPlaceholder = this._processLinksWithPlaceholder.bind(this);
			this._getLinksToPatchWithGivenAttribute = this._getLinksToPatchWithGivenAttribute.bind(this);
			document.addEventListener('PAGE_READY', this._patchDealerLinks);
			document.addEventListener('LAYER_LOADED', this._patchDealerLinks);
			stateRegistry.subscribeToStore('dbadDealerStore', this._onStateUpdate);
		}
	}

	_initialize() {
		this.kvpsidPlaceholder = '{kvpsid}';
		this.kvpsid = '';
		this.kvpsSyncIdPlaceholder = '{kvpsSyncId}';
		this.kvpsSyncId = '';
	}

	/**
	 * handle a state update
	 * @param {Object} state_ - state of the dbadDealerStore
	 * @returns {void}
	 */
	_onStateUpdate(state_) {
		this.kvpsid = state_.kvpsid || '';
		this.kvpsSyncId = state_.kvpsSyncId || '';

		this._patchDealerLinks();
	}

	/**
	 * patch the dealer links
	 * @returns {void}
	 */
	_patchDealerLinks() {
		this._markLinksWithPlaceholderAsProcessable('href', 'data-href-placeholder-url');
		this._markLinksWithPlaceholderAsProcessable('data-link-pattern', 'data-pattern-placeholder-url');
		this._processLinksWithPlaceholder('href', 'data-href-placeholder-url');
		this._processLinksWithPlaceholder('data-link-pattern', 'data-pattern-placeholder-url');
	}

	/**
	 * copy the href of PlaceholderLinks to a data-attribute to persistently mark those as
	 * patch candidates on state Update
	 * @param {string} originalAttribute_ - the original attribute to save
	 * @param {string} preservingAttribute_ - ata-attribute to keep the originalAttribute value
	 * @returns {void}
	 */
	_markLinksWithPlaceholderAsProcessable(originalAttribute_, preservingAttribute_) {
		const allOccurrences = this._getLinksToPatchWithGivenAttribute(originalAttribute_);

		allOccurrences.forEach(link => {
			link.setAttribute(preservingAttribute_, link.getAttribute(originalAttribute_));
		});
	}

	/**
	 * process links which match placeholder
	 * @param {string} originalAttribute_ - the original attribute to save
	 * @param {string} preservingAttribute_ - ata-attribute to keep the originalAttribute value
	 * @returns {void}
	 */
	_processLinksWithPlaceholder(originalAttribute_, preservingAttribute_) {
		const allProcessableOccurrences = this._getLinksToPatchWithGivenAttribute(preservingAttribute_);

		allProcessableOccurrences.forEach(link => {
			const patchedURL = link.getAttribute(preservingAttribute_)
				.replace(new RegExp(this.kvpsidPlaceholder, 'g'), this.kvpsid)
				.replace(new RegExp(this.kvpsSyncIdPlaceholder, 'g'), this.kvpsSyncId);
			link.setAttribute(originalAttribute_, patchedURL);
		});
	}

	/**
	 * collect either the links with placeholders or the links flagged as replaceble
	 * depending on the given attribute in a unique aray
	 * @param {string} attribute_ - enum: 'href' | 'data-href-placeholder-url'
	 * @returns {array} - array of single (unique) nodes from DOM
	 */
	_getLinksToPatchWithGivenAttribute(attribute_) {
		const linksToPatch = [...document.querySelectorAll('a['+attribute_+'*="' + this.kvpsidPlaceholder + '"], a['+attribute_+'*="' + this.kvpsSyncIdPlaceholder + '"]')];

		return linksToPatch;
	}
}

const patchDealerLinks = new PatchDealerLinks();
export {patchDealerLinks, PatchDealerLinks};
