import {modalLayer} from 'core-application';
import {messageLayerTemplate} from './message-layer-template';
import {template} from 'core-utils';

export default class MessageLayerElement extends HTMLElement {

	constructor(data_ = {}) {
		super();
		this._bindContextToFunctions();
		this.data = Object.assign({}, data_.dataset);

		if (this.data.useTextResourceKeys) {
			this.data.message = window.i18n[this.data.message];
			this.data.headline = window.i18n[this.data.headline];
			this.data.actionButtonLinkText = window.i18n[this.data.actionButtonLinkText];
		}
	}

	/**
	 * Returns the identificator for this type of layer
	 * @returns {string} Identificator
	 */
	static get type() {
		return 'MessageLayerElement';
	}

	/**
	 * Returns a promise that resolves to an instance of ModalWltpErrorLayerElement
	 * @param {Object} data_ Modal layer content data
	 * @returns {Promise} The promise, that resolves to an instance of ModalWltpErrorLayerElement
	 */
	static async getContent(data_) {
		return new MessageLayerElement(data_);
	}

	/**
	 * Runs automatically when Element is hook'd on the DOM
	 * @returns {void} returns nothing
	 */
	connectedCallback() {
		this._render();
	}

	/**
	 * _bindContextToFunctions - bind 'this' context to necessary functions
	 * @returns {void} returns nothing
	 */
	_bindContextToFunctions() {
		this._closeLayer = this._closeLayer.bind(this);
		this._render = this._render.bind(this);
	}

	/**
	 * _render
	 * @returns {void} returns nothing
	 */
	_render() {
		this.innerHTML = template.render(messageLayerTemplate, this.data);
	}

	/**
	 * _closeLayer
	 * @returns {void} returns nothing
	 */
	_closeLayer() {
		this._triggerClose();
	}
}

modalLayer.registerLayerType(MessageLayerElement);

if (window.customElements.get('audi-modal-layer-message-element') === undefined) {
	window.customElements.define('audi-modal-layer-message-element', MessageLayerElement);
}
