/* global Bootstrapper */
/**
 * CookieModal singleton
 */
export default class CookieModal {
	constructor() {
		if (!window.Bootstrapper) {
			return;
		}
		this._initialize();
	}

	/**
	 * @returns {void}
	 * @private
	*/
	_initialize() {
		this._handleClick = this._handleClick.bind(this);
		this._bindListenerToCookieLinks();
	}

	/**
	 * binds Event Listener to Cookie Links
	 * @returns {void}
	 * @private
	 */
	_bindListenerToCookieLinks() {
		const linkElements = document.querySelectorAll('.audi-cookie-modal-link');
		[...linkElements].forEach(linkItem => {
			linkItem.addEventListener('click', this._handleClick);
		});
	}

	/**
	 * handles click on specfic links
	 * @param {Event} event_ - The Click Event
	 * @private
	 * @returns {void}
	*/
	_handleClick(event_) {
		event_.preventDefault();
		if (!window.Bootstrapper || window.Bootstrapper === null) {
			return;
		}

		if (Bootstrapper.gateway && Bootstrapper.gateway.openModal) {
			Bootstrapper.gateway.openModal();
			document.body.scrollTop = 0;
		}
		else {
			console.error('Unable to call Bootstrapper.gateway.openModal');
		}
	}
}

export const cookieModal = new CookieModal();
