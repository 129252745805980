import {appEvents as EVENTS} from 'core-application';
const __ = {},
	// Public API
	exports = {
		__: __
	};
/**
 * @property {Object} store for all Model values
 */
__.categories = [];
__.items = [];
__.selectedItem = null;
__.selectedCategory = '';
// get global EventEmitter Instance
// get all items filteres by the current slected category
__.getCurrentItems = function() {
	if (__.selectedCategory === 'all') {
		return __.items;
	}
	let filteredItems = [];
	for (let i = 0, len = __.items.length; i < len; i++) {
		if (__.items[i].category === __.selectedCategory) {
			filteredItems.push(__.items[i]);
		}
	}
	return filteredItems;
};

exports.setAllCategories = function(catArr) {
	__.selectedCategory = '';
	__.categories = catArr;
};

exports.getCategories = function() {
	return __.categories;
};

exports.setAllItems = function(itemsArr) {
	__.selectedItem = null;
	__.items = itemsArr;
};

exports.getAllItems = function() {
	return __.items;
};

exports.getSelectedCategory = function() {
	return __.selectedCategory;
};

exports.setSelectedCategory = function(category_) {
	let changed = false;
	let previousCategory = __.selectedCategory;
	if (category_ === __.selectedCategory) {
		changed = false;
	}
	else {
		__.selectedCategory = category_;
		changed = true;
	}
	// first run…
	if (!previousCategory) {
		changed = false;
	}
	__.eventBus.emit(EVENTS.MEDIAGALLERY_CATEGORY_CHANGED, {
		category: category_,
		changed: changed
	});
};

exports.getSelectedItem = function() {
	return __.selectedItem;
};

exports.setSelectedItem = function(item_) {
	let changed = false;
	let previousItem = __.selectedItem;
	if (item_ === __.selectedItem) {
		changed = false;
	}
	else {
		__.selectedItem = item_;
		changed = true;
	}
	// first run…
	if (!previousItem) {
		changed = false;
	}
	let index = exports.getItemIndex();
	let previousIndex = (!!previousItem) ? exports.getItemIndex(previousItem) : index;
	let maxIndex = exports.getMaxIndex();
	let direction = (previousIndex < index) ? 'next' : 'back';
	if (!changed || index === previousIndex) {
		direction = 'none';
	}
	__.eventBus.emit(EVENTS.MEDIAGALLERY_ITEM_CHANGED, {
		'item': item_,
		'index': index,
		'maxIndex': maxIndex,
		'direction': direction,
		'changed': changed
	});
};

exports.getMaxIndex = function() {
	return __.getCurrentItems().length - 1;
};

// get an item by its id
exports.getItemById = function(id_) {
	return __.getItemByAttributeValue('id', id_);
};

// get an item by its image URL
exports.getItemByImageurl = function(imageUrl_) {
	return __.getItemByAttributeValue('imageurl', imageUrl_);
};

// get an item by a specific attribute nad value
__.getItemByAttributeValue = function(attr_, value_) {
	for (let i = 0, len = __.items.length; i < len; i++) {
		if (__.items[i][attr_] === value_) {
			return __.items[i];
		}
	}
	return null;
};

exports.getNextItem = function(index_) {
	var maxIndex = exports.getMaxIndex();
	let index=index_;
	if (index < maxIndex) {
		index++;
	}
	else {
		index = 0;
	}
	return exports.getItemByIndex(index);
};

exports.getPreviousItem = function(index_) {
	var maxIndex = exports.getMaxIndex();
	let index=index_;
	if (index_ > 0) {
		index--;
	}
	else {
		index = maxIndex;
	}
	return exports.getItemByIndex(index);
};
// get an item by its index
exports.getItemByIndex = function(index_) {
	var items = __.getCurrentItems();
	if (!items || items.length < 1 || items.length < index_) {
		return null;
	}
	return items[index_] || null;
};
// get the index of the current selected item
exports.getItemIndex = function(item_) {
	var currentItem = item_ || __.selectedItem;
	var index = -1;
	var curentItems = __.getCurrentItems();
	for (let i = 0, len = curentItems.length; i < len; i++) {
		if (curentItems[i].id === currentItem.id) {
			index = i;
			return index;
		}
	}
	return index;
};

exports.initialize = function(eventEmitter_) {
	__.eventBus = eventEmitter_;
};
export {exports as mediagalleryModel};
