import {appEvents as EVENTS} from 'core-application';
import {dom as DOM_UTILS} from 'core-utils';
import {ScrollSnap} from 'core-vendor';

const __ = {},
	exports = {
		__: __
	};

/**
 * oDefaults deafauts objext for strings and classes
 * @type {Object}
 */
__.oDefaults = {
	selectorModuleRenderStage: '.nm-md-render-stage',
	selectorImagesInputs: '.nm-render-stage-inputs',
	selectorThumbnails: '.nm-render-stage-thumbnail-item-wrap',
	selectorThumbnailsContainer: '.nm-render-stage-thumbnails',
	selectorThumbnailsWrapper: '.nm-render-stage-thumbnails-wrapper',
	selectorRenderStage: '.nm-render-stage',
	selectorStageItem: '.nm-render-stage-item-wrap',
	selectorNavigationPrev: '.nm-render-stage-prev',
	selectorNavigationNext: '.nm-render-stage-next',
	selectorThumbnailNavigationPrev: '.nm-render-stage-thumbnail-prev',
	selectorThumbnailNavigationNext: '.nm-render-stage-thumbnail-next',
	selectorInitialRenderImage: '[data-is-initial]'
};

/**
 * renderStages - holds all renderStage instances
 * @type {Array}
 */
__.renderStages = [];

// SNAPPOINTS POLYFILL
__.isScrollSnapSupported =
	'scrollSnapType' in document.documentElement.style ||
	'webkitScrollSnapType' in document.documentElement.style ||
	!('classList' in document.createElement('_'));
// if browser already supports CSS scroll Snap Points, or it is too old:
__.nonPolyfillable = __.isScrollSnapSupported || !Element.prototype.addEventListener || !window.requestAnimationFrame;

/**
 * RenderStage Class constructor
 * @param {HtmlElement} context context
 * @returns {void} returns nothing
 */
// eslint-disable-next-line max-statements
__.RenderStage = function(context) {
	this.context = context;
	this.imageInputs = DOM_UTILS.getElementsArray(__.oDefaults.selectorImagesInputs, this.context);
	this.maxIndex = this.imageInputs.length;
	this.scrollContainer = DOM_UTILS.getElement(__.oDefaults.selectorRenderStage, this.context);
	this.scrollPosition = 0;
	this.scrollContainerItem = DOM_UTILS.getElement(__.oDefaults.selectorStageItem, this.context);
	this.thumbnails = DOM_UTILS.getElementsArray(__.oDefaults.selectorThumbnails, this.context);
	this.thumbnailsContainer = DOM_UTILS.getElement(__.oDefaults.selectorThumbnailsContainer, this.context);
	this.thumbnailsWrapper = DOM_UTILS.getElement(__.oDefaults.selectorThumbnailsWrapper, this.context);
	this.currentIndex = 0;
	this.slides = DOM_UTILS.getElementsArray(__.oDefaults.selectorStageItem, this.context);
	this.navNext = DOM_UTILS.getElement(__.oDefaults.selectorNavigationNext, this.context);
	this.navPrev = DOM_UTILS.getElement(__.oDefaults.selectorNavigationPrev, this.context);
	this.thumbnailNavNext = DOM_UTILS.getElement(__.oDefaults.selectorThumbnailNavigationNext, this.context);
	this.thumbnailNavPrev = DOM_UTILS.getElement(__.oDefaults.selectorThumbnailNavigationPrev, this.context);

	this.initialRenderImage = DOM_UTILS.getElement(__.oDefaults.selectorInitialRenderImage, this.context);
	if (this.initialRenderImage) {
		this.currentIndex = this.initialRenderImage.getAttribute('data-index') - 1;
		if (this.currentIndex > 0) {
			this.jumpToImage(this.currentIndex);
		}
	}
	this.scrollContainer.classList.remove('nm-render-stage--loading');
};

/**
 * jumpToImage - jumpToImage without animation
 * @param {Number} newIndex_ stage-image index to jump to
 * @return {void} returns nothing
 */
__.RenderStage.prototype.jumpToImage = function(newIndex_) {
	const scrollToPosition = this.scrollContainerItem.clientWidth * newIndex_;

	this.scrollContainer.classList.add('nm-render-stage--no-animation');
	this.scrollContainer.scrollLeft = scrollToPosition;
	this.handleNavigationVisibility(this.slides, this.scrollContainer, this.navPrev, this.navNext);
	this.changeInputPos(newIndex_);
	this.scrollContainer.classList.remove('nm-render-stage--no-animation');
};

/**
 * navigationNextArrowClickHandler handler for next arrow click
 * @return {void} returns nothing
 */
__.RenderStage.prototype.navigationNextArrowClickHandler = function() {
	if (this.currentIndex < this.maxIndex) {
		this.currentIndex++;
		this.scrollToImage(this.currentIndex);
	}
	else {
		return;
	}
};

/**
 * navigationPrevArrowClickHandler handler for prev arrow click
 * @return {void} returns nothing
 */
__.RenderStage.prototype.navigationPrevArrowClickHandler = function() {
	if (this.currentIndex > 0) {
		this.currentIndex--;
		this.scrollToImage(this.currentIndex);
	}
	else {
		return;
	}
};

/**
 * thumbnailClickhandler handles stage thumbnail clicks
 * @param {Event} event click event
 * @return {void} returns nothing
 */
__.RenderStage.prototype.thumbnailClickhandler = async function(event) {
	event.preventDefault();
	let newIndex = 0;
	const clickedItem = event.target;

	this.thumbnails.forEach((thumbItem, index) => {
		if (thumbItem === clickedItem) {
			newIndex = index;
		}
	});

	this.currentIndex = newIndex;
	await this.scrollToImage(newIndex);
};

__.RenderStage.prototype.intersectionHandler = function() {
	if (typeof IntersectionObserver !== 'undefined') {
		const io = new IntersectionObserver(
			entries => {
				if (entries[0].intersectionRatio >= 0.25) {
					const intersectedSlideIndex = entries[0].target.getAttribute('data-index') - 1;
					this.changeInputPos(intersectedSlideIndex);
					this.handleNavigationVisibility(this.slides, this.scrollContainer, this.navPrev, this.navNext);
				}
			},
			{root: this.scrollContainer, threshold: 0.25}
		);

		this.slides.forEach(slide => {
			io.observe(slide);
		});
	}
};

/**
 * thumbnailsScrollHandler handles thumbnail container scroll
 * @returns {void} returns nothing
 */
__.RenderStage.prototype.thumbnailsScrollHandler = function() {
	this.handleNavigationVisibility(
		this.thumbnails,
		this.thumbnailsContainer,
		this.thumbnailNavPrev,
		this.thumbnailNavNext,
		true
	);
};

/**
 * thumbnailsThrottleHandler
 * @return {function} returns throttle thumbnails scroll handler function
 */
__.RenderStage.prototype.thumbnailsThrottleHandler = function() {
	return DOM_UTILS.throttle(this.thumbnailsScrollHandler.bind(this), 15);
};

/**
 * thumbnailNavigationNextClickhandler - handles click on thumbnail navigation next arrow
 * @return {void} returns nothing
 */
__.RenderStage.prototype.thumbnailNavigationNextClickhandler = function() {
	this.translateThumbnailContainer('next');
};

/**
 * thumbnailNavigationPrevClickhandler - handles click on thumbnail navigation prev arrow
 * @return {void} returns nothing
 */
__.RenderStage.prototype.thumbnailNavigationPrevClickhandler = function() {
	this.translateThumbnailContainer('prev');
};

/**
 * addStageEvents adds events only for specific render stage object
 * @return {void} returns nothing
 */
/* eslint-disable max-statements */
__.RenderStage.prototype.addStageEvents = function() {
	// override events to bind this and get the possibility to remove each event
	this.navigationNextArrowClickHandler = this.navigationNextArrowClickHandler.bind(this);
	this.navigationPrevArrowClickHandler = this.navigationPrevArrowClickHandler.bind(this);
	this.thumbnailClickhandler = this.thumbnailClickhandler.bind(this);
	this.thumbnailNavigationNextClickhandler = this.thumbnailNavigationNextClickhandler.bind(this);
	this.thumbnailNavigationPrevClickhandler = this.thumbnailNavigationPrevClickhandler.bind(this);
	this.thumbnailsThrottleHandler = this.thumbnailsThrottleHandler();

	// add events
	this.navNext.addEventListener('click', this.navigationNextArrowClickHandler);
	this.navPrev.addEventListener('click', this.navigationPrevArrowClickHandler);
	this.thumbnailsContainer.addEventListener('click', this.thumbnailClickhandler);
	this.thumbnailNavNext.addEventListener('click', this.thumbnailNavigationNextClickhandler);
	this.thumbnailNavPrev.addEventListener('click', this.thumbnailNavigationPrevClickhandler);
	this.thumbnailsContainer.addEventListener('scroll', this.thumbnailsThrottleHandler);

	this.intersectionHandler = this.intersectionHandler.bind(this);
	this.intersectionHandler();

	// SNAPPOINTS POLYFILL
	if (!__.nonPolyfillable) {
		this.addScrollSnapPolyfill();
	}
};

__.RenderStage.prototype.addScrollSnapPolyfill = function() {
	const snapConfig = {
		scrollSnapDestination: '100% 0%',
		scrollTimeout: 50,
		scrollTime: 300
	};

	const element = DOM_UTILS.getElement('.nm-render-stage', this.context);
	this.snapObject = new ScrollSnap(element, snapConfig);
	this.handleScrollSnapEnd = this.handleScrollSnapEnd.bind(this);
	this.snapObject.bind(this.handleScrollSnapEnd);
};

__.RenderStage.prototype.handleScrollSnapEnd = function() {
	this.handleNavigationVisibility(this.slides, this.scrollContainer, this.navPrev, this.navNext);
};

/**
 * removeStageEvents removes instance functions of specific renderStage object
 * @return {void} returns nothing
 */
__.RenderStage.prototype.removeStageEvents = function() {
	// remove events
	this.navNext.removeEventListener('click', this.navigationNextArrowClickHandler);
	this.navPrev.removeEventListener('click', this.navigationPrevArrowClickHandler);
	this.thumbnailsContainer.removeEventListener('click', this.thumbnailClickhandler);
	this.thumbnailNavNext.removeEventListener('click', this.thumbnailNavigationNextClickhandler);
	this.thumbnailNavPrev.removeEventListener('click', this.thumbnailNavigationPrevClickhandler);
	this.scrollContainer.removeEventListener('scroll', this.throttleHandler);
	this.thumbnailsContainer.removeEventListener('scroll', this.thumbnailsThrottleHandler);
	if (this.snapObject) {
		this.snapObject.unbind(this.handleScrollSnapEnd);
	}
};

/**
 * getVisibleThumbnailItems find all visible items in thumbnail container
 * @return {Array<HtmlElement>} visibleItems returns array with all visible thumnail items
 */
__.RenderStage.prototype.getVisibleThumbnailItems = function() {
	var that = this,
		visibleItems = [];
	// loop all thumbnail items
	that.thumbnails.forEach(function(thumbnail) {
		// if item is full or partially visible, add to visible items array
		if (DOM_UTILS.isVisible(thumbnail, that.thumbnailsContainer, false)) {
			visibleItems.push(thumbnail);
		}
	});
	return visibleItems;
};

/**
 * calculateNewScrollPosition calculates scroll position depending on direction
 * @param {string} direction_ direction to scroll e.g. "next" or "prev"
 * @return {Number} returns new scrollposition
 */
__.RenderStage.prototype.calculateNewScrollPosition = function(direction_) {
	var newTranslate,
		visibleItems = this.getVisibleThumbnailItems(),
		currentPos = this.thumbnailsContainer.scrollLeft,
		containerWidth = this.thumbnailsContainer.clientWidth;

	if (direction_ === 'next') {
		// current position plus containerWidt minus right bound of thumbnails container minus left bound of last visible item
		newTranslate =
			currentPos +
			containerWidth -
			(__.getRightBound(this.thumbnailsContainer) - __.getLeftBound(visibleItems[visibleItems.length - 1]));
	}
	else {
		// current position minus container width minus left bound of thumbnails container - right bound of first visible item
		newTranslate =
			currentPos -
			containerWidth -
			(__.getLeftBound(this.thumbnailsContainer) - __.getRightBound(visibleItems[0]));
	}
	return newTranslate;
};

/**
 * translateThumbnailContainer translates thumbnails stripe position
 * @param {string} direction_ translate direction
 * @return {void} returns nothing
 */
__.RenderStage.prototype.translateThumbnailContainer = function function_name(direction_) {
	var newTranslate = this.calculateNewScrollPosition(direction_),
		that = this;

	DOM_UTILS.animateElementX(newTranslate, this.thumbnailsContainer, 600)
		.then(function() {
			that.handleNavigationVisibility(
				that.thumbnails,
				that.thumbnailsContainer,
				that.thumbnailNavPrev,
				that.thumbnailNavNext
			);
		})
		.catch(function() {
			// hide animation errors from console
		});
};

/*
 * handleNavigationVisibility description check and set navigation arrows (in)visible
 * @param {HtmlElement} currentGallery_  currentGallery
 * @param {Array} slides_ Array of slide items
 * @param {Element} scrollContainer_ scroll container
 * @return {void} returns nothing
 */
/* eslint-disable max-params */
__.RenderStage.prototype.handleNavigationVisibility = function(
	itemsArr_,
	itemsContainer_,
	navPrev_,
	navNext_,
	fullVisible_
) {
	var prevStatus = __.getItemVisiblityStatus(itemsArr_[0], itemsContainer_),
		nextStatus = __.getItemVisiblityStatus(itemsArr_[itemsArr_.length - 1], itemsContainer_);

	__.refreshNavigationVisibility(prevStatus, navPrev_, fullVisible_);
	__.refreshNavigationVisibility(nextStatus, navNext_, fullVisible_);
};

/**
 * scrollToImage - scrollToImage
 * @param {Number} newIndex_ new target index
 * @return {void} returns nothing, animates scroll container
 */
__.RenderStage.prototype.scrollToImage = function(newIndex_) {
	var that = this,
		scrollContainerItemWidth = this.scrollContainerItem.clientWidth,
		scrollToPosition = scrollContainerItemWidth * newIndex_; // calculate new scroll position ( width of stage slide * index)

	DOM_UTILS.animateElementX(scrollToPosition, this.scrollContainer, 300)
		.catch(function() {
			// hide animation errors from console
		})
		.then(function() {
			that.centerThumb();
			// SNAPPOINTS POLYFILL
			const isSafari = (/^((?!chrome|android|crios|fxios).)*safari/i).test(navigator.userAgent);
			if (isSafari) {
				that.changeInputPos(newIndex_);
			}
		});
};

/**
 * centerThumb description - centers cut thumbnail if clicked or sroll
 * @param {HtmlElement} currentGallery currentGallery
 * @param {HtmlElement} sliderWrap sliderWrap
 * @return {void} returns nothing
 */
__.RenderStage.prototype.centerThumb = function() {
	var thumb_ = this.thumbnails[this.currentIndex], // thumbnails element for visible stage slide
		targetValue = thumb_.offsetLeft - (this.scrollContainer.clientWidth - thumb_.clientWidth) / 2; // calculate centered position in wrapper
	if (!DOM_UTILS.isVisible(thumb_, this.thumbnailsContainer, true)) {
		DOM_UTILS.animateElementX(targetValue, this.thumbnailsContainer, 300).catch(function() {
			// hide animation errors from console
		});
	}
};

/**
 * changeInputPos
 * set checked attribute at newIndex
 * @param {Number} newIndex_ - the new index
 * @return {void} returns nothing
 */
__.RenderStage.prototype.changeInputPos = function(newIndex_) {
	this.imageInputs.map(function(input) {
		input.checked = false;
	});
	this.currentIndex = newIndex_;
	this.imageInputs[newIndex_].checked = true; // set new current index for inputs to set boarder for active element
};

/**
 * refreshNavigationVisibility check if navItem should be visible and show/hide it
 * @param {Object} status_  visible object
 * @param {HtmlElement} navItem_ navigation item
 * @param {boolean} fullVisible_ fullVisible_
 * @return {[type]}          [description]
 */
__.refreshNavigationVisibility = function(status_, navItem_, fullVisible_) {
	// check if status of item is at least 20 % visible or if full visible it has to be 100% visibility
	var minimumVisiblePercentage = fullVisible_ ? 100 : 20;

	if (
		status_.visibleStatus === 2 ||
		(status_.visibleStatus === 1 && status_.nearestParentBoundPercentage >= minimumVisiblePercentage)
	) {
		navItem_.classList.add('nm-hidden');
	}
	else {
		navItem_.classList.remove('nm-hidden');
	}
};

/**
 * getItemVisiblityStatus
 * @param {HtmlElement} element_ to check if visible
 * @param {HtmlElement} parent_ parent node
 * @param {HtmlElement} approximation_ approximation at the edges (default = 0)
 * @return {Object} returns bounding object
 */
__.getItemVisiblityStatus = function(element_, parent_, approximation_) {
	var parentBound,
		rectBound,
		rightBoundVisible,
		leftBoundVisible,
		approximation = approximation_ || 0;

	parent_.parentNode.style.display = 'block'; // temporarly un-hide container -> needed to calculate the bounding-box size
	parentBound = parent_.getBoundingClientRect();
	rectBound = element_.getBoundingClientRect();
	rightBoundVisible =
		(Math.round(rectBound.right) <= Math.round(parentBound.right) ||
			(Math.round(Math.abs(rectBound.right / parentBound.right) * 100) >= 100 - approximation &&
				Math.round(Math.abs(rectBound.right / parentBound.right) * 100) <= 100 + approximation)) &&
		Math.round(rectBound.right) >= Math.round(parentBound.left);
	leftBoundVisible =
		(Math.round(rectBound.left) >= Math.round(parentBound.left) ||
			(Math.round(Math.abs(rectBound.left / parentBound.left) * 100) >= 100 - approximation &&
				Math.round(Math.abs(rectBound.left / parentBound.left) * 100) <= 100 + approximation)) &&
		Math.round(rectBound.left) <= Math.round(parentBound.right);
	parent_.parentNode.style.display = ''; // remove the temp styling

	return __.createBoundingObject(parentBound, rectBound, leftBoundVisible, rightBoundVisible);
};

/**
 * createBoundingObject
 * @param {Object} parentBound_ - object with boundings of parent element
 * @param {Object} rectBound_ - object with boundings of element
 * @param {boolean} leftBoundVisible_
 * @param {boolean} rightBoundVisible_
 * @return {Object} returns bounding object
 */
/* eslint-disable max-statements */
__.createBoundingObject = function(parentBound_, rectBound_, leftBoundVisible_, rightBoundVisible_) {
	var boundingObject = {};
	if (rightBoundVisible_ && leftBoundVisible_) {
		boundingObject.visible = true;
		boundingObject.visibleStatus = 2;
		boundingObject.visibleStatusText = 'full';
	}
	else if (rightBoundVisible_ || leftBoundVisible_) {
		boundingObject.visible = true;
		boundingObject.visibleStatus = 1;
		boundingObject.visibleStatusText = 'half';
	}
	else {
		boundingObject.visible = false;
		boundingObject.visibleStatus = 0;
		boundingObject.visibleStatusText = 'no';
	}
	boundingObject.elemWidth = Math.round(rectBound_.width);
	if (rectBound_.right < parentBound_.right && rectBound_.right < parentBound_.right - parentBound_.width / 2) {
		boundingObject.nearestParentBound = 'left';
		boundingObject.nearestParentBoundPercentage = Math.round(
			Math.abs((rectBound_.right - parentBound_.left) / rectBound_.width) * 100
		);
	}
	else {
		boundingObject.nearestParentBound = 'right';
		boundingObject.nearestParentBoundPercentage = Math.round(
			Math.abs((parentBound_.right - rectBound_.left) / rectBound_.width) * 100
		);
	}
	return boundingObject;
};

/**
 * getNewIndex - gets item index from data attribute
 * @param {HTMLElement} item slider item
 * @return {Number} returns item index
 */
__.getNewIndex = function(item) {
	return item.getAttribute('data-index') || 0;
};

/**
 * getItemWidth get client width of item
 * @param {HTMLElement} item slider item
 * @return {Number} return client width of item
 */
__.getItemWidth = function(item) {
	return item.clientWidth || 0;
};

/**
 * getRightBound
 * @param {HTMLElement} item slider item
 * @return {Number} returns right bound of item
 */
__.getRightBound = function(item) {
	return item.getBoundingClientRect().right || 0;
};

/**
 * getLefBound
 * @param {HTMLElement} item slider item
 * @return {Number} returns left bound of item
 */
__.getLeftBound = function(item) {
	return item.getBoundingClientRect().left || 0;
};

/**
 * initiateStages initiate all stages on start
 * @return {void} return nothing
 */
__.initiateStages = function() {
	var stages = DOM_UTILS.getElementsArray(__.oDefaults.selectorModuleRenderStage), // get all stages found in dom
		stage,
		index;

	// removes existing stage items from found dom stages
	__.renderStages.forEach(function(renderStage) {
		index = stages.indexOf(renderStage.context);
		if (index > -1) {
			stages.splice(index, 1);
		}
	});

	// stages now contains only new renderStages - generate new RenderStage Object,
	// add to __.renderStages and add events for this item
	stages.forEach(function(stage_) {
		stage = new __.RenderStage(stage_);
		__.renderStages.push(stage);
		stage.addStageEvents();
	});
	__.addOrDeleteResizeHandler();
};

/**
 * removeStages remove stages called on layer close
 * @return {void} returns nothing
 */
__.removeStages = function() {
	var stages = DOM_UTILS.getElementsArray(__.oDefaults.selectorModuleRenderStage), // get all stages found in dom
		i = __.renderStages.length;

	// loop (reverse, to prevent indices destroying) __.renderStages and check if item exist in new dom render stages,
	// if not remove events and delete from __.renderStages
	while (i--) {
		if (stages.indexOf(__.renderStages[i].context) < 0) {
			__.renderStages[i].removeStageEvents(); // remove all events of instance before deleting
			__.renderStages.splice(i, 1); // delete renderstage item
		}
	}
	__.addOrDeleteResizeHandler();
};

/**
 * resizeHandler - change scroll left and thumb position for each initiated renderStage objects in __.renderStages array
 * @return {void} returns nothing
 */
__.resizeHandler = function() {
	// resize each renderStage element
	__.renderStages.forEach(function(stage) {
		// get new scrollLeft postion and set scrollLeft valu
		var scrollToPosition = stage.scrollContainerItem.clientWidth * stage.currentIndex;
		stage.scrollContainer.scrollLeft = scrollToPosition;
		stage.centerThumb(); // recenter thumb position
	});
};

/**
 * addOrDeleteResizeHandler adds resize handler if renderStages in dom, else removes it
 * @return {void} returns nothing
 */
__.addOrDeleteResizeHandler = function() {
	if (__.renderStages.length > 0) {
		window.removeEventListener('resize', __.resizeDebounceHandler);
		window.addEventListener('resize', __.resizeDebounceHandler);
	}
	else {
		window.removeEventListener('resize', __.resizeDebounceHandler);
	}
};

/**
 * resizeDebounceHandler
 * @return {Functioon} returns resize debounce function
 */
__.resizeDebounceHandler = DOM_UTILS.debounce(__.resizeHandler, 200);

/**
 * addEvents common events
 * @return {void} returns nothing
 */
__.addEvents = function() {
	__.eventBus.on(EVENTS.PAGE_READY, __.initiateStages);
	__.eventBus.on(EVENTS.LAYER_LOADED, __.initiateStages);
	__.eventBus.on(EVENTS.LAYER_CLOSED, __.removeStages);
};

/**
 * initialize module initialize function
 * @param {EventBus} eventBus_ common eventBus
 * @return {void} returns nothing
 */
exports.initialize = function(eventBus_) {
	return new Promise(function(resolve) {
		__.eventBus = eventBus_;
		__.initiateStages();
		__.addEvents();
		resolve('module/render-stage.js');
	});
};
export {exports as renderStage};
