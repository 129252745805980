const dotTemplate = `
	<div class="modal-layer__inner" role="alert">
		<div class="modal-layer__inner-header">
		</div>
		<div class="modal-layer__inner-content">
			<div data-layer-options='{"onEscClick": false, "onShaderClick": false, "centerVertically": true, "addCssClass": "nm-module-interstitial-layer"}'>
				{{=it.body}}
			</div>
		</div>
	</div>
`;

export {dotTemplate as modalLayerInterstitialTemplate};
