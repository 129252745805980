import {appEvents as EVENTS} from 'core-application';
const __ = {},
	exports = {
		__: __
	};

let _startPos = {
		x: 0,
		y: 0
	},
	_sensitivity = 25,
	_xDirection = 'none',
	// var _yDirection = 'none',
	_deltaX = 0,
	_deltaY = 0,
	$_stage,
	_eventLookup = {
		touchdevice: {
			down: 'touchstart',
			move: 'touchmove',
			up: 'touchend'
		},
		desktopdevice: {
			down: 'mousedown',
			move: 'mousemove',
			up: 'mouseup'
		}
	},
	_down = false,
	_deviceType = 'desktopdevice'; // 'touchdevice'
__.isTouchDevice = function() {
	return !!('ontouchstart' in window) || !!(navigator.msMaxTouchPoints);
};

__.handleMouseDown = function(event) {
	_down = false;
	/* jslint maxlen: 175 */
	if (event.target.nodeName.toLowerCase() !== 'select' && event.target.nodeName.toLowerCase() !== 'input' && event.target.nodeName.toLowerCase() !== 'option') {
		_down = true;
	}
	_deltaY = 0;
	_deltaX = 0;
	_startPos.x = event.pageX || event.originalEvent.changedTouches[0].pageX;
	_startPos.y = event.pageY || event.originalEvent.changedTouches[0].pageY;
	$_stage.off(_eventLookup[_deviceType].up, __.handleMouseUp).on(_eventLookup[_deviceType].up, __.handleMouseUp);
	if (!__.isTouchDevice()) {
		$_stage.off(_eventLookup[_deviceType].move, __.handleMouseMove).on(_eventLookup[_deviceType].move, __.handleMouseMove);
	}
};
__.triggerStageViewChange = function(direction_) {
	if (direction_ === 'right') {
		jQuery('li.nm-backarrow').trigger('click.stage.remote');

	}
	else {
		jQuery('li.nm-nextarrow').trigger('click.stage.remote');
	}
};

__.handleMouseUp = function(event) {
	_down = false;
	_deltaX = _startPos.x - (event.pageX || event.originalEvent.changedTouches[0].pageX);
	_deltaY = _startPos.y - (event.pageY || event.originalEvent.changedTouches[0].pageY);
	if (_deltaX > 0) {
		_xDirection = 'left';
	}
	else {
		_xDirection = 'right';
	}
	/*
	if (_deltaY < 0) {
		_yDirection = 'down';
	}
	else {
		_yDirection = 'up';
	}
	*/
	if (Math.abs(_deltaX) > _sensitivity && Math.abs(_deltaY) < _sensitivity) {
		__.triggerStageViewChange(_xDirection);
	}
};
__.handleMouseMove = function(event) {
	if (_down) {
		_deltaX = _startPos.x - (event.pageX || event.originalEvent.changedTouches[0].pageX);
		_deltaY = _startPos.y - (event.pageY || event.originalEvent.changedTouches[0].pageY);
		/*
		if (_deltaY < 0) {
			_yDirection = 'down';
		}
		else {
			_yDirection = 'up';
		}
		*/
		if (_deltaX > 0) {
			_xDirection = 'left';
		}
		else {
			_xDirection = 'right';
		}
		// horizontal swipe
		if (Math.abs(_deltaX) > _sensitivity && Math.abs(_deltaY) < _sensitivity) {
			__.removeSwipeEvents();
			_down = false;
			__.triggerStageViewChange(_xDirection);

		}
	}
};
__.removeSwipeEvents = function() {
	$_stage.off(_eventLookup[_deviceType].move, __.handleMouseMove);
	$_stage.off(_eventLookup[_deviceType].up, __.handleMouseUp);
};


exports.disableSwipe = function() {
	$_stage.off(_eventLookup[_deviceType].move, __.handleMouseMove);
	$_stage.off(_eventLookup[_deviceType].up, __.handleMouseUp);
	$_stage.off(_eventLookup[_deviceType].down, __.handleMouseDown);
};

exports.enableSwipe = function() {
	$_stage.off(_eventLookup[_deviceType].down, __.handleMouseDown).on(_eventLookup[_deviceType].down, __.handleMouseDown);
};


__.addEvents = function() {
	$_stage = jQuery('.nm-stage');
	if (__.isTouchDevice()) {
		_deviceType = 'touchdevice';
	}
	$_stage.find('img').on('dragstart', function(e) {
		e.preventDefault();
	});
	$_stage.off(_eventLookup[_deviceType].down, __.handleMouseDown).on(_eventLookup[_deviceType].down, __.handleMouseDown);
};

exports.initialize = function(eventEmitter_) {
	__.eventBus = eventEmitter_;
	__.addEvents();
	__.eventBus.on(EVENTS.PAGE_LOADED, __.addEvents);
	__.eventBus.on(EVENTS.UPDATE_VIEW_STATE, __.addEvents);
};

export {exports as swipeStage};
