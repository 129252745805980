import {appEvents} from 'core-application';

var __, exports;

__ = {};

exports = {
	__: __
};

__.pending = false;

__.initialize = function () {
	__.addEvents();
	// only init things if we are on a result page
	__.$SearchResultEl = jQuery('.gsa-search-result');
	if (__.$SearchResultEl.length > 0) {
		__.baseSearchURL = __.$SearchResultEl.attr('data-searchurl');
		// on first pageload do a query
		__.onHashChange();
	}

};

__.processReturnedData = function(data){
	const body = document.querySelector('body');
	const searchQueryStr = exports.getSearchQueryString();
	// parseHTML is used to clean the data from <script></script> tags
	const $el = jQuery(jQuery.parseHTML(data));
	let pos = 0;
	const activePage = parseInt($el.find('.lists-pagination .is-selected').text(), 10);
	// hide some unneccessary information inside the html
	$el.find('.dropdown-search-sort').hide(); // hide sort filters

	// add tracking information to html (there already is some tracking data there (can we reuse it?))
	$el.find('a[data-tracking]').each(function () {
		pos++;
		let $this = jQuery(this);
		$this.attr('data-tracking-pos', ((activePage - 1) * 10) + pos);
		$this.addClass('audi-j-search-result');
		$this.removeAttr('data-tracking');
	});

	body.classList.add('audi-j-tracking-search');
	body.setAttribute('data-search-name', 'primary main searchfield');
	body.setAttribute('data-search-term', searchQueryStr);
	body.setAttribute('data-search-results', $el.find('.search-results-count b').text());
	__.$SearchResultEl.html($el);
};

__.search = async function (searchUrl) {
	__.pending = true;
	try {
		const request = await fetch(searchUrl);
		const data = await request.text();
		__.processReturnedData(data);
	}
	catch (err) {
		console.log('GSA Error:' + err.message);
		// display generic error message
		__.$SearchResultEl.html('<div class="nm-error">' + __.$SearchResultEl.attr('data-errormessage') + '</div>');
	}
	__.pending = false;
	__.eventBus.emit(appEvents.SEARCH_LOADED);
};

__.addEvents = function () {
	// TODO: how to make back button work with our ajax??
	// add click handlers to all links that trigger a new search
	__.legacyEventBus.on('click', 'a[data-gsa]', __.handleNewSearchClick);
	__.eventBus.on(appEvents.HASH_CHANGE, __.onHashChange);
};

__.handleNewSearchClick = function (event_) {
	event_.preventDefault();
	const getParams = jQuery(this).attr('data-gsa');

	// TODO: some get params are duplicated (in __.baseSearchURL and getParams ) fix??
	const searchUrl = __.baseSearchURL + getParams;
	__.search(searchUrl);
};

__.onHashChange = function () {
	var searchQueryStr = exports.getSearchQueryString();
	var searchUrl = __.baseSearchURL + '&q=' + searchQueryStr;
	if (!!searchQueryStr) {
		__.search(searchUrl);
	}
};

/**
 * return current search string from url
 * @param {Object} window_ global window object
 * @returns {string|null} querystring
 */
exports.getSearchQueryString = function (window_) {
	var win = window_ || window;
	var splittedHash = win.location.hash.replace('#', '').replace(/</g, '').replace(/>/g, '').split('&');
	var searchQueryStr = null;
	for (let i = 0; i < splittedHash.length; i++) {
		if (splittedHash[i].search('search=') === 0) {
			searchQueryStr = splittedHash[i].replace('search=', '');
		}
	}
	return searchQueryStr;
};

/**
 * check if results are still loading
 * @return {boolean} pending
 */
exports.hasPendingResults = function () {
	return __.pending;
};

exports.initialize = function (eventEmitter) {
	__.eventBus = eventEmitter;
	__.legacyEventBus = jQuery('body');
	__.initialize();
};

export {exports as gsaSearch};
