import {appEvents as EVENTS} from 'core-application';
import {template as TPL_RENDERER} from 'core-utils';
import {videoNew as VIDEO_ELEMENT} from '../video-new';
import {videoControlsTemplate as VIDEO_CTRLS_TPL} from './video-controls-template';
const __ = {},
	exports = {
		__: __
	};
__.oDefaults = {
	sSelectorStage: '.nm-mediagallery-stage',
	sSelectorThumbsContainer: '.nm-mediagallery-thumbs',
	animationDuration: 250,
	sSelectorModule: '.nm-md-mediagallery-media-list',
	sClassShowThumbs: 'nm-md-mediagallery-media-list-detail',
	sSelectorSlideContainer: '.nm-mediagallery-detail-slide',
	sSelectorVideoSlide: '.nm-mediagallery-detail-video',
	sSelectorImageSlide: '.nm-mediagallery-detail-img',
	imageTemplateString: '<div class="nm-mediagallery-detail-img"><!--[if IE 8 ]><img src="" /><![endif]--></div>',
	videoTemplateString: '<div class="nm-mediagallery-detail-video"><div class="nm-mediaplayer nm-video"><video src="" title="" poster=""></video><!--[if IE 8 ]><img src="" /><![endif]-->'+TPL_RENDERER.render(VIDEO_CTRLS_TPL, null)+'</div></div>'
};
__.defaultVideoWidth = 1920;
__.defaultVideoHeight = 1080;
// store native dimensions for the current image/video
__.nativeDimensions = {
	width: 0,
	height: 0
};
__.mousePosX=0;
/*
 * resize/center stage
 */
__.resizeStage = function() {
	var thumbsHeight = jQuery(__.oDefaults.sSelectorThumbsContainer).height();
	var stageHeight = jQuery('body').innerHeight() - thumbsHeight;
	if (jQuery(__.oDefaults.sSelectorModule).hasClass(__.oDefaults.sClassShowThumbs)) {
		jQuery(__.oDefaults.sSelectorSlideContainer).height(jQuery('body').innerHeight());
	}
	else {
		jQuery(__.oDefaults.sSelectorSlideContainer).height(stageHeight);
	}
};

/*
* check if content was downscaled or is displayed in its natural size
*/
__.contentIsResized = function() {
	var $stageContainer = jQuery(__.oDefaults.sSelectorStage),
		$slideWrapper = $stageContainer.find(__.oDefaults.sSelectorSlideContainer),
		contentWidth = $slideWrapper.width(),
		contentHeight = __.getNewContentHeight();
	if ((__.nativeDimensions.width < contentWidth) && (__.nativeDimensions.height < contentHeight)) {
		return false;
	}
	else {
		return true;
	}
};
__.addEvents = function() {
	__.eventBus.on(EVENTS.MEDIAGALLERY_ITEM_CHANGED, __.handleItemSelectionChange);
	__.eventBus.on(EVENTS.LAYER_LOADED, __.checkNativeContentSize);
	__.eventBus.on(EVENTS.MEDIAGALLERY_RESIZED, __.checkNativeContentSize);
	jQuery(window).on('resize', __.checkNativeContentSize);
};


/**
* read native file dimensions from a given image url
* @param {string} src_ - image url
* @returns {Promise} image metadata (width and height)
*/
__.getImageInfoPromise = function(src_) {
	return new Promise(function(resolve, reject) {
		var new_img = new Image();
		new_img.onload = function() {
			var img_width = this.width,
				img_heigth = this.height;
			__.nativeDimensions.width = img_width;
			__.nativeDimensions.height = img_heigth;
			resolve({
				'width': img_width,
				'height': img_heigth
			});
		};
		new_img.onerror = function() {
			reject('could not load image');
		};
		new_img.src = src_;
	});
};

/**
* react upon model changes
* @param {Object} data_ - selection data
* @returns {void} nothing
*/
__.handleItemSelectionChange = function(data_) {
	console.log('data', data_);
	if (!data_.changed) {
		console.log('nothing changed');
		return false;
	}
	// wait for description box to finish changing its size
	__.eventBus.once(EVENTS.MEDIAGALLERY_SLIDED, function() {
		__.waitForDescriptionTextboxResizing(data_);
	});
};

/**
* wait until new Textheight is available to do further animations/resizings
* @param {Object} data_ - selection data
* @returns {void} nothing
*/
__.waitForDescriptionTextboxResizing = function(data_) {
	if (!!data_.item && !!data_.item) {
		__.removeOldSlide().then(function() {
			return __.getNewSlide(data_.item);
		}).then(function($slide) {
			return __.appendNewSlide($slide);
		}).then(function() {
			if (__.isVideoSlide()) {
				__.startVideoSlide();
			}
		}).then(undefined, function(err) {
			console.log('Error appending new slide:', err);
		});
	}
	else {
		console.warn('data missing!!!!', data_);
	}
};

/**
* cretates an new slide (video or image)
* @param {Object} itemData_ - item data
* @returns {void} nothing
*/
__.getNewSlide = function(itemData_) {
	if (itemData_.contenttype === 'video') {
		return __.createVideoSlide(itemData_);
	}
	else {
		return __.createImageSlide(itemData_);
	}
};

/**
* checks if the current slide is a video slide (or image)
* @returns {boolean} is vido slide?
*/
__.isVideoSlide = function() {
	return jQuery(__.oDefaults.sSelectorStage).find(__.oDefaults.sSelectorVideoSlide).length === 1;
};

__.stopVideoSlide = function() {
	jQuery('.nm-video video').each(function () {
		this.pause();
	});
};

/**
* initialize Medialement once slide is active (animated)
* @returns {void} nothing
*/
__.startVideoSlide = function() {
	VIDEO_ELEMENT.handlerLayer({domElement: jQuery(__.oDefaults.sSelectorStage)});
};

/**
* remove old slide from to DOM
* @returns {void} nothing
*/
__.removeOldSlide = function() {
	return new Promise(function(resolve /* , reject */) {
		var $stageContainer = jQuery(__.oDefaults.sSelectorStage),
			$slideWrapper = $stageContainer.find(__.oDefaults.sSelectorSlideContainer);
		$slideWrapper.addClass('nm-animating');
		if (__.isVideoSlide()) {
			__.stopVideoSlide();
		}
		$slideWrapper.animate({
			'opacity': 0
		}, __.oDefaults.animationDuration, 'easeOutSine');
		setTimeout(function() {
			$slideWrapper.empty();
			// adjust content height for new slide
			$slideWrapper.css('height', __.getNewContentHeight());
			resolve($slideWrapper);
		}, __.oDefaults.animationDuration);
	});
};

/**
* append and animate new slide to the DOM
* @param {Object} $newSlide new slide (jQuery)
* @returns {void} nothing
*/
__.appendNewSlide = function($newSlide) {
	return new Promise(function(resolve/* , reject */) {
		var $stageContainer = jQuery(__.oDefaults.sSelectorStage),
			$slideWrapper = $stageContainer.find(__.oDefaults.sSelectorSlideContainer);
		$slideWrapper.css('opacity', 0);
		// append new slide
		$newSlide.appendTo($slideWrapper);
		__.checkNativeContentSize();
		$slideWrapper.removeClass('nm-animating');
		// animate stage
		$slideWrapper.animate({
			'opacity': 1
		}, __.oDefaults.animationDuration, 'easeOutSine');
		setTimeout(function() {
			resolve($slideWrapper);
		}, __.oDefaults.animationDuration);
	});
};

/**
* create an image image slide (jQuery Element)
* @param {Object} itemData_ - item data
* @returns {Promise} nothing
*/
__.createImageSlide = function(itemData_) {
	if (!itemData_ || !itemData_.imageurl) {
		return Promise.reject('image url missing!!!');
	}
	const imageSrc = itemData_.imageurl;
	let promise = __.getImageInfoPromise(imageSrc);
	return promise.then(function() {
		var $newSlide = jQuery(__.oDefaults.imageTemplateString);
		$newSlide.css('background-image', 'url(' + imageSrc + ')');
		return Promise.resolve($newSlide);
	});
};

/**
* helper method to read image source
* @param {Object} $image image (jQuery)
* @returns {void} nothing
*/
__.getBackgroundImageURL = function($image) {
	var url = $image.css('background-image');
	if (!!url) {
		url = url.replace(/^url\(["']?/, '').replace(/["']?\)$/, '');
	}
	else {
		url = $image.find('image').attr('src');
	}
	return url;
};

/**
* check for native image size and resize stage
* @returns {void} nothing
*/
__.checkNativeContentSize = function() { // eslint-disable-line max-statements
	if (jQuery(__.oDefaults.sSelectorStage).length===0){
		return true;
	}
	// check for ResizeEnd event
	if (!!this && this.type === 'resize') {
		if (__.resizeIntervalID) {
			clearInterval(__.resizeIntervalID);
		}
		__.resizeIntervalID = setInterval(function() {
			clearInterval(__.resizeIntervalID);
			__.resizeIntervalID = null;
			__.checkNativeContentSize();
		}, 250);
		return false;
	}
	__.resizeStage();
	let promise;
	// initial call on layer.loaded
	if (__.nativeDimensions.width === 0) {
		if (__.isVideoSlide()) {
			__.nativeDimensions.width = __.defaultVideoWidth;
			__.nativeDimensions.height = __.defaultVideoHeight;
		}
		else {
			let $initialImage = jQuery(__.oDefaults.sSelectorImageSlide);
			let imageSrc = __.getBackgroundImageURL($initialImage);
			promise = __.getImageInfoPromise(imageSrc);
		}
	}
	if (!promise) {
		promise = Promise.resolve();
	}
	promise.then(function() {
		var $stageContainer = jQuery(__.oDefaults.sSelectorStage),
			$slideWrapper = $stageContainer.find(__.oDefaults.sSelectorSlideContainer),
			// get video or image slide
			$slide = $slideWrapper.find([__.oDefaults.sSelectorVideoSlide, __.oDefaults.sSelectorImageSlide].join(','));
		// image´s/video´s native size is smalles than the actual content stage?
		if (!__.contentIsResized()) {
			$slide.addClass('nm-mediagallery-detail-img-native-size');
		}
		else {
			$slide.removeClass('nm-mediagallery-detail-img-native-size');
		}
		// resize Video slide
		if (__.isVideoSlide()) {
			__.resizeVideoSlideContainer();
		}
	});
};

/**
* create an video image slide (jQuery Element)
* @param {Object} itemData_ item data
* @returns {void} nothing
*/
__.createVideoSlide = function(itemData_) {
	if (!itemData_ || !itemData_.videosrc) {
		return Promise.reject('invalid video slide');
	}
	return new Promise(function(resolve/* , reject */) {
		var $stageContainer = jQuery(__.oDefaults.sSelectorStage),
			$slideWrapper = $stageContainer.find(__.oDefaults.sSelectorSlideContainer),
			$newSlide = jQuery(__.oDefaults.videoTemplateString),
			contentWidth = $slideWrapper.width();
		// static size for Videos?
		__.nativeDimensions.width = __.defaultVideoWidth; // '1920',
		__.nativeDimensions.height = __.defaultVideoHeight; // '1080';
		// image native size is smaller than the actual content stage?
		if ((__.nativeDimensions.width < contentWidth)) {
			$newSlide.addClass('nm-mediagallery-detail-img-native-size');
		}
		$newSlide.find('video').attr({
			'src': itemData_.videosrc,
			'poster': itemData_.posterurl
		});
		__.resizeVideoSlideContainer($newSlide);
		resolve($newSlide);
	});
};
/*
* special resizing/calculation for video (mediaelement)
*/
__.resizeVideoSlideContainer = function($videoContainer_) {
	var $stageContainer = jQuery(__.oDefaults.sSelectorStage),
		$slideWrapper = $stageContainer.find(__.oDefaults.sSelectorSlideContainer),
		h = __.getNewContentHeight(),
		w = $slideWrapper.width(),
		ratioVideo = __.nativeDimensions.width / __.nativeDimensions.height,
		resultingHeight = 0,
		targetWidth = 0;
	let $videoContainer = $videoContainer_ || $slideWrapper.find(__.oDefaults.sSelectorVideoSlide);
	if ($videoContainer.length) {
		resultingHeight = (w / __.nativeDimensions.width) * __.nativeDimensions.height;
		// does the resulting height fit into the stage?
		if (resultingHeight < h) {
			// use stage width to resize videocontent
			targetWidth = (w / __.nativeDimensions.width);
		}
		else {
			// limit videocontent to stage height
			targetWidth = ((h * ratioVideo) / __.nativeDimensions.width);
		}
		targetWidth = ((targetWidth * __.nativeDimensions.width) / w) * 100;
		// set container´s new width
		$videoContainer.css('width', targetWidth + '%');
	}
};

/**
* get current content height within the stage
* @returns {void} nothing
*/
__.getNewContentHeight = function() {
	var thumbsHeight = jQuery(__.oDefaults.sSelectorThumbsContainer).height();
	var stageHeight = jQuery('body').innerHeight() - thumbsHeight;
	console.log('THUMBS: ' + thumbsHeight + ' - BROWSER: ' + jQuery('body').innerHeight() + ' - ' + stageHeight);
	return stageHeight;
};

exports.initialize = function(eventEmitter_) {
	__.eventBus=eventEmitter_;
	__.addEvents();
};

export {exports as mediagalleryStage};
