import {appEvents} from 'core-application';
import {stateRegistry} from 'microkernel';

/**
 * PageService singleton
 */
class PageService {

	/**
	 * @constructor
	 */
	constructor() {
		if (PageService._instance) {
			return PageService._instance;
		}

		this.handlePageType = this.handlePageType.bind(this);

		stateRegistry.addStore('dbadPageStoreFootnotes', {
			isNemoHome: false
		}, {
			'updatePageType': function (state, parameterObject) {
				return {
					...state,
					isNemoHome: parameterObject.typeName === 'nemo' && parameterObject.templateName === 'home'
				};
			}
		});

		PageService._instance = this;
	}

	/**
	 * is called to inject the signal dependency
	 * @param {Object} eventEmitter - the injected signal dependency
	 * @returns {void}
	 */
	initialize(eventEmitter) {
		this.eventBus = eventEmitter;

		this.eventBus.on(appEvents.PAGE_LOADED, this.handlePageType);
		this.handlePageType();
	}

	/**
	 * if data is null, reads type and template directly from the DOM;
	 * else reads type and template from data.domElement.
	 * dispatches updatePageTypeAction with type and template.
	 * @param {Object} data - optional
	 * @returns {void}
	 */
	handlePageType(data = null) {
		let contentElement,
			dataType,
			dataTemplate;

		if (data !== null && typeof data.domElement !== 'undefined') {
			contentElement = data.domElement.querySelector('.nm-content');
		}
		else {
			contentElement = document.querySelector('.nm-content');
		}

		if (contentElement !== null) {
			dataType = contentElement.getAttribute('data-type');
			dataTemplate = contentElement.getAttribute('data-template');

			stateRegistry.triggerAction('dbadPageStoreFootnotes', 'updatePageType', {
				typeName: dataType,
				templateName: dataTemplate
			});
		}
	}
}

export const pageService = new PageService();
