import {appEvents as EVENTS} from 'core-application';
import {dom as DOM_UTILS} from 'core-utils';
import {mediagalleryModel as MODEL} from './model';
const __ = {},
	// Public API
	exports = {
		__: __
	};

__.oDefaults = {
	sSelectorNextBtn: '.nm-mediagallery-browse .nm-nextarrow',
	sSelectorPrevBtn: '.nm-mediagallery-browse .nm-backarrow',
	sSelectorSliderItemLink: '.nm-mediagallery-item a',
	sSelectorSliderItem: '.nm-mediagallery-item',
	sSelectorDataCatAttr: 'data-category',
	sSelectorCategoryItem: '.nm-mediagallery-categories li',
	sSelectorModule: '.nm-md-mediagallery-media-list',
	sClassShowOverview: 'nm-md-mediagallery-media-list-overview',
	sSelectorOverviewSwitch: '.nm-mediagallery-categories-overview',
	sSelectorOverview: '.nm-mediagallery-categories-overview',
	sSelectorDownloadbox: '.nm-mediagallery-download-infobox',
	sSelectorDownloadIcon: '.nm-mediagallery-download',
	sIdDropdownList: '#nm-mediagallery-category-list-select',
	sSelectorSlideContainer: '.nm-mediagallery-detail-slide',
	sSelectorMediagalleryModule: '.nm-md-mediagallery-media-list',
	sIDprefix: 'mg_item_'
};

__.addEvents = function() {
	var clickDelegate=jQuery('body');
	__.removeEvents();
	__.eventBus.one(EVENTS.LAYER_CLOSE, __.onLayerClosing);
	clickDelegate.on('click.mg_prevnext', __.oDefaults.sSelectorPrevBtn, {
		direction: 'back'
	}, __.handlePagePrevNextClick);
	clickDelegate.on('click.mg_prevnext', __.oDefaults.sSelectorNextBtn, {
		direction: 'next'
	}, __.handlePagePrevNextClick);
	clickDelegate.on('mousedown.mg_stage', __.oDefaults.sSelectorSlideContainer, __.onMouseDown);
	clickDelegate.on('click.mg_slider_item', __.oDefaults.sSelectorSliderItemLink, __.handleSliderItemClick);
	clickDelegate.on('click.mg_category', __.oDefaults.sSelectorCategoryItem, __.handleCategoryChangeClick);
	clickDelegate.on('keydown.mg_browse_arrows', __.handleKeyDown);
	clickDelegate.on('change.mg_dropdown_select', __.oDefaults.sIdDropdownList, __.handleCategoryDropdownChanges);
	clickDelegate.on('keyup.mediagallery', __.handleEscKey);
	clickDelegate.on('click.mediagallery', '.nm-layer-wrapper', __.handleShaderClick);
};

__.removeEvents = function() {
	var clickDelegate=jQuery('body');
	clickDelegate.off('click.mg_prevnext', __.oDefaults.sSelectorPrevBtn, __.handlePagePrevNextClick);
	clickDelegate.off('click.mg_prevnext', __.oDefaults.sSelectorNextBtn, __.handlePagePrevNextClick);
	clickDelegate.off('mousedown.mg_stage', __.oDefaults.sSelectorSlideContainer, __.onMouseDown);
	clickDelegate.off('click.mg_slider_item', __.oDefaults.sSelectorSliderItemLink, __.handleSliderItemClick);
	clickDelegate.off('click.mg_category', __.oDefaults.sSelectorCategoryItem, __.handleCategoryChangeClick);
	clickDelegate.off('keydown.mg_browse_arrows', __.handleKeyDown);
	clickDelegate.off('change.mg_dropdown_select', __.oDefaults.sIdDropdownList, __.handleCategoryDropdownChanges);
	clickDelegate.off('keyup.mediagallery', __.handleEscKey);
	clickDelegate.off('click.mediagallery', '.nm-layer-wrapper', __.handleShaderClick);
};

__.onLayerClosing = function() {
	var bodyScrollPos, htmlScrollPos;

	__.removeEvents();

	if (window.location.hash.indexOf('mediagallery=') > -1) {
		// save current scrollpos
		bodyScrollPos = document.body.scrollTop;
		htmlScrollPos = document.documentElement.scrollTop; // for FF and IE
		// remove mediagallery-param from browserhash before closing the layer
		window.location.hash = window.location.hash.replace(/(mediagallery=){1}\w*(\|){1}\d*(&)?/, '');
		// move back to original scrollpos after hash reset //FIXME: quick and dirty soluten - find a better one!
		document.body.scrollTop = bodyScrollPos;
		document.documentElement.scrollTop = htmlScrollPos;
	}
};

__.onMouseDown = function(event_) {
	__.mousePosX = event_.pageX;
	jQuery('body').one('mouseup.mg_stage', __.oDefaults.sSelectorSlideContainer, __.onMouseUp);
};

__.onMouseUp = function(event_) {
	if (Math.abs(__.mousePosX - event_.pageX) > 25) {
		if ((__.mousePosX - event_.pageX > 0)) {
			__.triggerNextClick();
		}
		else {
			__.triggerPrevClick();
		}
	}
};

__.handleEscKey = function(event_) {
	if (event_.keyCode === 27) {
		__.closeLayer();
	}
};

__.galleryIsOpened = function() {
	return (jQuery(__.oDefaults.sSelectorMediagalleryModule).length > 0);
};

__.closeLayer = function() {
	jQuery([__.oDefaults.sSelectorMediagalleryModule, '.nm-button-close'].join(' ')).trigger('click');
};

__.handleShaderClick = function(event_) {
	if (__.galleryIsOpened()) {
		const clickedOutside = DOM_UTILS.isElementOutsideOfElementWithSelector(event_.target, __.oDefaults.sSelectorMediagalleryModule);
		if (clickedOutside && !jQuery(event_.target).hasClass('nm-mediagallery-image')) {
			__.cancelEvent(event_);
			__.closeLayer();
		}
	}
};

__.isInOverviewMode = function() {
	var $selModule = jQuery(__.oDefaults.sSelectorModule);
	return $selModule.hasClass(__.oDefaults.sClassShowOverview);
};

__.handleKeyDown = function(event_) {
	if (event_.keyCode === 37) {
		__.triggerPrevClick();
	}
	if (event_.keyCode === 39) {
		__.triggerNextClick();
	}
};

__.triggerNextClick = function() {
	var nextBtn = jQuery(__.oDefaults.sSelectorNextBtn);
	if (!nextBtn.hasClass('nm.hidden')) {
		nextBtn.trigger('click');
	}
};

__.triggerPrevClick = function() {
	var prevBtn = jQuery(__.oDefaults.sSelectorPrevBtn);
	if (!prevBtn.hasClass('nm.hidden')) {
		prevBtn.trigger('click');
	}
};

/*
 * handle next/previous arrow clicks
 */
__.handlePagePrevNextClick = function(event_) {
	if (__.isInOverviewMode()) {
		return false;
	}
	__.cancelEvent(event_);
	const direction = event_.data.direction,
		index = MODEL.getItemIndex(),
		selectedItem = (direction === 'next') ? MODEL.getNextItem(index) : MODEL.getPreviousItem(index);
	__.triggerItemSelectionUpdate(selectedItem);
};
/*
 * handle thumbnail item clicks
 */
__.handleSliderItemClick = function(event_) {
	__.cancelEvent(event_);
	const clickedItemID = jQuery(this).parent().attr('id');
	if (!clickedItemID) {
		console.warn('item could not be found!');
		return false;
	}
	const selectedItem = MODEL.getItemById(clickedItemID);
	__.triggerItemSelectionUpdate(selectedItem);
};

__.showOverview = function() {
	var $selModule = jQuery(__.oDefaults.sSelectorModule);
	var $categoryWrapper = jQuery('.nm-mediagallery-categories');
	$categoryWrapper.find('.nm-active').removeClass('nm-active');
	// show overview
	jQuery(__.oDefaults.sSelectorOverviewSwitch).addClass('nm-active');
	$selModule.addClass(__.oDefaults.sClassShowOverview);
};

__.hideOverview = function() {
	var $selModule = jQuery(__.oDefaults.sSelectorModule);
	var $categoryWrapper = jQuery('.nm-mediagallery-categories');
	// remove all selected states from menubar
	$categoryWrapper.find('.nm-active').removeClass('nm-active');
	// hide overview
	$selModule.removeClass(__.oDefaults.sClassShowOverview);
	// TODO: add selected state to previous selected category
	const category = MODEL.getSelectedCategory();
	$categoryWrapper.find('[data-category="' + category + '"]').addClass('nm-active');
};

/**
 * handle category item clicks
 * @param {Event} event_ - click Event
 * @returns {void} nothing
 */
__.handleCategoryChangeClick = function(event_) {
	__.cancelEvent(event_);
	const $clickedItem = jQuery(this);
	const selectedCategory = $clickedItem.attr(__.oDefaults.sSelectorDataCatAttr);
	if (selectedCategory === 'overview') {
		if (!__.isInOverviewMode()) {
			__.showOverview();
		}
		else {
			__.hideOverview();
		}
	}
	else {
		if (__.isInOverviewMode()) {
			__.hideOverview();
		}
		__.triggerCategoryUpdate(selectedCategory);
	}
};

/**
 * handle category dowpdown changes
 * @param {Event} event_ - change Event
 * @returns {void} nothing
 */
__.handleCategoryDropdownChanges = function(event_) {
	__.cancelEvent(event_);
	const valueSelected = this.value;
	if (valueSelected === 'overview') {
		if (__.isInOverviewMode()) {
			__.hideOverview();
		}
		else {
			__.showOverview();
		}
	}
	else {
		if (__.isInOverviewMode()) {
			__.hideOverview();
		}
		__.triggerCategoryUpdate(valueSelected);
	}
};

/**
 * dispatch Event on category updates
 * @param {string} cat_ category
 * @returns {void} nothing
 */
__.triggerCategoryUpdate = function(cat_) {
	MODEL.setSelectedCategory(cat_);
	// the current slected item exists in the previous AND the new category
	const previousItem = MODEL.getSelectedItem();
	// called for the first Time?
	if (!previousItem) {
		return false;
	}
	if (MODEL.getItemIndex() > -1) {
		__.triggerItemSelectionUpdate(MODEL.getSelectedItem());
	}
	else {
		// set the first Item of the new category
		__.triggerItemSelectionUpdate(MODEL.getItemByIndex(0));
	}
};

/**
 * dispach Event on Item selection updates
 * @param {HTMLElement} selectedItem_ selected Item
 * @returns {void} nothing
 */
__.triggerItemSelectionUpdate = function(selectedItem_) {
	if (__.isInOverviewMode()) {
		// is item is not in current category?
		if (MODEL.getItemIndex(selectedItem_) < 0) {
			// set category 'all'
			MODEL.setSelectedCategory('all');
		}
		// close overview
		__.hideOverview();
	}
	MODEL.setSelectedItem(selectedItem_);
	__.updateBrowseArrows();
};
__.updateBrowseArrows = function() {
	const maxIndex = MODEL.getMaxIndex(),
		prevBtn = jQuery(__.oDefaults.sSelectorPrevBtn),
		nextBtn = jQuery(__.oDefaults.sSelectorNextBtn);
	if (maxIndex < 1) {
		prevBtn.addClass('nm-hidden');
		nextBtn.addClass('nm-hidden');
	}
	else {
		prevBtn.removeClass('nm-hidden');
		nextBtn.removeClass('nm-hidden');
	}
};

/**
 * parse loaction hash string for "mediagallery="
 * @param {string} hashString_ - hash string
 * @returns {Object} - preselection {category:"<category>",index:<index>}
 */
__.parseHash = function(hashString_) {
	const preselection = {
		category: null,
		index: -1
	};
	if (hashString_.indexOf('mediagallery=') > -1) {
		let mgString = hashString_.split('mediagallery=')[1];
		mgString = decodeURI(mgString);
		mgString = mgString.split('&')[0];
		const mg = mgString.split('|');
		if (mg.length === 2) {
			preselection.category = mg[0];
			preselection.index = parseInt(mg[1], 10);
		}
		if (mg.length === 1 && !isNaN(parseInt(mg[0], 10))) {
			preselection.category = 'all';
			preselection.index = parseInt(mg[0], 10);
		}
		if (parseInt(preselection.index, 10) > 0) {
			preselection.index = preselection.index - 1;
		}
	}
	return preselection;
};

/**
 * prevent default event behavior
 * @param {Object} event_ e.g. e click event
 * @returns {void}
 */
__.cancelEvent = function(event_) {
	if (!!event_ && typeof event_.preventDefault === 'function') {
		event_.preventDefault();
	}
};

/**
 * initialize Model with data and set initail view states
 * @returns {void} nothing
 */
__.initializeDataAndViews = function() {
	// do nothing if no mediagallery is available
	if (__.galleryIsOpened()) {
		__.addEvents();
		__.initializeModel();
		// get preselected state from hash
		const preselection = __.parseHash(window.location.hash);
		// update item
		const items = MODEL.getAllItems();
		if (!preselection.category || !items || !items.length || preselection.index >= items.length) {
			console.log('could not enter with preselection!');
			return false;
		}
		const item = items[preselection.index];
		__.triggerCategoryUpdate(preselection.category);
		__.triggerItemSelectionUpdate(item);
	}
};

/**
 * collect items and categories from DOM
 * @returns {void} nothing
 */
__.initializeModel = function() {
	var $selModule = jQuery(__.oDefaults.sSelectorModule),
		$items = $selModule.find(__.oDefaults.sSelectorSliderItem),
		$cur, items = [],
		item,
		selectedItem;
	jQuery.each($items, function(index, val) { // eslint-disable-line complexity
		$cur = jQuery(val);
		item = {};
		item.category = !!$cur.attr('data-category') ? $cur.attr('data-category').toLowerCase() : 'all';
		item.description = $cur.attr('data-description') || '';
		item.contenttype = !!$cur.attr('data-content-type') ? $cur.attr('data-content-type') : 'image';
		item.imageurl = $cur.attr('data-image-url') || null;
		item.thumburl = $cur.attr('data-thumb-url');
		item.posterurl = $cur.attr('data-poster-url') || null;
		item.videosrc = $cur.attr('data-video-src') || null;
		item.downloadlink = $cur.attr('data-download-link') || null;
		item.id = $cur.attr('id');
		items.push(item);
		if ($cur.hasClass('nm-active')) {
			selectedItem = item;
		}
	});
	selectedItem = selectedItem || items[0];
	MODEL.setAllItems(items);
	$items = jQuery(__.oDefaults.sSelectorCategoryItem);
	items = [];
	let selectedCategory;
	jQuery.each($items, function() {
		$cur = jQuery(this);
		item = $cur.attr(__.oDefaults.sSelectorDataCatAttr);
		items.push(item);
		if ($cur.hasClass('nm-active')) {
			selectedCategory = item;
		}
	});
	MODEL.setAllCategories(items);
	selectedCategory = selectedCategory || 'all';
	// set initals status without changeEvent propagation
	MODEL.setSelectedCategory(selectedCategory);
	MODEL.setSelectedItem(selectedItem);
};

exports.initialize = function(eventEmitter_) {
	__.eventBus=eventEmitter_;
	__.eventBus.on(EVENTS.LAYER_LOADED, __.initializeDataAndViews);
};

export {exports as mediagalleryController};
