import {layerAnimation} from 'core-application';

var __ = {},
	exports = {
		'__': __
	};

__.getIframeForUrl = function (url) {
	var frame = document.querySelector('iframe[src*="' + url + '"]');
	var newUrl;

	if (frame === null) {
		newUrl = encodeURI(new URL(url).pathname);
		frame = document.querySelector('iframe[src*="' + newUrl + '"]');
	}

	return jQuery(frame);
};

__.toggleShader = function (show, url) {
	var $iframe = __.getIframeForUrl(url);
	var headerHeight = jQuery('.nm-header').height();
	var responsiveMobile = 479;
	var parentHeight = window.document.body.offsetHeight;
	var iframeHeight = $iframe.height();

	if (show) {
		$iframe.css({
			'zIndex': 101,
			'position': 'relative'
		});

		if ((window.document.body.offsetWidth <= responsiveMobile) || (parentHeight - 70 <= iframeHeight)) {
			$iframe.css({
				'top': -headerHeight
			});
		}

		jQuery('body').addClass('nm-carpark-shader-visible');
		layerAnimation.showShader();
	}
	else {
		jQuery('body').removeClass('nm-carpark-shader-visible');
		layerAnimation.hideShader();

		$iframe.css({
			'position': 'static',
			'top': 0
		});
	}
};

__.parseJsonDataFromEvent = function (event = {}) {
	let data = event.data;
	if (typeof data === "string") {
		try {
			data = window.JSON.parse(event.data);
		}
		catch (e) {
			console.warn(e);
		}
	}
	return data;
};

__.removeClassFromModule = function (iFrame_) {
	var $module, moduleClasses, className, iFrame = iFrame_;
	if (iFrame) {
		$module = iFrame_.closest('.nm-md-iframe')[0];
		if ($module) {
			moduleClasses = $module.classList;

			[].slice.call(moduleClasses).forEach(function (module, index) {
				var match = module.indexOf('nm-md-iframe-pageformat-') >= 0;
				if (match) {
					className = moduleClasses[index];
					moduleClasses.remove(className);
				}
			});
		}
	}
};

__.setAndAnimateIframeHeight = function (origin, height_) {
	var $iframe = __.getIframeForUrl(origin), height;

	__.removeClassFromModule($iframe);

	height = parseInt(height_, 10) + 'px';

	$iframe.animate({'height': height}, 400, 'swing', function () {
		$iframe.animate({
			'opacity': 1
		});
	});

};

__.setIframeHeight = function (origin, height_, url) {
	var $iframe = __.getIframeForUrl(url), height;

	__.removeClassFromModule($iframe);

	height = parseInt(height_, 10) + 'px';
	$iframe.height(height);

};

__.handler = function (event) {
	var data;
	if (typeof event === 'undefined' || typeof event.data === 'undefined') {
		return;
	}

	data = __.parseJsonDataFromEvent(event);

	if (!!data) {
		if (!!data.originurl && !!data.layerExternalizerBridgeSetHeight) {

			__.setAndAnimateIframeHeight(data.originurl, data.layerExternalizerBridgeSetHeight);
		}
		else if (!!data.height) {

			__.setIframeHeight(event.origin, data.height, data.url);
		}
		else if (!!data.shader) {

			__.toggleShader(data.shader === 'show', data.url);

		}
	}

};

__.addEvents = function () {

	if (window.addEventListener) {
		window.addEventListener('message', __.handler, false);
	}
	else {
		window.attachEvent('onmessage', __.handler);
	}

};

exports.initialize = function () {
	__.addEvents();
};

export {exports as iframeHeight};
