import {appEvents as EVENTS} from 'core-application';
import {dom as DOM_UTILS} from 'core-utils';
import {videoNew as VIDEO_NEW} from './video-new';
// Private Methods
/**
 * Private API
 *
 * @var {Object} __
 */
var __ = {},
	/**
	 * Public API
	 * @var {Object} exports
	 */
	exports = {
		'__': __
	};

__.NEXT = 'next';
__.PREV = 'prev';
__.itemPadding = 6;
__.mobile = false;
__.swipeStarted = false;
__.clickTimer = null; // timer property to detect double or single touch
// reference to the global eventEmitter
__.eventBus = null;
__.galleries = {};

/**
 * default Values used several times
 */
__.oDefaults = {
	module: '.nm-md-inline-mediagallery',
	arrowNext: '.nm-inline-mediagallery-next',
	arrowPrev: '.nm-inline-mediagallery-prev',
	disclaimer: '.nm-inline-mediagallery__asset-disclaimer',
	imageContainer: '.nm-inline-mediagallery-image-container',
	imageWrap: '.nm-inline-mediagallery-big-image-wrap',
	image: '.nm-inline-mediagallery-image-wrap',
	innerImage: '.nm-inline-mediagallery-image',
	closeFullscreenBtn: '.nm-inline-mediagallery-fullscreen-close-btn',
	classFullscreen: 'nm-md-mediagallery-fullscreen-mode',
	thumbnails: '.nm-inline-mediagallery-slider-item',
	sliderList: '.nm-inline-mediagallery-image-list',
	thumbSliderList: '.nm-inline-mediagallery-slider-list',
	sliderWrap: '.nm-inline-mediagallery-slider-list-wrap',
	sliderPrev: '.nm-inline-mediagallery-slider-prev',
	sliderNext: '.nm-inline-mediagallery-slider-next',
	videoPlayerPlay: '.nm-md-inline-mediagallery .nm-video .nm-j-play-btn',
	videoPlayerPause: '.nm-md-inline-mediagallery .nm-video .nm-j-play-btn .nm-video-button-pause',
	videoTarget: '.nm-prevent-video-click'
};

/**
 * setCurrentGallery - gets current gallery by an given child (f.e. arrow-button)
 * @param {HTMLElement} target_ target to get closest module
 * @return {void} returns nothing
 */
__.setCurrentGallery = function(target_) {
	__.currentGallery = DOM_UTILS.closest(target_, __.oDefaults.module);
};

/**
 * nextArrowClickHandler
 * handles next arrow click
 * @param {Event} event - click event
 * @return {void} returns nothing
 */
__.nextArrowClickHandler = function(event) {
	if (event && event.preventDefault) {
		event.preventDefault();
	}
	__.setCurrentGallery(event.target);
	__.setCurrentIndex(__.NEXT);
};

/**
 * prevArrowClickHandler
 * handles prev arrow click
 * @param {Event} event - click event
 * @return {void} returns nothing
 */
__.prevArrowClickHandler = function(event) {
	if (event && event.preventDefault) {
		event.preventDefault();
	}
	__.setCurrentGallery(event.target);
	__.setCurrentIndex(__.PREV);
};

/**
 * getCurrentIndex
 * the checked element from checkboxes to get selected image index
 * @return {number} returns current index
 */
__.getCurrentIndex = function() {
	var imageInputs = DOM_UTILS.getElementsArray(__.oDefaults.sliderList, __.currentGallery),
		currentIndex;
	imageInputs.forEach(function(item, index) {
		if (item.checked) {
			currentIndex = index;
		}
	});
	return currentIndex;
};

/**
 * getInputsCount
 * @return {number} returns the count of slides in gallery
 */
__.getInputsCount = function() {
	var imageInputs = DOM_UTILS.getElementsArray(__.oDefaults.sliderList, __.currentGallery);
	return imageInputs.length;
};

/**
 * changeInputPos
 * set checked attribute at newIndex
 * @param {number} newIndex_ - the new index
 * @return {void} returns nothing
 */
__.changeInputPos = function(newIndex_) {
	var inputs = DOM_UTILS.getElementsArray(__.oDefaults.sliderList, __.currentGallery);
	inputs.map(function(element) {
		element.checked = false;
	});
	inputs[newIndex_].checked = true;
};

/**
 * setCurrentIndex
 * calucate new index and trigger animation
 * @param {string} direction direction to animate
 * @return {void} returns nothing
 */
__.setCurrentIndex = function(direction) {
	var oldIndex = __.getCurrentIndex(),
		imagesLen = __.getInputsCount(),
		newIndex = -1,
		thumbs = DOM_UTILS.getElementsArray(__.oDefaults.thumbnails, __.currentGallery);
	if (direction === __.NEXT) {
		newIndex = oldIndex < imagesLen ? oldIndex + 1 : oldIndex;
	}
	else if (direction === __.PREV) {
		newIndex = oldIndex > 0 ? oldIndex - 1 : oldIndex;
	}
	if ((newIndex < 0) || (newIndex === imagesLen)) {
		return;
	}
	__.makeAnimation(thumbs, newIndex, direction);
};

/**
 * swapImages
 * @param {number} index_ - new index to find url from thumb list
 * @param {number} imageNumber_ - specify which image container
 * @return {void} - returns nothing
 */
__.swapImages = function(index_, imageNumber_) {
	var images = DOM_UTILS.getElementsArray(__.oDefaults.image, __.currentGallery),
		urls = __.getThumbUrls(index_),
		newUrl = urls.imageUrl,
		videoPosterUrl = urls.posterUrl,
		pictureTag = DOM_UTILS.getElement('picture', images[imageNumber_]);

	__.setArrowStyle(index_);
	__.updatePictureUrls(pictureTag, videoPosterUrl? videoPosterUrl : newUrl);

	if (videoPosterUrl) {
		__.addVideoClassToGalleryImages();
	}
	else {
		__.removeVideoClassFromGalleryImages();
	}
};

/**
 * swapAssetDisclaimer
 * @param {number} index_ - new index to find text from thumb list
 * @return {void} - returns nothing
 */
__.swapAssetDisclaimer = function(index_) {
	const disclaimers = __.currentGallery.querySelectorAll(__.oDefaults.disclaimer);
	const newDisclaimer = __.getCurrentDisclaimer(index_);

	[...disclaimers].forEach((disclaimer) => {
		disclaimer.innerHTML = newDisclaimer;
	});

};

/**
 * updatePictureUrls - updates all src and srscet urls within picture tag
 * @param {Element} pictureTag_ picture Tag, where the src and srcset attributes need to be udpated
 * @param {String} imageUrl_ new url value
 * @returns {void} returns nothing
 */
__.updatePictureUrls = function(pictureTag_, imageUrl_) {
	let srcAttribute = pictureTag_.querySelector('img').getAttribute('src');
	let sourceTags = pictureTag_.querySelectorAll('source');
	let newSrcValue = __.replaceStringBeforeQuestionMark(srcAttribute, imageUrl_);

	pictureTag_.querySelector('img').setAttribute('src', newSrcValue);
	[...sourceTags].forEach(function(sourceItem) {
		let newSourcesetValue = __.replaceStringBeforeQuestionMark(sourceItem.getAttribute('srcset'), imageUrl_);
		sourceItem.setAttribute('srcset', newSourcesetValue);
	});
};

/**
 * replaceStringBeforeQuestionMark - replaces the part of the url before "?" to keep the "?width" part of the url
 * @param {String} existingString_ current string value
 * @param {String} replacementString_ new string value
 * @returns {String} returns value with replaced first part
 */
__.replaceStringBeforeQuestionMark = function(existingString_, replacementString_) {
	let stringParts = existingString_.split('?');
	stringParts[0] = replacementString_;
	return stringParts.join('?');
};

/**
 * setArrowStyle
 * @param {number} index_ thums array index
 * @returns {void} returns nothing
 */
__.setArrowStyle = function(index_) {
	var navigation = DOM_UTILS.getElement('.nm-inline-mediagallery-navigation', __.currentGallery),
		thumbs = DOM_UTILS.getElementsArray(__.oDefaults.thumbnails, __.currentGallery),
		arrowStyle = thumbs[index_].getAttribute('data-arrow-style');

	if (arrowStyle === 'light') {
		navigation.classList.add('nm-inline-mediagallery-arrow-dark');
	}
	else {
		navigation.classList.remove('nm-inline-mediagallery-arrow-dark');
	}
};

/**
 * hideVideoPlayer
 * @returns {void} returns nothing
 */
__.hideVideoPlayer = function() {
	var videoPlayer = __.getVideoPlayer();
	if (videoPlayer) {
		videoPlayer.style.display = 'none';
	}
};

/**
 * handleTransition - adds animation class postfixed with direction
 * and removes class when animation has finished.
 * @param {string} direction_ - direction to animate
 * @param {number} index_ - index
 * @return {void} returns nothing
 */
__.handleTransition = function(direction_, index_) {
	var wrap = DOM_UTILS.getElement(__.oDefaults.imageWrap, __.currentGallery),
		animationClass = 'nm-inline-mediagallery-animate-' + direction_;

	DOM_UTILS.handleTransition(wrap, animationClass).then(function() {
		__.hideVideoPlayer();
		__.swapImages(index_, 0);
		__.swapAssetDisclaimer(index_);
		__.showVideoPlayer(index_);
	}).catch(function(err) {
		console.log('Promise not completed ', err);
	});
};

/**
 * getThumbUrls
 * function to get next image url from thumbnails with index
 * @param {number} index_ number of image in thumbnail list
 * @return {Object} returns object with all thumb urls
 */
__.getThumbUrls = function(index_) {
	var thumbs = DOM_UTILS.getElementsArray(__.oDefaults.thumbnails, __.currentGallery),
		thumb = {};
	thumb.imageUrl = thumbs[index_].getAttribute('data-full-image-url');
	thumb.posterUrl = thumbs[index_].getAttribute('data-video-poster');
	thumb.videoUrl = thumbs[index_].getAttribute('data-video-slide');
	thumb.vmsUrl = thumbs[index_].getAttribute('data-video-vms-slide');

	return thumb;
};

/**
 * getCurrentDisclaimer
 * function to get next image disclaimer from thumbnails with index
 * @param {number} index_ number of image in thumbnail list
 * @return {string} returns single disclaimer
 */
__.getCurrentDisclaimer = function(index_) {
	const thumbs = DOM_UTILS.getElementsArray(__.oDefaults.thumbnails, __.currentGallery);
	const assetDisclaimer = thumbs[index_].getAttribute('data-asset-disclaimer');

	return assetDisclaimer;
};

/**
 * showVideoPlayer
 * show or hide video player depending on video url
 * @param {number} index_ index to find video url
 * @return {void} returns nothing
 */
__.showVideoPlayer = function(index_) {
	var urls = __.getThumbUrls(index_),
		videoUrl = urls.videoUrl,
		vmsUrl = urls.vmsUrl,
		poster = urls.posterUrl,
		videoPlayer = __.getVideoPlayer(),
		video = videoPlayer.querySelector('video'),
		vmsExtension;
	if (videoUrl) {
		video.pause();
		VIDEO_NEW.resetVideoPlayer(videoPlayer, videoUrl, poster);
		videoPlayer.style.display = 'block';
	}
	else if (vmsUrl) {
		vmsExtension = __.getVmsExtension(screen.width);
		video.pause();
		VIDEO_NEW.resetVideoPlayer(videoPlayer, vmsUrl+vmsExtension, poster);
		videoPlayer.style.display = 'block';
	}
	else {
		video.pause();
		VIDEO_NEW.resetVideoPlayer(videoPlayer);
		videoPlayer.style.display = 'none';
	}
};

/**
 * getVmsExtension
 * depending on screen size get the vms url extension
 * @param {number} screenSize_ size of the users screen
 * @return {string} returns vms extension string
 */
__.getVmsExtension = function(screenSize_) {
	var vmsExtension = '_Content270p-Web.mp4';
	if (screenSize_ > 1281) {
		vmsExtension = '_Content1080p-Web.mp4';
	}
	else if (screenSize_ >= 890 && screenSize_ <= 1280) {
		vmsExtension = '_Content720p-Web.mp4';
	}
	else if (screenSize_ >= 705 && screenSize_ <= 889) {
		vmsExtension = '_Content500p-Web.mp4';
	}
	else if (screenSize_ >= 481 && screenSize_ <= 704) {
		vmsExtension = '_Content396p-Web.mp4';
	}
	return vmsExtension;
};

/**
 * addVideoClassToGalleryImages add class to set the correct css prop
 * @returns {void} returns nothing
 */
__.addVideoClassToGalleryImages = function() {
	var images = DOM_UTILS.getElementsArray('.nm-inline-mediagallery-image', __.currentGallery),
		bigImageWrap = DOM_UTILS.getElement('.nm-inline-mediagallery-big-image-wrap', __.currentGallery);
	images.forEach(function(image) {
		image.classList.add('nm-inline-mediagallery-video');
	});
	bigImageWrap.classList.add('nm-inline-mediagallery-video-hover');
};

/**
 * removeVideoClassFromGalleryImages add class to set the correct css prop
 * @returns {void} returns nothing
 */
__.removeVideoClassFromGalleryImages = function() {
	var images = DOM_UTILS.getElementsArray('.nm-inline-mediagallery-image', __.currentGallery),
		bigImageWrap = DOM_UTILS.getElement('.nm-inline-mediagallery-big-image-wrap', __.currentGallery);
	images.forEach(function(image) {
		image.classList.remove('nm-inline-mediagallery-video');
	});
	bigImageWrap.classList.remove('nm-inline-mediagallery-video-hover');

};

/**
 * getVideoPlayer - get video container inside media gallery
 * @return {HtmlElement} Video container element
 */
__.getVideoPlayer = function() {
	return DOM_UTILS.getElement('.nm-inline-mediagallery-image-container .nm-mediaplayer.nm-video', __.currentGallery);
};

/**
 * onSwipeStart
 * gets called when mousedown or touchstart event is fired.
 * Caches start click/touch point and registering end event which calls onSwipeEnd
 * @param {Event} startEvent_ click/touch start x
 * @return {void} returns nothing
 */
__.onSwipeStart = function(startEvent_) {
	__.swipeStarted = true;
	__.setCurrentGallery(startEvent_.target);
	let swipeStart = __.getEventPos(startEvent_),
		videoControls = DOM_UTILS.getElement('.nm-inline-mediagallery-image .nm-controls-wrap-video', __.currentGallery),
		overlayPlayBtn = DOM_UTILS.getElement('nm-inline-mediagallery-image .nm-video-button-play', __.currentGallery),
		eventType = startEvent_.type === 'touchstart' ? 'touchend' : 'mouseup',
		startTarget = startEvent_.target;
	if (startTarget === videoControls || videoControls.contains(startTarget) || startTarget === overlayPlayBtn) {
		return;
	}
	__.domEventDelegate.on(eventType, __.oDefaults.imageContainer, function bbb(endEvent) {
		if (__.swipeStarted) {
			__.swipeStarted = false;
			__.domEventDelegate.off(eventType, __.oDefaults.imageContainer, bbb);
			__.onSwipeEnd(eventType, startTarget, endEvent, swipeStart);
		}
	});
};

/**
 * onSwipeEnd description
 * gets called when mouseup or touchend event is fired
 * @param {string} eventType type of the event (mouseup/touchend)
 * @param {HtmlElement} startTarget startTarget
 * @param {HtmlElement} endTarget endTarget
 * @param {number} swipeStart  start position
 * @return {void} returns nothing
 */
__.onSwipeEnd = function(eventType, startTarget, endTarget, swipeStart) {
	var swipeEnd,
		swipeDirection,
		swipeDifference,
		swipeHeightDiff;
	swipeEnd = __.getEventPos(endTarget);
	swipeDirection = __.calculateSwipeDirection(swipeStart.x, swipeEnd.x);
	swipeDifference = (swipeStart.x > swipeEnd.x) ? swipeStart.x - swipeEnd.x : swipeEnd.x - swipeStart.x;
	swipeHeightDiff = (swipeStart.y > swipeEnd.y) ? swipeStart.y - swipeEnd.y : swipeEnd.y - swipeStart.y;

	if (swipeDifference > 50 && swipeHeightDiff <= 50) {
		__.setCurrentIndex(swipeDirection);
	}
	else if (swipeDifference === 0 && eventType === 'touchend') {
		if (__.clickTimer === null) {
			__.clickTimer = setTimeout(function() {
				__.clickTimer = null;
				__.openFullscreen(startTarget);
			}, 500);
		}
		else {
			clearTimeout(__.clickTimer);
			__.clickTimer = null;
		}
	}
};

/**
 * calculateSwipeDirection
 * @param {number} swipeStart start click/touch point
 * @param {number} swipeEnd   end click/touch point
 * @return {string} swipeDirection __.PREV/__.NEXT
 */
__.calculateSwipeDirection = function(swipeStart, swipeEnd) {
	var swipeDirection,
		currentIndex = __.getCurrentIndex(),
		inputsCount = __.getInputsCount();
	if (swipeEnd > swipeStart) {
		swipeDirection = __.PREV;
		if (currentIndex === 0) {
			return;
		}
	}
	else {
		swipeDirection = __.NEXT;
		if (currentIndex === inputsCount - 1) {
			return;
		}
	}
	return swipeDirection;
};

/**
 * getEventPos
 * return the event click/touch position
 * @param {Event} event_ click/touch event
 * @return {number} event position
 */
__.getEventPos = function(event_) {
	var pos = {};
	if (event_.type === 'touchmove' || event_.type === 'touchend') {
		if (event_.originalEvent) {
			pos.x = event_.originalEvent.pageX;
			pos.y = event_.originalEvent.pageY;
		}
		else if (event_.changedTouches) {
			pos.x = event_.changedTouches[0].pageX;
			pos.y = event_.changedTouches[0].pageY;
		}
	}
	else {
		if (event_.type === 'touchstart') {
			pos.x = event_.touches[0].pageX;
			pos.y = event_.touches[0].pageY;
		}
		else {
			pos.x = event_.pageX;
			pos.y = event_.clientY;
		}
	}
	return pos;
};

/**
 * openFullscreen - opens the native fullscreen mode
 * @param {Event} event_ - event
 * @return {void} returns nothing
 */
__.openFullscreen = function(event_) {
	var element,
		target = event_.target || event_;
	__.setCurrentGallery(target);
	if (__.isFullScreen()) {
		return;
	}
	element = DOM_UTILS.getElement(__.oDefaults.imageWrap, __.currentGallery);
	document.addEventListener('webkitfullscreenchange', __.toggleFullscreenClass, false);
	document.addEventListener('mozfullscreenchange', __.toggleFullscreenClass, false);
	document.addEventListener('fullscreenchange', __.toggleFullscreenClass, false);
	document.addEventListener('MSFullscreenChange', __.toggleFullscreenClass, false);
	if (element.requestFullScreen) {
		element.requestFullScreen();
	}
	else if (element.mozRequestFullScreen) {
		element.mozRequestFullScreen();
	}
	else if (element.webkitRequestFullScreen) {
		element.webkitRequestFullScreen();
	}
};

/**
 * closeFullscreen - closes the native fullscreen mode
 * @return {void} returns nothing
 */
__.closeFullscreen = function() {
	if (__.isFullScreen()) {
		document.exitFullscreen();
	}
};

/**
 * isFullScreen - checks if browser is in fullscreen mode
 * @return {boolean} returns true/false
 */
__.isFullScreen = function() {
	return !!(document.fullscreenElement ||
		document.webkitFullscreenElement ||
		document.mozFullScreenElement ||
		document.msFullscreenElement ||
		document.fullScreen ||
		document.mozFullScreen);
};

/**
 * toggleFullscreenClass - toggles a class on module
 * @return {void} returns nothing
 */
__.toggleFullscreenClass = function() {
	var module = DOM_UTILS.closest(event.target, __.oDefaults.module);
	if (__.isFullScreen()) {
		module.classList.add(__.oDefaults.classFullscreen);
	}
	else if (!__.isFullScreen()) {
		module.classList.remove(__.oDefaults.classFullscreen);
		document.removeEventListener('webkitfullscreenchange', __.toggleFullscreenClass, false);
		document.removeEventListener('mozfullscreenchange', __.toggleFullscreenClass, false);
		document.removeEventListener('fullscreenchange', __.toggleFullscreenClass, false);
		document.removeEventListener('MSFullscreenChange', __.toggleFullscreenClass, false);
	}
};

/**
 * thumbClickHandler
 * handles click on gallery thumbnails
 * @param {Event} event passed click event
 * @return {void} returns nothing
 */
__.thumbClickHandler = function(event) {
	let clickedItem = event.target,
		thumbs,
		currentIndex,
		newIndex,
		direction;

	if (clickedItem.classList.contains('nm-image')) {
		clickedItem = clickedItem.parentElement;
	}

	__.setCurrentGallery(clickedItem);
	thumbs = DOM_UTILS.getElementsArray(__.oDefaults.thumbnails, __.currentGallery);
	currentIndex = __.getCurrentIndex();

	thumbs.forEach(function(thumbItem, index) {
		if (thumbItem === clickedItem.parentElement) {
			newIndex = index;
		}
	});
	if (currentIndex === newIndex) {
		return;
	}
	direction = currentIndex < newIndex ? __.NEXT : __.PREV;
	__.makeAnimation(thumbs, newIndex, direction);
};

/**
 * makeAnimation
 * @param {Array} thumbs_ Array of slider thumb items (HTMLElements)
 * @param {number} newIndex_ newIndex
 * @param {string} direction_ direction for animation
 * @returns {void} returns nothing
 */
__.makeAnimation = function(thumbs_, newIndex_, direction_) {
	if (!__.isVisible(thumbs_[newIndex_], true)) {
		__.centerHiddenThumb(thumbs_, newIndex_);
	}
	__.changeInputPos(newIndex_);
	__.swapImages(newIndex_, 1);
	__.swapAssetDisclaimer(newIndex_);
	__.handleTransition(direction_, newIndex_);
};

__.centerHiddenThumb = function(sliderItems_, newIndex_) {
	var xValue = sliderItems_[newIndex_],
		slider = DOM_UTILS.getElement(__.oDefaults.thumbSliderList, __.currentGallery),
		sliderWrap = DOM_UTILS.getElement(__.oDefaults.sliderWrap, __.currentGallery),
		sliderNav = __.getSliderNavigation(__.currentGallery);

	var targetValue = xValue.offsetLeft - ((sliderWrap.clientWidth - xValue.clientWidth)/2),
		maxValue = slider.clientWidth - sliderWrap.clientWidth;
	if (targetValue > maxValue) {
		targetValue = maxValue;
	}
	__.scrollElementToX(sliderWrap, targetValue, 300).then(function() {
		__.hideSliderNavigation(sliderItems_, sliderNav);
	});
};
/*
 * scrollXvalue animation method
 * @param {HtmlElement} element - scroll container
 * @param {number} scrollXFinal_ - scroll to value
 * @param {number} scrollDuration - duration im milliseconds
 * @returns	{void} nothing
 */
var _leftAnimation, // eslint-disable-line vars-on-top
	_rightAnimation,
	scrollXFinal;
__.scrollElementToX = function(element, scrollXFinal_, scrollDuration) {
	scrollXFinal = scrollXFinal_;
	return new Promise(function(resolve) {
		if (scrollXFinal < 0) {
			scrollXFinal = 0;
		}
		if (_leftAnimation) {
			cancelAnimationFrame(_leftAnimation);
			_leftAnimation = undefined;
		}
		if (_rightAnimation) {
			cancelAnimationFrame(_rightAnimation);
			_rightAnimation = undefined;
		}
		var scrollLeft = element.scrollLeft, // eslint-disable-line vars-on-top
			scrollStep = Math.PI / (scrollDuration / 15),
			cosParameter = scrollLeft / 2,
			scrollCount = 0,
			finalValue,
			scrollMargin;
		if (scrollXFinal < element.scrollLeft) {
			_leftAnimation = window.requestAnimationFrame(stepLeft);// eslint-disable-line no-use-before-define
		}
		else {
			cosParameter = scrollXFinal / 2;
			_rightAnimation = window.requestAnimationFrame(stepRight);// eslint-disable-line no-use-before-define
		}

		function stepLeft() {
			setTimeout(function() {
				if (element.scrollLeft > Math.round(scrollXFinal)) {
					_leftAnimation = window.requestAnimationFrame(stepLeft);
					scrollCount = scrollCount + 1;
					scrollMargin = cosParameter - cosParameter * Math.cos(scrollCount * scrollStep);
					finalValue = scrollLeft - scrollMargin;
					if (finalValue < scrollXFinal) {
						finalValue = scrollXFinal;
					}
					element.scrollLeft = finalValue;
				}
				else {
					cancelAnimationFrame(_leftAnimation);
					resolve();
				}
			}, 15);
		}

		function stepRight() {
			setTimeout(function() {
				if (Math.round(element.scrollLeft) < Math.round(scrollXFinal)-1) {
					_leftAnimation = window.requestAnimationFrame(stepRight);
					scrollCount = scrollCount + 1;
					scrollMargin = cosParameter - cosParameter * Math.cos(scrollCount * scrollStep);
					finalValue = scrollLeft + scrollMargin;
					if (finalValue > scrollXFinal) {
						finalValue = scrollXFinal;
					}
					element.scrollLeft = finalValue;
				}
				else {
					cancelAnimationFrame(_leftAnimation);
					resolve();
				}
			}, 15);
		}
	});
};

/**
 * isElementVisible
 * @param {HtmlElement} element_ to check if visible
 * @param {boolean} fullVisible_ if true only returns true if the all object rect is visible
 * @return {boolean} returns if element is visible
 */
__.isVisible = function(element_, fullVisible_) {
	var parentBound = element_.parentNode.parentNode.getBoundingClientRect(),
		rectBound = element_.getBoundingClientRect(),
		rechts = (parseInt(rectBound.right, 10) <= parseInt(parentBound.right, 10)+2) && (parseInt(rectBound.right, 10) >= parseInt(parentBound.left, 10)),
		links = parseInt(rectBound.left, 10) >= parseInt(parentBound.left, 10) && parseInt(rectBound.left, 10) <= parseInt(parentBound.right, 10);
	if (fullVisible_) {
		return rechts && links;
	}
	return rechts || links;
};

/**
 * sliderArrowClickHandler
 * @param {Event} event_ event to set __.currentGallery
 * @return {void} returns nothing
 */
__.sliderArrowClickHandler = function(event_) {
	event_.preventDefault();
	__.setCurrentGallery(this);
	let width = 0,
		maxDistance,
		scrollToValue = 0,
		direction = this.getAttribute('data-direction'),
		slider = DOM_UTILS.getElement(__.oDefaults.thumbSliderList, __.currentGallery),
		sliderElements = DOM_UTILS.getElementsArray(__.oDefaults.thumbnails, __.currentGallery),
		sliderWrap = DOM_UTILS.getElement(__.oDefaults.sliderWrap, __.currentGallery),
		activeBoarder = DOM_UTILS.getElement('.nm-inline-mediagallery-slider-item.nm-inline-mediagallery-slider-item-active', __.currentGallery),
		left = sliderWrap.scrollLeft;

	__.sliderWrapWidth = __.getClientWidth(sliderWrap);
	__.sliderWidth = __.getClientWidth(slider);
	maxDistance = (__.sliderWidth - __.sliderWrapWidth) + 2; // plus 2 pixels because of the active boarder

	sliderElements.forEach(function(item) {
		if (__.isVisible(item, true) && item !== activeBoarder) {
			width = width + item.clientWidth + __.itemPadding;
		}
	});

	if (direction === __.PREV) {
		// GO LEFT
		scrollToValue = (left - width) > 0 ? (left - width): 0;
	}
	else {
		// GO RIGHT
		scrollToValue = (left + width) > maxDistance ? maxDistance : (left + width);
	}
	__.scrollElementToX(sliderWrap, scrollToValue - 2, 300);
};

/**
 * scrollHandler
 * @param {Event} event_ scrollEvent
 * @returns {void} nothing
 */
__.scrollHandler = function(event_) {
	__.setCurrentGallery(this);
	let sliderNav = __.getSliderNavigation(__.currentGallery),
		sliderElements = DOM_UTILS.getElementsArray(__.oDefaults.thumbnails, __.currentGallery);
	__.hideSliderNavigation(sliderElements, sliderNav);
	__.setGalleryCenterMostElement(event_.target);
};

/**
 * hideSliderNavigation
 * @param {Array} sliderArray Array with slider elements ([HtmlElement])
 * @param {HTMLElement} sliderNav slider navigation
 * @return {void} returns nothing
 */
__.hideSliderNavigation = function(sliderArray, sliderNav) {
	__.isVisible(sliderArray[0], true) ? sliderNav.prev.style.display = 'none' : sliderNav.prev.style.display = 'block';// eslint-disable-line no-unused-expressions
	// we use sliderArray-2 for the last item because -1 ist the active border
	__.isVisible(sliderArray[sliderArray.length - 2], true) ? sliderNav.next.style.display = 'none' : sliderNav.next.style.display = 'block';// eslint-disable-line no-unused-expressions
};

/**
 * getSliderNavigation
 * get slider next and prev button
 * @param {HTMLElement} gallery_ - gallery context
 * @returns {Object} returns slider navigation elements object
 */
__.getSliderNavigation = function(gallery_) {
	var sliderNavigation = {};
	sliderNavigation.prev = DOM_UTILS.getElement(__.oDefaults.sliderPrev, gallery_);
	sliderNavigation.next = DOM_UTILS.getElement(__.oDefaults.sliderNext, gallery_);
	return sliderNavigation;
};

/**
 * getClientWidth
 * @param {HtmlElement} element element to get client width from
 * @return {number} return width of element
 */
__.getClientWidth = function(element) {
	return element.clientWidth;
};

/**
 * addPreventDefault
 * @param {Event} e event to add prevent default
 * @return {void} returns nothing
 */
__.addPreventDefault = function(e) {
	if (!__.mobile) {
		e.preventDefault();
	}
};

/**
 * addGalleryVideoEvents
 * @return {void} returns noting
 */
__.addGalleryVideoEvents = function() {
	var videos = DOM_UTILS.getElementsArray('.nm-inline-mediagallery-image video');
	videos.forEach(function(video) {
		video.addEventListener('playing', __.addPlayingClass);
	});
};

/**
 * addPlayingClass
 * @param {Event} event  - target is video element
 * @return {void} returns nothing
 */
__.addPlayingClass = function(event) {
	__.setCurrentGallery(event.target);
	let imageContainer = DOM_UTILS.getElement('.nm-inline-mediagallery-image-container', __.currentGallery),
		video = event.target;
	imageContainer.classList.add('nm-inline-mediagallery-video-playing');
	__.hideNavigation();
	video.addEventListener('pause', __.removePlayingClass);
	video.addEventListener('ended', __.removePlayingClass);
};

/**
 * removePlayingClass
 * @param {Event} event  - target is video element
 * @return {void} returns nothing
 */
__.removePlayingClass = function(event) {
	var imageContainer = DOM_UTILS.getElement('.nm-inline-mediagallery-image-container', __.currentGallery),
		video = event.target;
	imageContainer.classList.remove('nm-inline-mediagallery-video-playing');
	__.showNavigation();
	video.removeEventListener('pause', __.removePlayingClass);
	video.removeEventListener('ended', __.removePlayingClass);
};

/**
 * showNavigation - shows gallery navigation and gradient
 * @return {void} returns noting
 */
__.showNavigation = function() {
	var gradientContainer = DOM_UTILS.getElement('.nm-inline-mediagallery-navigation-gradients', __.currentGallery),
		galleryNavigation = DOM_UTILS.getElement('.nm-inline-mediagallery-navigation', __.currentGallery);
	gradientContainer.classList.remove('nm-hidden');
	galleryNavigation.classList.remove('nm-hidden');
};

/**
 * hideNavigation - hides gallery navigation and gradient
 * @return {void} returns noting
 */
__.hideNavigation = function() {
	var gradientContainer = DOM_UTILS.getElement('.nm-inline-mediagallery-navigation-gradients', __.currentGallery),
		galleryNavigation = DOM_UTILS.getElement('.nm-inline-mediagallery-navigation', __.currentGallery);
	gradientContainer.classList.add('nm-hidden');
	galleryNavigation.classList.add('nm-hidden');
};

/**
 * preventImgDrag - prevents gallery image from dragging
 * @returns {boolean} returns always false
 */
__.preventImgDrag = function() {
	return false;
};

/**
 * orientationChangeHandler gets called when device orientation changes
 * @return {void} return nothing
 */
__.orientationChangeHandler = function() {
	__.centerAllGalleryThumbs();
};

/**
 * setGalleryCenterMostElement add centermost thumbnail for gallery module
 * @param {HTMLElement} target_ target from scroll handler
 * @returns {void} returns nothing
 */
__.setGalleryCenterMostElement = function(target_) {
	var centermost = __.getCenterMostElementOfParent(target_),
		currGalleryId = __.currentGallery.getAttribute('data-gallery-id');
	__.galleries[currGalleryId] = centermost;
};

/**
 * getCenterMostElementOfParent - get the centermost element inside of scroll container
 * @param {HTMLElement} parentElement_ - wrapper element
 * @return {HTMLElement} centermost element
 */
__.getCenterMostElementOfParent = function(parentElement_) {
	var sliderElements = DOM_UTILS.getElementsArray('.nm-inline-mediagallery-slider-item', parentElement_),
		wrapCenter = parentElement_.getBoundingClientRect().right - (parentElement_.clientWidth/2),
		centerMostElement,
		elementCenter,
		elementsCenterToParentCenter;

	sliderElements.forEach(function(ele) {
		if (__.isVisible(ele, true)) {
			elementCenter = ele.getBoundingClientRect().right - (ele.clientWidth/2);
			elementsCenterToParentCenter = Math.abs(wrapCenter - elementCenter);
			if (!centerMostElement) {
				centerMostElement = {};
				centerMostElement.abstand = elementsCenterToParentCenter;
				centerMostElement.element = ele;
			}
			else {
				if (elementsCenterToParentCenter < centerMostElement.abstand) {
					centerMostElement.abstand = elementsCenterToParentCenter;
					centerMostElement.element = ele;
				}
			}
		}
	});
	return centerMostElement.element;
};

/**
 * centerAllGalleryThumbs - centers last centermost thumbnail after orientation change
 * @return {void} returns nothing
 */
__.centerAllGalleryThumbs = function() {
	var wrap;
	Object.keys(__.galleries).forEach(function(key) {
		wrap = DOM_UTILS.closest(__.galleries[key], __.oDefaults.sliderWrap);
		__.centerThumb(wrap, __.galleries[key]);
	});
};

/**
 * centerThumb - calculates thumbs center position and sets scroll left value
 * @param {HTMLElelemt} sliderWrap wrapper which has overflow scroll set
 * @param {HTMLElement} thumb_ single thumb element
 * @returns {void} returns nothing
 */
__.centerThumb = function(sliderWrap, thumb_) {
	var targetValue = thumb_.offsetLeft - ((sliderWrap.clientWidth - thumb_.clientWidth)/2);
	sliderWrap.scrollLeft = targetValue;
};

/**
 * saveAllGalleryInstancesToGlobalObject - saves all instances of inline gallery with current centermost thumbnail
 * @returns {void} returns nothing
 */
__.saveAllGalleryInstancesToGlobalObject = function() {
	var galleyModules = DOM_UTILS.getElementsArray(__.oDefaults.module),
		wrap;
	galleyModules.forEach(function(gallery) {
		wrap = DOM_UTILS.getElement(__.oDefaults.sliderWrap, gallery);
		__.galleries[gallery.getAttribute('data-gallery-id')] = __.getCenterMostElementOfParent(wrap);
	});
};

/**
 * stopVideoOnScrollOut
 * gets called on gallery video play
 * @param {Event} event_ - click event
 * @returns {void} returns nothing
 */
__.stopVideoOnScrollOut = function(event_) {
	var videoContainer = DOM_UTILS.closest(event_.target, '.nm-video'),
		video = DOM_UTILS.getElement('video', videoContainer);

	__.videoScrollHandler = DOM_UTILS.throttle(__.handleScrollVideo.bind(video), 15);
	document.addEventListener('scroll', __.videoScrollHandler);
};

/**
 * handleScrollVideo - checks if gallery video is out of viewport and stops video if true
 * @returns {void} - returns nothing
 */
__.handleScrollVideo = function() {
	var video = this,
		videoContainer,
		galleryContainer,
		videoVisibility = DOM_UTILS.getVisibleVerticalPercentageInViewport(video);

	if (videoVisibility === 0) {
		videoContainer = DOM_UTILS.closest(video, '.nm-video');
		galleryContainer = DOM_UTILS.closest(videoContainer, '.nm-inline-mediagallery-image-container.nm-inline-mediagallery-video-playing');
		video.pause();
		// remove classes to show play buttons and gradients
		if (videoContainer && galleryContainer) {
			videoContainer.classList.remove('nm-video-playing');
			galleryContainer.classList.remove('nm-inline-mediagallery-video-playing');
		}
		document.removeEventListener('scroll', __.videoScrollHandler);
	}
};

/**
 * addEvents - adds several dom events
 * @return {void} returns nothing
 */
__.addEvents = function() { // eslint-disable-line max-statements
	__.domEventDelegate.on('click', __.oDefaults.arrowNext, __.nextArrowClickHandler);
	__.domEventDelegate.on('click', __.oDefaults.arrowPrev, __.prevArrowClickHandler);
	__.domEventDelegate.on('click', __.oDefaults.thumbnails, __.thumbClickHandler);
	__.domEventDelegate.on('mousedown', __.oDefaults.imageContainer, __.onSwipeStart);
	__.domEventDelegate.on('touchstart', __.oDefaults.imageContainer, __.onSwipeStart);
	__.domEventDelegate.on('dblclick', '.nm-inline-mediagallery-image', __.openFullscreen);
	__.domEventDelegate.on('keyup', 'body', __.closeFullscreen);
	__.domEventDelegate.on('click', __.oDefaults.closeFullscreenBtn, __.closeFullscreen);
	__.domEventDelegate.on('click', __.oDefaults.sliderPrev, __.sliderArrowClickHandler);
	__.domEventDelegate.on('click', __.oDefaults.sliderNext, __.sliderArrowClickHandler);
	__.domEventDelegate.on('scroll', __.oDefaults.sliderWrap, DOM_UTILS.throttle(__.scrollHandler, 15));
	__.domEventDelegate.on('dragstart', '.nm-inline-mediagallery-image img', __.preventImgDrag);
	__.domEventDelegate.on('click', __.oDefaults.videoPlayerPlay, __.stopVideoOnScrollOut);
	__.eventBus.on(EVENTS.LAYER_LOADED, __.addGalleryVideoEvents);
	__.eventBus.on(EVENTS.LAYER_LOADED, __.saveAllGalleryInstancesToGlobalObject);
	__.eventBus.on(EVENTS.LAYER_LOADED, __.resizeHandler);
	window.addEventListener('resize', __.resizeHandler);
	window.addEventListener('orientationchange', __.orientationChangeHandler);

};

/**
 * resizeHandler - checks all galleries if sliderNavigation is visible
 * @return {void} returns nothing
 */
__.resizeHandler = function() {
	var galleries = DOM_UTILS.getElementsArray(__.oDefaults.module);
	galleries.forEach(function(gallery) {
		var sliderNav = __.getSliderNavigation(gallery),
			sliderElements = DOM_UTILS.getElementsArray(__.oDefaults.thumbnails, gallery);
		__.hideSliderNavigation(sliderElements, sliderNav);
	});
};

exports.initialize = function(globalEventBus) {
	__.eventBus = globalEventBus;
	__.domEventDelegate = DOM_UTILS.getEventDelegate('body');
	__.addEvents();
	__.addGalleryVideoEvents();
	__.saveAllGalleryInstancesToGlobalObject();
	__.resizeHandler();
};

export {exports as inlineMediagallery};
