import {appEvents as EVENTS} from 'core-application';
const __ = {},
	exports = {
		__: __
	};
__.oDefaults = {
	sSelectorCounterContainer: '.nm-md-mediagallery-media-list .nm-counter',
	sSelectorActiveTxt: '.nm-active',
	sSelectorTotalTxt: '.nm-total'
};
__.addEvents = function() {
	__.eventBus.on(EVENTS.MEDIAGALLERY_ITEM_CHANGED, __.handleUpdates);
};
__.handleUpdates = function(data_) {
	__.updateCounter(data_.index, data_.maxIndex);
};
__.updateCounter = function(curr_, max_) {
	var maxIndex = max_ + 1;
	var index = curr_ + 1;
	var activeEl = document.querySelector(__.oDefaults.sSelectorCounterContainer + ' ' + __.oDefaults.sSelectorActiveTxt);
	var totalEl = document.querySelector(__.oDefaults.sSelectorCounterContainer + ' ' + __.oDefaults.sSelectorTotalTxt);
	if (!!activeEl) {
		activeEl.innerHTML = index;
	}
	if (!!totalEl) {
		totalEl.innerHTML = maxIndex;
	}
};
exports.initialize = function(eventEmitter_) {
	__.eventBus = eventEmitter_;
	__.addEvents();
};
export {exports as mediagalleryCounter};
