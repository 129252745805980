import {appEvents as EVENTS} from 'core-application';
// Private Methods
const __ = {},
	// Public API
	exports = {
		__: __
	};

__.oDefaults = {
	sSelectorModule: '.nm-md-mediagallery-media-list',
	sSelectorCategoryItem: '.nm-mediagallery-categories li',
	sSelectorDescription: '.nm-mediagallery-thumbs-description p',
	sSelectorThumbs: '.nm-mediagallery-show-thumbs',
	sClassShowThumbs: 'nm-md-mediagallery-media-list-detail',
	sSelectorMediaItem: '.nm-mediagallery-item',
	sSelectorThumbslider: '.nm-mediagallery-thumbs-slider',
	sSelectorThumbList: '.nm-mediagallery-thumbs-slider ul',
	animationDuration: 800
};

__.addEvents = function() {
	__.eventBus.on(EVENTS.MEDIAGALLERY_ITEM_CHANGED, __.handleItemUpdates);
	__.eventBus.on(EVENTS.MEDIAGALLERY_CATEGORY_CHANGED, __.handleCategoryUpdates);
	jQuery('body').on('click', __.oDefaults.sSelectorThumbs, __.handleShowThumbs);
};

/**
 * update selected status for selected item
 * @param {Object} data_ - data
 * @returns {void} nothing
 */
__.handleItemUpdates = function(data_) {
	__.centerSelectedItem(data_);
	if (!data_.changed) {
		return false;
	}
	let $sliderItems = jQuery(__.oDefaults.sSelectorMediaItem);
	let selectedItem = data_.item;
	let itemID = selectedItem.id;
	$sliderItems.removeClass('nm-active');
	jQuery('#' + itemID).addClass('nm-active');
	__.updateDescriptionText(data_.item.description);
};

__.centerSelectedItem = function(data_) {
	if (!!data_ && !!data_.item && data_.item.id) {
		let $sliderContainer = jQuery(__.oDefaults.sSelectorThumbslider);
		let $sliderList = jQuery(__.oDefaults.sSelectorThumbList);
		// slides are scollable?
		if ($sliderContainer.width() < $sliderList.width()) {
			let $selectedItem = jQuery('#' + data_.item.id),
				itemWidth = $selectedItem.width(),
				// item´s x-center
				xPos = $selectedItem.offset().left + (itemWidth * 0.5),
				stageCenter = $sliderContainer.width() * 0.5,
				deltaCenter = (stageCenter - xPos),
				currentMargin = parseInt($sliderList.css('margin-left'), 10),
				newMargin = currentMargin + deltaCenter + (itemWidth * 0.5),
				maxMargin = $sliderContainer.width() - $sliderList.width();
			if (newMargin < 0) {
				// do not move is item is on the end
				newMargin = (newMargin < maxMargin) ? maxMargin : newMargin;
				$sliderList.css({
					'margin-left': Math.round(newMargin) + 'px'
				});
			}
			else {
				$sliderList.removeAttr('style');
			}
		}
		else {
			$sliderList.removeAttr('style');
		}
	}
};

/*
 * show/hide thumbnailslider
 */
__.handleShowThumbs = function(event_) {
	__.cancelEvent(event_);
	jQuery(__.oDefaults.sSelectorModule).toggleClass(__.oDefaults.sClassShowThumbs);
	let delay = __.oDefaults.animationDuration;
	if (jQuery(__.oDefaults.sSelectorModule).hasClass(__.oDefaults.sClassShowThumbs)) {
		delay = 0;
	}
	setTimeout(function() {
		__.eventBus.emit(EVENTS.MEDIAGALLERY_RESIZED);
	}, delay);
};

/**
 * update all visible items within the selected category
 * @param {Object} data_ - data
 * @returns {void} nothing
 */
__.handleCategoryUpdates = function(data_) {
	var $categoryItems = jQuery(__.oDefaults.sSelectorCategoryItem);
	// hide all category items
	$categoryItems.removeClass('nm-active');
	// show slected category´s items only
	$categoryItems.filter('[data-category="' + data_.category + '"]').addClass('nm-active');
	let $sliderItems = jQuery(__.oDefaults.sSelectorMediaItem);
	if (data_.category === 'all') {
		$sliderItems.addClass('nm-selected');
	}
	else {
		// hide all items
		$sliderItems.removeClass('nm-selected');
		// show slected items matching the selected category only
		$sliderItems.filter('[data-category="' + data_.category + '"]').addClass('nm-selected');
	}
};

/**
 * update description textfield and resize/center content
 * @param { string} text_ - description test
 * @returns {void}
 */
__.updateDescriptionText = function(text_) {
	var delay = 300;
	jQuery(__.oDefaults.sSelectorDescription).css('opacity', '0');
	setTimeout(function() {
		document.querySelectorAll(__.oDefaults.sSelectorDescription)[0].innerHTML = text_;
		__.eventBus.emit(EVENTS.MEDIAGALLERY_SLIDED);
	}, delay);
	setTimeout(function() {
		jQuery(__.oDefaults.sSelectorDescription).css('opacity', '1');
	}, (__.oDefaults.animationDuration + delay));
};

/**
 * prevent default event behavior
 * @param {Event} event_ e.g. e click event
 * @returns {void}
 */
__.cancelEvent = function(event_) {
	if (!!event_ && typeof event_.preventDefault === 'function') {
		event_.preventDefault();
	}
};

exports.initialize = function(eventEmitter_) {
	__.eventBus=eventEmitter_;
	__.addEvents();
};

export {exports as mediagalleryThumbnailSlider};
