import {stateRegistry} from 'microkernel';

/**
 * Footnote singleton
 */
class Footnote {
	/**
	 * subscribes to data store changes
	 * @constructor
	 */
	constructor() {
		if (Footnote._instance) {
			return Footnote._instance;
		}

		if (!SETUPS.get('nemo.footnote-engine.active')) {
			this._hideOrShowFootnote = this._hideOrShowFootnote.bind(this);
			stateRegistry.subscribeToStore('dbadPageStoreFootnotes', this._hideOrShowFootnote);
		}

		Footnote._instance = this;
	}

	/**
	 * @returns {void}
	 * @private
	 */
	_bindElements() {
		this.footnoteElements = document.getElementsByClassName('audi-footnotes');
	}

	/**
	 * hides or shows footnotes depending on the current state
	 * @param {Object} state - The current state.
	 * @returns {void}
	 * @private
	 */
	_hideOrShowFootnote(state) {
		this._bindElements();

		let footnoteElementsLength = this.footnoteElements.length;

		for (let i = 0; i < footnoteElementsLength; i += 1) {
			let footnoteElement = this.footnoteElements[i];

			if (state.isNemoHome) {
				footnoteElement.classList.add('nm-hidden');
			}
			else {
				footnoteElement.classList.remove('nm-hidden');
			}
		}
	}
}

export const footnote = new Footnote();
