import {modalLayer} from 'core-application';
import {modalLayerInterstitialTemplate} from './modal-layer-interstitial-template';
import {template} from 'core-utils';
import {content} from 'core-application';
import {appEvents as APPEVENTS} from 'core-application';
import {router as ROUTER} from 'core-application';

export default class ModalLayerInterstitialElement extends HTMLElement {

	constructor(data_ = {}) {
		super();
		this._bindContextToFunctions();
		this.data = Object.assign({}, data_.dataset);
	}

	/**
	 * Returns the identificator for this type of layer
	 * @returns {string} Identificator
	 */
	static get type() {
		return 'ModalLayerInterstitialElement';
	}

	/**
	* static getter function for default CSS Selectors
	* @static
	* @returns {Object} defaults css selectors
	*/
	static get defaults () {
		return {
			closeButton: '.nm-button-cancel'
		};
	}

	/**
	 * Returns a promise that resolves to an instance of ModalLayerInterstitialElement
	 * @param {Object} data_ Modal layer content data
	 * @returns {Promise} The promise, that resolves to an instance of ModalLayerInterstitialElement
	 */
	static async getContent(data_) {
		return new ModalLayerInterstitialElement(data_);
	}

	/**
	 * addEvents
	 * @returns {void} returns nothing
	 */
	addEvents() {
		this.closeButton.addEventListener('click', this._closeLayer);
	}

	/**
	 * removeEvents
	 * @returns {void} returns nothing
	 */
	removeEvents() {
		this.closeButton.removeEventListener('click', this._closeLayer);
	}

	/**
	 * Runs automatically when Element is hook'd on the DOM
	 * @returns {void} returns nothing
	 */
	connectedCallback() {
		this._render().then(() => this.addEvents());
	}

	/**
	 * _bindContextToFunctions - bind 'this' context to necessary functions
	 * @returns {void} returns nothing
	 */
	_bindContextToFunctions() {
		this._closeLayer = this._closeLayer.bind(this);
		this._render = this._render.bind(this);
	}

	/**
	 * _render
	 * @returns {void} returns nothing
	 */
	async _render() {
		const contentUrl = SETUPS.get('nemo.InterstitialLayerLink');
		this.data.body = await this._fetchContent(contentUrl);
		this.innerHTML = template.render(modalLayerInterstitialTemplate, this.data);
		this.closeButton = this.querySelector(ModalLayerInterstitialElement.defaults.closeButton);

		// dispatch the custom event to retrigger layer loaded content.
		const customEvent = new CustomEvent('content:rendered', {detail: {element: this}});
		document.dispatchEvent(customEvent);
	}

	/**
	 * Make a HTTP request and return the HTML body text
	 *
	 * @param {string} link URL of the template to display inside the layer
	 * @returns {void}
	 */
	async _fetchContent(link) {
		try {
			return await content.getContent(link, false);
		}
		catch (Error) {
			return Error.message;
		}
	}

	/**
	 * beforeClose is called from the  wrapper
	 * @returns {void} returns nothing
	 */
	beforeClose() {
		this._dispatchCloseInterstitialEvent();
	}

	/**
	 * _closeLayer It fires when an element inside this template initiates a close layer event e.g clicking on the close button in this template
	 * @param {Event} event_ - the click event
	 * @returns {void} returns nothing
	 */
	_closeLayer(event_) {
		event_.preventDefault();
		event_.stopPropagation();
		this._triggerClose();

		let href = event_.target.getAttribute('href');

		if (!href || href === '#') {
			return;
		}

		ROUTER.open('', href);
	}

	/**
	 * Custom Event to inform interstitial-layer.js the intersitital modal layer is closed
	 * @returns {void} returns nothing
	 */
	_dispatchCloseInterstitialEvent() {
		document.dispatchEvent(
			new CustomEvent(APPEVENTS.INTERSTITIAL_LAYER_CLOSE)
		);
	}

	/**
	 * _triggerClose - searches for modal layer wrapper, and calls async close function on it.
	 * @param {Boolean} forceClose - true closes layer without transition
	 * @returns {void} returns nothing
	 */
	async _triggerClose(forceClose=false) {
		try {
			const wrapper = this.closest('modal-layer-wrapper');
			await wrapper.close(this, forceClose);
		}
		catch (error) {
			console.warn('_triggerClose - modal layer wrapper missing.');
			throw error;
		}
	}

}

modalLayer.registerLayerType(ModalLayerInterstitialElement);

if (window.customElements.get('modal-layer-interstitial-element') === undefined) {
	window.customElements.define('modal-layer-interstitial-element', ModalLayerInterstitialElement);
}
