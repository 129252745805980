const dotTemplate = `
	<div class="modal-layer__inner nm-modal-layer__inner" role="alert">
		<div class="nm-modal-layer__header">
			<div class="nm-modal-layer__headline-wrap" data-type="{{=it.type}}">
				<h2 class="nm-modal-layer__headline audi-headline-order-2">{{=it.headline}}</h2>
			</div>
		</div>
		<div class="nm-modal-layer__inner-content">
			<div data-layer-options='{"onEscClick": false, "onShaderClick": false, "centerVertically": true, "addCssClass": "nm-module-message-layer"}'>
			<p class="nm-modal-layer__message audi-copy-m">{{=it.message}}</p>
		</div>
		<div class="nm-modal-layer__footer">
			<a class="nm-modal-layer__confirm-button audi-button" href="{{=it.actionButtonLinkUrl}}">
				<span class="audi-button__text">
					{{=it.actionButtonLinkText}}
				</span>
			</a>
		</div>
	</div>
`;

export {dotTemplate as messageLayerTemplate};
