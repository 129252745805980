import '../runtimeWebpackPublicPath';
import {appEvents as EVENTS} from 'core-application';
import {dom as DOM_UTILS} from 'core-utils';
import {device as DEVICE} from 'core-utils';

const __ = {},
	exports = {
		'__': __
	};

__.oDefaults = {
	'sSelectorPanoramaModule': '.nm-module-panorama',
	'sSelectorPanoramaWrapper': '.nm-j-panorama',
	'sSelectorPanoramaPoster': '.nm-md-panorama-static',
	'sSelectorPanoramaActivate': '.nm-j-panorama-activate',
	'sSelectorPanoramaCollapse': '.nm-j-panorama-close-fullscreen'
};

__.threejsURL = window.webpack_public_path + 'bundle.panorama.js';

// flags
__.threejsLoaded = false;
__.panoramaIsFullscreen = false;
__.userInteraction = false;
__.debugEnabled = false;
__.gyroAvailable = window.DeviceOrientationEvent !== undefined;
__.iOS = DEVICE.isiOS();
__.mobileDevice = DEVICE.isMobile();

// set scrolling range for coordinates
__.topRange = 28; // set latitude range to hide ceiling camera hole
__.bottomRange = -85; // set latitude range to prevent swipe outside context and reverted axis

// animationID for AnimationFrame Listener Start/Stop
__.animationID = null;

// save FullScreen ElementID for ESC Action
__.fullScreenElementID = null;

// Initial Coordinates for User Interaction
__.onMouseDownMouseX = 0;
__.onMouseDownMouseY = 0;
__.logitude = 0;
__.onMouseDownLon = 0;
__.latitude = 0;
__.onMouseDownLat = 0;
__.alpha = 0;
__.beta = 0;
__.gamma = 0;

/**
 * set threejs ready flag and triggers user interaction enabling
 * @param {Module} library_ - the library loaded by webpack chunk mechanism
 * @returns {void}
 */
__.threeReady = function(library_) {
	__.debug('threejs_loaded!');
	__.THREE = library_.default;
	__.threejsLoaded = true;
	__.addEvents();
	__.enableInteraction();
	__.addDeviceOrientationControls();
};

/**
 * enableInteraction
 * makes clickable areas visible
 * @returns {void}
 */
__.enableInteraction = function() {
	var clickAreas = DOM_UTILS.getElementsArray(__.oDefaults.sSelectorPanoramaActivate);

	clickAreas.forEach(function(clickArea) {
		clickArea.setAttribute('data-state', 'enabled');
		__.preloadTexture(clickArea);
	});
};

/**
 * preloadTexture
 * preload panorama texture file
 * @param {HTMLElement} triggerSibling_ - HTML Element for DOM_UTILS Closest Search
 * @returns {void}
 */
__.preloadTexture = function(triggerSibling_) {
	var img = new Image(),
		canvasWrapper = DOM_UTILS.closest(triggerSibling_, __.oDefaults.sSelectorPanoramaWrapper),
		texture = DOM_UTILS.getDataAttribute(canvasWrapper, 'texture-url');

	texture = (DEVICE.isMobile()) ? texture + '?width=4000' : texture;
	img.setAttribute('loading', 'lazy');
	img.dataset.src = texture;
};

/**
 * initialise the panorama for the given click context
 * @returns {void}
 */
__.activatePanoramaClickHandler = function() {
	var that = this,
		module = DOM_UTILS.closest(that, __.oDefaults.sSelectorPanoramaModule),
		panoramaWrapper;
	if (DOM_UTILS.isElement(module)) {
		__.initPanoramaViewer(module);
		__.activateUserControls(module);
		__.animatePanorama();
		if (!__.isFullScreen()) {
			panoramaWrapper = DOM_UTILS.getElement(__.oDefaults.sSelectorPanoramaWrapper, module);
			__.switchToFullScreen(panoramaWrapper);
		}
	}
};

/**
 * collapsePanoramaClickHandler
 * triggers actions viewer and fullscreen
 * @returns {void}
 */
__.collapsePanoramaClickHandler = function() {
	var that = this,
		module = DOM_UTILS.closest(that, __.oDefaults.sSelectorPanoramaModule);
	if (DOM_UTILS.isElement(module)) {
		__.deactivateUserControls(module);
		__.closePanoramaViewer(module);
		__.exitFullScreen();
	}
};

/**
 * collapsePanoramaHandler
 * triggers actions viewer after ESC closing fullscreen
 * @param {HTMLElement} element_ - HTML Context for DOM_UTILS
 * @returns {void}
 */
__.collapsePanoramaHandler = function(element_) {
	var module = DOM_UTILS.closest(element_, __.oDefaults.sSelectorPanoramaModule);
	if (DOM_UTILS.isElement(module)) {
		__.deactivateUserControls(module);
		__.closePanoramaViewer(module);
	}
};

/**
 * activateUserControls
 * calls to register events set callbacks for needed mouse / touch / deviceorientation / resize events
 * @param {HTMLElement} context_ - HTML Context for DOM_UTILS
 * @returns {void}
 */
__.activateUserControls = function(context_) {
	var panorama = DOM_UTILS.getElement('canvas', context_);
	__.addWindowResizeEvent();
	__.addMouseInteractionEvents(panorama);
	__.addTouchAndGesturesInteractionEvents(panorama);
	__.addDeviceOrientationEvent();
};

/**
 * addWindowResizeEvent
 * register event for canvas update/redraw after window resize
 * @param {HTMLElement} canvas_ - DOM Element to register events
 * @returns {void}
 */
__.addWindowResizeEvent = function() {
	__.throttleResizeHandler = DOM_UTILS.throttle(__.panoramaResizeHandler, 250);
	window.addEventListener('resize', __.throttleResizeHandler);
};

/**
 * addMouseInteractionEvents
 * register events set callbacks for needed mouse events
 * @param {HTMLElement} canvas_ - DOM Element to register events
 * @returns {void}
 */
__.addMouseInteractionEvents = function(canvas_) {
	canvas_.addEventListener('mousedown', __.handlePanoramaMouseDown, false);
	__.throttlePanoramaMouseMove = DOM_UTILS.throttle(__.handlePanoramaMouseMove, 40);
	canvas_.addEventListener('mousemove', __.throttlePanoramaMouseMove, false);
	canvas_.addEventListener('mouseup', __.handlePanoramaMouseUp, false);
	__.throttlePanoramaWheelAndMultitouchHandler = DOM_UTILS.throttle(__.handlePanoramaWheelAndMultitouch, 500);
	canvas_.addEventListener('wheel', __.throttlePanoramaWheelAndMultitouchHandler, false);
	canvas_.addEventListener('context', __.handlePanoramaWheelAndMultitouch, false);
};

/**
 * addTouchAndGesturesInteractionEvents
 * register events set callbacks for needed touch and gestures events
 * @param {HTMLElement} canvas_ - DOM Element to register events
 * @returns {void}
 */
__.addTouchAndGesturesInteractionEvents = function(canvas_) {
	canvas_.addEventListener('gesturestart', __.handlePanoramaWheelAndMultitouch, false);
	canvas_.addEventListener('gesturechange', __.throttlePanoramaWheelAndMultitouchHandler, false);
	canvas_.addEventListener('gestureend', __.handlePanoramaWheelAndMultitouch, false);
	canvas_.addEventListener('touchstart', __.handlePanoramaTouchStart, false);
	__.throttlePanoramaTouchMoveHandler = DOM_UTILS.throttle(__.handlePanoramaTouchMove, 20);
	canvas_.addEventListener('touchmove', __.throttlePanoramaTouchMoveHandler, false);
	canvas_.addEventListener('touchend', __.handlePanoramaMouseUp, false);
};

/**
 * addDeviceOrientationEvents
 * register events set callbacks for needed device orientation events
 * @returns {void}
 */
__.addDeviceOrientationEvent = function () {
	if (__.iOS) {
		if (window.DeviceMotionEvent !== null) {
			__.throttleIOSMotionHandler = DOM_UTILS.throttle(__.handleIOSMotion, 20);
			window.addEventListener('devicemotion', __.throttleIOSMotionHandler);
		}
	}
	else {
		window.addEventListener('deviceorientation', __.setAndroidOrientationControls, true);
	}
};

/**
 * deactivateUserControls
 * unregister events
 * @param {HTMLElement} context_ - HTML Context for DOM_UTILS
 * @returns {void}
 */
__.deactivateUserControls = function(context_) {
	var panorama = DOM_UTILS.getElement('canvas', context_);
	window.removeEventListener('resize', __.throttleResizeHandler);
	panorama.removeEventListener('mousedown', __.handlePanoramaMouseDown, false);
	panorama.removeEventListener('mousemove', __.throttlePanoramaMouseMove, false);
	panorama.removeEventListener('mouseup', __.handlePanoramaMouseUp, false);
	panorama.removeEventListener('wheel', __.throttlePanoramaWheelAndMultitouchHandler, false);
	panorama.removeEventListener('context', __.handlePanoramaWheelAndMultitouch, false);
	panorama.removeEventListener('gesturestart', __.handlePanoramaWheelAndMultitouch, false);
	panorama.removeEventListener('gesturechange', __.throttlePanoramaWheelAndMultitouchHandler, false);
	panorama.removeEventListener('gestureend', __.handlePanoramaWheelAndMultitouch, false);
	panorama.removeEventListener('touchstart', __.handlePanoramaTouchStart, false);
	panorama.removeEventListener('touchmove', __.throttlePanoramaTouchMoveHandler, false);
	panorama.removeEventListener('touchend', __.handlePanoramaMouseUp, false);

	if (__.iOS && window.DeviceMotionEvent !== null) {
		window.removeEventListener('devicemotion', __.throttleIOSMotionHandler);
	}
};

/**
 * handleIOSMotion
 * changes Scene View Coordinates on Device Rotation
 * @param {event} event_ - DeviceOrientationEvent Object
 * @returns {void}
 */
__.handleIOSMotion = function (event_) {
	var portrait = 0,
		portraitUpsideDown = 180,
		landscapeLeft = -90,
		landscapeRight = 90,
		adjustmentFactor = 0.02,
		alphaRotation = Math.round(event_.rotationRate.alpha),
		betaRotation = Math.round(event_.rotationRate.beta);

	switch (window.orientation) {
		case portrait:
			__.latitude += alphaRotation * adjustmentFactor;
			__.longitude -= betaRotation * adjustmentFactor;
			break;
		case landscapeLeft:
			__.latitude += betaRotation * adjustmentFactor;
			__.longitude += alphaRotation * adjustmentFactor;
			break;
		case portraitUpsideDown:
			__.latitude -= alphaRotation * adjustmentFactor;
			__.longitude += betaRotation * adjustmentFactor;
			break;
		case landscapeRight:
			__.latitude -= betaRotation * adjustmentFactor;
			__.longitude -= alphaRotation * adjustmentFactor;
			break;
		default:
			break;
	}
};

/**
 * Without an extra if to check deviceorientation support initializes the
 * DeviceOrientationControls on first occurance of the event.
 * @param {DeviceOrientationEvent} event_ - the "deviceorientation" event
 * @returns {void}
 */
__.setAndroidOrientationControls = function (event_) {
	if (!event_.alpha) {
		return;
	}

	// adjust camera and sphere to fit the coordinate system of the DeviceOrientationControls
	__.camera.lookAt(new __.THREE.Vector3(-1, 0, 0));
	__.sphereMesh.rotateY(__.THREE.MathUtils.degToRad(180));

	__.androidOrientationControls = new __.THREE.DeviceOrientationControls(__.camera, true);
	__.androidOrientationControls.connect();
	__.androidOrientationControls.update();

	window.removeEventListener('deviceorientation', __.setAndroidOrientationControls, true);
};

/**
 * handlePanoramaMouseDown
 * initialize local Coordinates for Scene Rotation
 * @param {event} event_ - Mouse Event Object
 * @returns {void}
 */
__.handlePanoramaMouseDown = function(event_) {
	event_.preventDefault();
	__.userInteraction = true;
	__.onMouseDownMouseX = event_.clientX;
	__.onMouseDownMouseY = event_.clientY;
	__.onMouseDownLon = __.longitude;
	__.onMouseDownLat = __.latitude;
};

/**
 * handlePanoramaMouseMove
 * changes Scene View Coordinates on Mouse Motion
 * @param {event} event_ - Mouse Event Object
 * @returns {void}
 */
__.handlePanoramaMouseMove = function (event_) {
	var dragSpeed = 0.1;

	event_.preventDefault();

	if (__.userInteraction) {
		__.latitude = (event_.clientY - __.onMouseDownMouseY) * dragSpeed + __.onMouseDownLat;
		__.longitude = (__.onMouseDownMouseX - event_.clientX) * dragSpeed + __.onMouseDownLon;
	}
};

/**
 * handlePanoramaMouseUp
 * set Ended User Interaction Flag
 * @param {event} event_ - Mouse Event Object
 * @returns {void}
 */
__.handlePanoramaMouseUp = function(event_) {
	__.userInteraction = false;
	event_.preventDefault();
};

/**
 * handlePanoramaTouchStart
 * initialize local Coordinates for Scene Rotation
 * @param {event} event_ - Touch Event Object
 * @returns {void}
 */
__.handlePanoramaTouchStart = function(event_) {
	var touch = event_.changedTouches[0];
	event_.preventDefault();
	if (event_.touches.length === 1) {
		__.userInteraction = true;
		__.onMouseDownMouseX = touch.clientX;
		__.onMouseDownMouseY = touch.clientY;
		__.onMouseDownLon = __.longitude;
		__.onMouseDownLat = __.latitude;
	}
};

/**
 * handlePanoramaTouchMove
 * changes Scene View Coordinates on Touch Motion
 * @param {event} event_ - Touch Event Object
 * @returns {void}
 */
__.handlePanoramaTouchMove = function (event_) {
	var touch = event_.changedTouches[0],
		dragSpeed = 0.2;

	event_.preventDefault();

	if (event_.touches.length === 1) {
		if (__.userInteraction) {
			__.latitude = (touch.clientY - __.onMouseDownMouseY) * dragSpeed + __.onMouseDownLat;
			__.longitude = (__.onMouseDownMouseX - touch.clientX) * dragSpeed + __.onMouseDownLon;
		}
	}
};

/**
 * handlePanoramaWheelAndMultitouch
 * Deactivates ContextMenu / Wheel Actions / Multitouch Gestures (pinch/ zoom)
 * @param {event} event_ - Event Object
 * @returns {void}
 */
__.handlePanoramaWheelAndMultitouch = function(event_) {
	event_.preventDefault();
};

/**
 * closePanoramaViewer
 * triggers actions for canvas redraw
 * @param {HTMLElement} context_ - HTML Context for DOM_UTILS
 * @returns {void}
 */
__.closePanoramaViewer = function(context_) {
	var canvasWrapper = DOM_UTILS.getElement(__.oDefaults.sSelectorPanoramaWrapper, context_);
	__.debug('collapsePano - close');
	canvasWrapper.setAttribute('data-state', null);
	canvasWrapper.removeChild(__.renderer.domElement);

	if (__.androidOrientationControls) {
		__.androidOrientationControls.dispose();
	}

	__.remove3DEntity();
};

/**
 * panoramaResizeHandler
 * triggers actions for canvas redraw after viewport change
 * @returns {void}
 */
__.panoramaResizeHandler = function () {
	// note: in Chrome on iOS and on window resize, window.innerWidth has a wrong value
	let width = document.documentElement.clientWidth;
	let height = document.documentElement.clientHeight;

	if (__.panoramaIsFullscreen) {
		__.camera.aspect = width / height;
		__.camera.updateProjectionMatrix();
		__.renderer.setSize(width, height);
	}
};

/**
 * isFullScreen
 * Checks if Fullscreen is active or not
 * @returns {(boolean|HTMLElement)} Returns either if an Element is Fullscreen or in case which Element is Fullscreen
 */
__.isFullScreen = function() {
	return !!(document.fullscreenElement || document.fullscreen || document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement);
};

/**
 * enterFullScreen
 * Send an asynch request to show element in FullScreen
 * @param {HTMLElement} element_ - element to send the request for
 * @returns {void}
 */
__.enterFullScreen = function(element_) {
	if (!element_.requestFullscreen) {
		element_.requestFullscreen = element_.mozRequestFullScreen || element_.webkitRequestFullscreen || element_.msRequestFullscreen;
	}
	element_.requestFullscreen();
};

/**
 * isFullScreenClosed
 * Check whether the fullscreen state ist active or not and calls the exitFullscreen in case
 * @returns {void}
 */
__.isFullScreenClosed = function() {
	var fs = __.isFullScreen();
	if (!fs) {
		if (!!__.fullScreenElement) {
			__.collapsePanoramaHandler(__.fullScreenElement);
		}
		__.exitFullScreen();
	}
};

/**
 * exitFullScreen
 * document call to exit fullScreen call for unregister events
 * @returns {void}
 */
__.exitFullScreen = function() {
	if (!document.exitFullScreen) {
		document.exitFullScreen = document.mozCancelFullScreen || document.webkitExitFullscreen || document.msExitFullscreen;
	}
	document.exitFullScreen();
	__.handleFullscreenEvents(false);
};

/**
 * switchToFullScreen
 * pass on element for fullscreen request and call for register events
 * @param {HTMLElement} element_ - DOM Element for the fullScreen Request
 * @returns {void}
 */
__.switchToFullScreen = function(element_) {
	__.handleFullscreenEvents(true);
	__.fullScreenElement = element_;
	__.enterFullScreen(element_);
};

/**
 * handleFullscreenEvents
 * register or unregister fullscreenChangeEvents
 * @param {boolean} fullscreen_ - Flag
 * @returns {void}
 */
__.handleFullscreenEvents = function(fullscreen_) {
	if (fullscreen_) {
		document.addEventListener('webkitfullscreenchange', __.isFullScreenClosed, false);
		document.addEventListener('mozfullscreenchange', __.isFullScreenClosed, false);
		document.addEventListener('fullscreenchange', __.isFullScreenClosed, false);
		document.addEventListener('MSFullscreenChange', __.isFullScreenClosed, false);
	}
	else {
		document.removeEventListener('webkitfullscreenchange', __.isFullScreenClosed, false);
		document.removeEventListener('mozfullscreenchange', __.isFullScreenClosed, false);
		document.removeEventListener('fullscreenchange', __.isFullScreenClosed, false);
		document.removeEventListener('MSFullscreenChange', __.isFullScreenClosed, false);
	}
};

/**
 * initPanoramaViewer
 * initialize the given canvas with Three
 * @param {HTMLElement} context_ - module to setup the renderer into
 * @returns {void}
 */
__.initPanoramaViewer = function(context_) {
	var canvasWrapper = DOM_UTILS.getElement(__.oDefaults.sSelectorPanoramaWrapper, context_),
		texture = DOM_UTILS.getDataAttribute(canvasWrapper, 'texture-url'),
		initialWidth = window.innerWidth,
		initialHeight = window.innerHeight;
	texture = (__.mobileDevice) ? texture + '?width=4000' : texture;
	__.debug('initPanoView');
	__.longitude = 0;
	__.latitude = 0;
	__.setUpRenderer(initialWidth, initialHeight);
	__.updateDOMWithRenderer(canvasWrapper);
	__.createNewScene();
	__.createNewCamera(initialWidth / initialHeight);
	__.createMeshAndAddPanoramaToScene(__.createNewSphere(), __.createSphereMaterial(texture));
	__.animatePanorama();
};

/**
 * setUpRenderer
 * set up the WebGL renderer
 * @param {Number} initialWidth_ - init width for the canvas
 * @param {Number} initialHeight_ - init height for the canvas
 * @returns {void}
 */
__.setUpRenderer = function(initialWidth_, initialHeight_) {
	__.renderer = new __.THREE.WebGLRenderer();
	__.renderer.setSize(initialWidth_, initialHeight_);
};

/**
 * updateDOMForRenderer
 * appends the panorama viewer to the page and set the module internal fullScreen Flag
 * @param {HTMLElement} canvasWrapper_ - DOM Parent of the canvas
 * @returns {void}
 */
__.updateDOMWithRenderer = function(canvasWrapper_) {
	canvasWrapper_.setAttribute('data-state', 'active');
	canvasWrapper_.appendChild(__.renderer.domElement);
	__.panoramaIsFullscreen = true;
};

/**
 * createNewScene
 * callback for THREE Scene constructor - creates a new 3D Scene
 * @returns {void}
 */
__.createNewScene = function() {
	__.scene = new __.THREE.Scene();
};

/**
 * createNewCamera
 * callbacks for THREE Camera - creates a new PerspectiveCamera to render
 * views of the scene
 * @param {Number} ratio_ - init aspect ratio for the camera
 * @returns {void}
 */
__.createNewCamera = function(ratio_) {
	__.camera = new __.THREE.PerspectiveCamera(80, ratio_, 1, 1000);
	__.camera.target = new __.THREE.Vector3(0, 0, 0);
};

/**
 * createNewSphere
 * callbacks for THREE - creates the 3D Object to adapt the equirectangular
 * bild to
 * @returns {void}
 */
__.createNewSphere = function() {
	// Params: Radius / Horizontal Polygons / Vertical Polygons / Starting Horizontal Degree (wich portion of the Sphere should be shown on init)
	var sphere = new __.THREE.SphereGeometry(100, 100, 60, 160);
	sphere.applyMatrix4(new __.THREE.Matrix4().makeScale(-1, 1, 1));
	return sphere;
};

/**
 * createSphereMaterial
 * callbacks for THREE Material and mapping Image - the material is needed
 * to wrap the Poligons of the the 3D Object
 * @param {string} texture_ - the URL for the equirectangular Picture
 * @returns {Object} -
 */
__.createSphereMaterial = function(texture_) {
	var texture = new __.THREE.TextureLoader().load(texture_);
	var sphereMaterial = new __.THREE.MeshBasicMaterial({ map:texture });
	return sphereMaterial;
};

/**
 * createMeshAndAddPanoramaToScene
 * a Mesh (texture on geometry) is added to the 3D scene
 * @param {Object} sphere_ - 3D Object for the Scene
 * @param {Object} sphereMaterial_ - Texture
 * @returns {Object} -
 */
__.createMeshAndAddPanoramaToScene = function (sphere_, sphereMaterial_) {
	__.sphereMesh = new __.THREE.Mesh(sphere_, sphereMaterial_);
	__.scene.add(__.sphereMesh);
};

/**
 * remove3DEntity
 * Check whether the fullscreen state ist active or not and calls the exitFullscreen in case
 * @returns {void}
 */
__.remove3DEntity = function() {
	cancelAnimationFrame(__.animationID);
	__.panoramaIsFullscreen = false;
	__.scene = null;
	__.camera = null;
	__.renderer = null;
};

/**
 * animatePanorama
 * triggers the canvas update
 * @returns {void}
 */
__.animatePanorama = function() {
	if (!__.panoramaIsFullscreen) {
		return;
	}
	__.animationID = requestAnimationFrame(__.animatePanorama);
	__.updatePanoramaStage();
};

/**
 * updatePanoramaStage
 * updates the view withon canvas
 * @returns {void}
 */
__.updatePanoramaStage = function () {
	if (!__.androidOrientationControls) {
		__.latitude = Math.max(__.bottomRange, Math.min(__.topRange, __.latitude));

		__.camera.target.x = 500 * Math.sin(__.THREE.MathUtils.degToRad(90 - __.latitude)) * Math.cos(__.THREE.MathUtils.degToRad(__.longitude));
		__.camera.target.y = 500 * Math.cos(__.THREE.MathUtils.degToRad(90 - __.latitude));
		__.camera.target.z = 500 * Math.sin(__.THREE.MathUtils.degToRad(90 - __.latitude)) * Math.sin(__.THREE.MathUtils.degToRad(__.longitude));

		__.camera.lookAt(__.camera.target);
	}
	else {
		// enable user drag only to the left and right to avoid ending up upside down.
		// i.e. when the device points down, the user should see the seat, not the ceiling.
		__.androidOrientationControls.alphaOffset = __.THREE.MathUtils.degToRad(__.longitude * -1);
		__.androidOrientationControls.update();
	}

	__.renderer.render(__.scene, __.camera);
};

/**
 * debug
 * custom debug function
 * @param {string} msg_ - the debug msg
 * @returns {void}
 */
__.debug = function(msg_) {
	if (__.debugEnabled) {
		console.log('module_panorama: ' + msg_);
	}
};

/**
 * addEvents
 * Adds events for User Interactions
 * @returns {void} returns nothing
 */
__.addEvents = function() {
	__.domEventDelegate.on('click', __.oDefaults.sSelectorPanoramaActivate, __.activatePanoramaClickHandler);
	__.domEventDelegate.on('click', __.oDefaults.sSelectorPanoramaCollapse, __.collapsePanoramaClickHandler);
};

/**
 * get context of loaded DOM
 * @param {Object|HtmlElement} data_ - the event's context payload
 * @returns {HTMLElement} - DOM Fragment of the context
 */
__.getContext = function(data_) {
	var contextElement;
	if (!!data_ && !data_.context) {
		contextElement = data_.domElement || data_.element;
	}
	else {
		contextElement = document;
	}
	return contextElement;
};

/**
 * isPanoramaModuleInContext
 * trigger initializeModules on proper context
 * @param {Object} data_ - payload to be analyzed
 * @returns {boolean} - true if the panorama module is in DOM
 */
__.isPanoramaModuleInContext = function(data_) {
	var context = __.getContext(data_),
		modules = DOM_UTILS.getElementsArray(__.oDefaults.sSelectorPanoramaModule, context);
	return modules.length > 0;
};

/**
 * loadThreeLibrary
 * checks if the Panorama Module is present and only in case loads the ThreeJS Bundle
 * @param {Object|HtmlElement} data_ - the event's context payload
 * @returns {void}
 */
__.loadThreeLibrary = function(data_) {
	if (__.isPanoramaModuleInContext(data_)) {
		import(/* webpackChunkName: "panorama-three" */ './panorama-three')
			.then(__.threeReady)
			.catch(error => {
				console.error(error);
			});
	}
};

/**
 * init
 * trigger initializeModules on proper context
 * @returns {void}
 */
__.initialize = function() {
	__.eventBus.on(EVENTS.PAGE_LOADED, __.loadThreeLibrary);
	__.eventBus.on(EVENTS.LAYER_LOADED, __.loadThreeLibrary);
	__.loadThreeLibrary();
};

/**
 * Adds a three.js controller for device orientation. The implementation is based
 * on a three.js example that got linted, hinted and that works with the __.THREE namespace.
 * @see https://github.com/mrdoob/three.js/blob/master/examples/js/controls/DeviceOrientationControls.js
 * @returns {void}
 */
__.addDeviceOrientationControls = function () {
	__.THREE.DeviceOrientationControls = function (object) {
		var scope = this;

		var onDeviceOrientationChangeEvent = function (event) {
			scope.deviceOrientation = event;
		};

		var onScreenOrientationChangeEvent = function () {
			scope.screenOrientation = window.orientation || 0;
		};

		// The angles alpha, beta and gamma form a set of intrinsic Tait-Bryan angles of type Z-X'-Y''

		var setObjectQuaternion = (function () {
			var zee = new __.THREE.Vector3(0, 0, 1);

			var euler = new __.THREE.Euler();

			var q0 = new __.THREE.Quaternion();

			var q1 = new __.THREE.Quaternion(-Math.sqrt(0.5), 0, 0, Math.sqrt(0.5)); // - PI/2 around the x-axis

			// eslint-disable-next-line max-params
			return function (quaternion, alpha, beta, gamma, orient) {

				euler.set(beta, alpha, -gamma, 'YXZ'); // 'ZXY' for the device, but 'YXZ' for us

				quaternion.setFromEuler(euler); // orient the device

				quaternion.multiply(q1); // camera looks out the back of the device, not the top

				quaternion.multiply(q0.setFromAxisAngle(zee, -orient)); // adjust for screen orientation
			};
		}());

		this.object = object;
		this.object.rotation.reorder('YXZ');

		this.enabled = true;

		this.deviceOrientation = {};
		this.screenOrientation = 0;

		this.alphaOffset = 0; // radians

		this.connect = function () {
			onScreenOrientationChangeEvent(); // run once on load

			window.addEventListener('orientationchange', onScreenOrientationChangeEvent, false);
			window.addEventListener('deviceorientation', onDeviceOrientationChangeEvent, false);

			scope.enabled = true;
		};

		this.disconnect = function () {
			window.removeEventListener('orientationchange', onScreenOrientationChangeEvent, false);
			window.removeEventListener('deviceorientation', onDeviceOrientationChangeEvent, false);

			scope.enabled = false;
		};

		this.update = function () {
			var alpha, beta, gamma, orient;
			var device = scope.deviceOrientation;

			if (scope.enabled === false) {
				return;
			}

			if (device) {
				alpha = device.alpha ? __.THREE.MathUtils.degToRad(device.alpha) + scope.alphaOffset : 0; // Z

				beta = device.beta ? __.THREE.MathUtils.degToRad(device.beta) : 0; // X'

				gamma = device.gamma ? __.THREE.MathUtils.degToRad(device.gamma) : 0; // Y''

				orient = scope.screenOrientation ? __.THREE.MathUtils.degToRad(scope.screenOrientation) : 0; // O

				setObjectQuaternion(scope.object.quaternion, alpha, beta, gamma, orient);
			}
		};

		this.dispose = function () {
			scope.disconnect();
		};

		this.connect();
	};
};

/**
 * Check on DOM-Load for Panorama inst
 * ances and then first initialize
 * @param {Emitter} eventBus_ - the global EventEmitter
 * @returns {Promise} - Module wrapped in Promise
 */
exports.initialize = function(eventBus_) {
	__.domEventDelegate = DOM_UTILS.getEventDelegate('body');
	__.eventBus = eventBus_;
	__.initialize();
};

export {exports as panorama};
