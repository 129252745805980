import {appEvents} from 'core-application';
import {patternMatching} from 'core-global';

var __ = {},
	exports = {
		'__': __
	};

/**
 * default Values used several times
 *
 * @member {Object} oDefaults
 * @example
 * //To call inner values:
 * __.oDefaults = { 'className' : '.my-name' };
 * console.log(__.oDefaults.className);
 */
__.oDefaults = {
	'module': '.nm-j-iframe'
};

// reference to the global eventEmitter
__.eventBus = null;

/**
 * add listeners to events of interest for this modeule
 * @returns {void}
 */
__.addEvents = function () {
	__.eventBus.on(appEvents.PAGE_LOADED, __.setIframeUrl);
	__.eventBus.on(appEvents.LAYER_LOADED, __.setIframeUrl);
	// AudiCode could be ready after the page was loaded,
	// for example if the audicode was loaded via userconfig or parameter
	// so we update the pattern if the config changed.
	__.eventBus.on(appEvents.CONFIG_UPDATE, __.setIframeUrl);
};

/**
 * get iframe container
 * @param { HTMLElement} context_ module context
 * @returns {HTMLElement|boolean} - TODO
 */
__.getIframeContainer = function (context_) {
	if (context_) {
		return [].slice.call(context_.querySelectorAll(__.oDefaults.module));
	}
	return false;
};

/**
 * get context
 * @param {Event} data_ event payload
 * @returns {HTMLElement} - TODO
 */
__.getContext = function (data_) {
	var contextElement;

	if (data_ && !data_.context) {
		contextElement = data_.domElement || data_.element;
	}
	else {
		contextElement = document;
	}

	return contextElement;
};

/**
 * set iframe url
 * @param {Event} data - event payload
 * @returns {void}
 */
__.setIframeUrl = function (data) {
	var context = __.getContext(data),
		iFrames;

	iFrames = __.getIframeContainer(context);

	if (iFrames && iFrames.length > 0) {

		iFrames.forEach(function (iFrame_) {
			var patterUrl, defaultUrl, iframeHeight;

			patterUrl = iFrame_.getAttribute('data-pattern') || '';
			defaultUrl = iFrame_.getAttribute('data-src') || '';
			iframeHeight = iFrame_.getAttribute('data-height') || '0';

			let scriptElements = iFrame_.getElementsByTagName('script');

			if (!!patterUrl && !!defaultUrl) {
				patternMatching
					.getReplacedUrl(patterUrl, defaultUrl)
					.then(function (url) {
						const newFrame = document.createElement('iframe');
						newFrame.setAttribute('src', url);
						newFrame.setAttribute('height', iframeHeight);
						newFrame.setAttribute('frameborder', '0');
						if (scriptElements.length > 0) {
							iFrame_.insertBefore(newFrame, scriptElements[0]);
						}
						else {
							iFrame_.innerHTML = '<iframe src="' + url + '" height="' + iframeHeight + '" frameborder="0" ></iframe>';
						}
					})
					.catch(function (err) {
						console.error(err);
					});
			}
		});
	}
};

exports.initialize = function (eventEmitter) {
	__.eventBus = eventEmitter;
	__.addEvents();
	setTimeout(__.setIframeUrl, 250);
};

export {exports as iframe};
