
const NEMO_BUILD_FOLDER = `/cms4i-nemo/build/`;
let dynamicPathPart = '';

if (!!SETUPS.get('nemo.url.static') && !!SETUPS.get('nemo.staticversion')) {
	dynamicPathPart = `${location.origin}${SETUPS.get('nemo.url.static')}.${SETUPS.get('nemo.staticversion')}`;
}

// eslint-disable-next-line no-undef
__webpack_public_path__ = dynamicPathPart + NEMO_BUILD_FOLDER;
