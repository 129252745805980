import {appEvents as EVENTS} from 'core-application';
import {dom as DOM_UTILS} from 'core-utils';
import {device as DEVICE} from 'core-utils';
import {swipeStage as SWIPE} from './swipe-stage';

const __ = {};

__.delayIntervallId = '';
__.aAllAutoplayVideos = [];
__.aPlayedVideos = [];
const exports = {
	__: __
};
/* eslint consistent-this: [0, 'that'] */
__.getCurrentVideoContainer = function(elm) {
	var containers = DOM_UTILS.getElementsArray('.nm-video'),
		i, conLen;

	if (!!containers && !!elm && elm.nodeType) {
		conLen = containers.length;
		for (i = 0; i < conLen; i++) {
			if (containers[i].contains(elm)) {
				return containers[i];
			}
		}
	}
	return null;
};

__.getVideo = function(videoContainer) {
	return videoContainer ? videoContainer.querySelector('video') : null;
};

/**
 * find closest layerhead
 *@param {HTMLElement} videoContainer - container with video
 * @returns {HTMLElement|null} layerhead container
 */
__.getLayerHead = function(videoContainer) {
	return __.findClosest(videoContainer, '.nm-layer-head');
};

__.setDataVideoState = function(statusName, layerHead) {
	if (layerHead) {
		layerHead.setAttribute('data-video-state', statusName);
	}
};

__.handlerPlayButton = function() {
	var btn = this,
		container = __.getCurrentVideoContainer(btn),
		video = __.getVideo(container),
		layerhead = __.getLayerHead(container);
	if (video) {
		video.addEventListener('playing', __.handlerVideoPlaying);
		video.play();
	}
	if (layerhead) {
		__.setDataVideoState('playing', layerhead);
	}
};

__.handlerPauseButton = function() {
	var btn = this,
		container = __.getCurrentVideoContainer(btn),
		video, layerhead;
	if (container) {
		video = __.getVideo(container);
		layerhead = __.getLayerHead(container);
	}
	if (video) {
		video.addEventListener('pause', __.handlerVideoPause);
		video.pause();
		if (layerhead) {
			__.setDataVideoState('paused', layerhead);
		}
	}
};

__.handlerVideoPause = function() {
	var video = this,
		container = __.getCurrentVideoContainer(video);
	if (video) {
		video.removeEventListener('pause', __.handlerVideoPause);
		video.removeEventListener('timeupdate', __.updateTimeInfo);
	}
	__.removeClassName(container, 'nm-video-playing');
};

__.handlerVideoPlaying = function() {
	var video = this,
		container = __.getCurrentVideoContainer(video);
	video.removeEventListener('playing', __.handlerVideoPlaying);
	video.addEventListener('timeupdate', __.updateTimeInfo);
	video.addEventListener('ended', __.handlerVideoEnded);
	video.addEventListener('progress', __.handleVideoProgress);
	__.addClassName(container, 'nm-video-playing');
};

__.handleVideoProgress = function() {
	var video = this,
		container = __.getCurrentVideoContainer(video),
		bufferBar, loadedTime, loadedPercentage;
	if (container && video) {
		bufferBar = container.querySelector('.nm-loader-loaded');
		loadedTime = video.buffered.end(0);
		loadedPercentage = parseFloat(loadedTime / video.duration) * 100;
		if (bufferBar) {
			bufferBar.style.width = loadedPercentage + '%';
		}
	}
};

__.handlerVideoEnded = function() {
	let video = this,
		container = __.getCurrentVideoContainer(video),
		fullScreenActive = !!document.querySelector('.active-fs'),
		videoSrc,
		layerhead = __.getLayerHead(container);
	__.handleVideoEndEvents(video);
	__.removeClassName(container, 'nm-video-playing');
	__.displayTimeInfos(container, 0, video.duration);
	/* show poster image of video again */
	videoSrc = video.currentSrc;
	video.src = videoSrc;
	if (fullScreenActive) {
		__.leaveFullScreen(container.querySelector('.nm-minimize'));
	}
	if (video){
		let nativeCtrl = video.getAttribute('data-nativectrls');
		if (nativeCtrl === 'false') {
			if (container){
				container.classList.remove('nm-hide-controls');
			}
		}
	}
	__.setDataVideoState('ended', layerhead);
};

__.handleVideoEndEvents = function(video) {
	if (video) {
		video.removeEventListener('ended', __.handlerVideoEnded);
		video.removeEventListener('timeupdate', __.updateTimeInfo);
		video.removeEventListener('progress', __.handleVideoProgress);
	}
};

__.updateTimeInfo = function() {
	var video = this,
		container = __.getCurrentVideoContainer(video);
	if (container) {
		__.displayTimeInfos(container, video.currentTime, video.duration);
	}
};

__.displayTimeInfos = function(container, currentTime, duration) {
	var timePlayed, timeDuration, progressBar, playedPercentage, progressBarLoaded, allValuesZero = (currentTime === 0 && duration === 0);
	if (container) {
		if (!container.querySelector('video[data-autoplay-in-viewport]')) {
			timePlayed = container.querySelector('.nm-time-played');
			timeDuration = container.querySelector('.nm-time-duration');
			timePlayed.innerHTML = __.getTime(currentTime);
			timeDuration.innerHTML = __.getTime(duration);
		}
		progressBar = container.querySelector('.nm-loader-played');
		progressBarLoaded = container.querySelector('.nm-loader-loaded');
		playedPercentage = allValuesZero ? 0 : parseFloat(currentTime / duration) * 100;
		if (progressBar && progressBarLoaded) {
			if (allValuesZero){
				progressBarLoaded.style.width ='0%';
			}
			progressBar.style.width = playedPercentage + '%';
		}
	}
};

__.handlerMuteButton = function() {
	var btn = this,
		container = __.getCurrentVideoContainer(btn),
		video = __.getVideo(container);
	if (!!video) {
		if (video.muted === false) {
			// Mute the video
			video.muted = true;
			__.removeClassName(this, 'nm-mute');
			__.addClassName(this, 'nm-unmute');
		}
		else {
			// Unmute the video
			video.muted = false;
			__.removeClassName(this, 'nm-unmute');
			__.addClassName(this, 'nm-mute');
		}
	}
};

__.handlerSeekBar = function(event) {
	var btn = this,
		container = __.getCurrentVideoContainer(btn),
		video = __.getVideo(container),
		width = btn.clientWidth,
		offX = event.offsetX,
		percent = (offX / width) * 100;
	if (!!video && !!container) {
		video.currentTime = video.duration * (percent / 100);
		__.displayTimeInfos(container, video.currentTime, video.duration);
	}
};

__.showHideControls = function() {
	var controlWrap,
		container = document.querySelector('.active-fs');
	if (!!container) {
		controlWrap = container.querySelector('.nm-controls-wrap-video');
		__.addClassName(controlWrap, 'nm-controls-wrap-video-full');
		clearTimeout(__.delayIntervallId);
		__.delayIntervallId = setTimeout(function() {
			__.removeClassName(controlWrap, 'nm-controls-wrap-video-full');
		}, 3000);
	}
};

__.isFullScreen = function() {
	/** @namespace document.fullScreen */
	/** @namespace document.webkitIsFullScreen */
	/** @namespace document.mozFullScreen */
	/** @namespace document.msFullscreenElement */
	/** @namespace document.fullscreenElement */
	return !!(document.fullScreen || document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement || document.fullscreenElement);
};

__.enterFullScreen = function(elm) {
	if (!elm.requestFullScreen) {
		/** @namespace elm.webkitRequestFullScreen */
		/** @namespace elm.msRequestFullscreen */
		/** @namespace elm.mozRequestFullScreen */
		elm.requestFullScreen = elm.mozRequestFullScreen || elm.webkitRequestFullScreen || elm.msRequestFullscreen;
	}
	elm.requestFullScreen();
};

__.isfullScreenClosed = function() {
	var fs = __.isFullScreen();
	if (!fs) {
		__.leaveFullScreen();
	}
};

exports.exitFullScreen = function() {
	if (!document.exitFullScreen) {
		/** @namespace document.webkitCancelFullScreen */
		/** @namespace document.msExitFullscreen */
		/** @namespace document.mozCancelFullScreen */
		document.exitFullScreen = document.mozCancelFullScreen || document.webkitCancelFullScreen || document.msExitFullscreen;
	}
	document.exitFullScreen();
	document.removeEventListener('mousemove', __.showHideControls);
	__.handleFullscreenEvents(false);
};

__.switchToFullScreen = function(btn) {
	var container = __.getCurrentVideoContainer(btn);
	if (container) {
		__.addClassName(container, 'active-fs');
		__.addClassName(btn, 'nm-minimize');
		__.handleFullscreenEvents(true);
		document.addEventListener('mousemove', __.showHideControls);
		__.enterFullScreen(container);
	}
};

__.leaveFullScreen = function(btn) {
	var container = __.getCurrentVideoContainer(btn),
		con;
	if (btn) {
		container = __.getCurrentVideoContainer(btn);
		if (container.className.indexOf('active-fs') > -1) {
			__.removeClassName(container, 'active-fs');
		}
		__.removeClassName(btn, 'nm-minimize');
	}
	else {
		con = document.querySelector('.active-fs');
		if (!!con) {
			__.removeClassName(con, 'active-fs');
			__.removeClassName(con.querySelector('.nm-minimize'), 'nm-minimize');
		}
	}
	exports.exitFullScreen();
};

__.handleFullscreenEvents = function(fullscreen) {
	if (fullscreen) {
		document.addEventListener('webkitfullscreenchange', __.isfullScreenClosed, false);
		document.addEventListener('mozfullscreenchange', __.isfullScreenClosed, false);
		document.addEventListener('fullscreenchange', __.isfullScreenClosed, false);
		document.addEventListener('MSFullscreenChange', __.isfullScreenClosed, false);
	}
	else {
		document.removeEventListener('webkitfullscreenchange', __.isfullScreenClosed, false);
		document.removeEventListener('mozfullscreenchange', __.isfullScreenClosed, false);
		document.removeEventListener('fullscreenchange', __.isfullScreenClosed, false);
		document.removeEventListener('MSFullscreenChange', __.isfullScreenClosed, false);
	}
};

__.handlerFullScreenButton = function() {
	var btn = this;
	if (!__.isFullScreen()) {
		__.switchToFullScreen(btn);
	}
	else {
		__.leaveFullScreen(btn);
	}
};

__.addClassName = function(ele, clname) {
	if (ele && ele.classList) {
		ele.classList.add(clname);
	}
};

__.removeClassName = function(ele, clname) {
	if (ele && ele.classList) {
		ele.classList.remove(clname);
	}
};

__.getTime = function(nTime_) {
	var min = Math.floor(nTime_ / 60) < 10 ? '0' + Math.floor(nTime_ / 60) : Math.floor(nTime_ / 60),
		sec = Math.floor(nTime_ - (min * 60)) < 10 ? '0' + Math.floor(nTime_ - (min * 60)) : Math.floor(nTime_ - (min * 60));
	return min + ':' + sec;
};

__.findClosest = function(el, selector) {
	var matchesFn = '',
		parent,
		element = el;
	// find vendor prefix
	['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function(fn) {
		if (typeof document.body[fn] === 'function') {
			matchesFn = fn;
			return true;
		}
		return false;
	});
	// traverse parents
	while (element) {
		parent = element.parentElement;
		if (parent && parent[matchesFn](selector)) {
			return parent;
		}
		element = parent;
	}
	return null;
};

__.handlerNativeControls = function(data) {
	var videos, vid, i;
	if (data && !!data.domElement) {
		videos = data.domElement.querySelectorAll('.nm-video');
	}
	else {
		videos = document.querySelectorAll('.nm-video');
	}
	for (i = 0; i < videos.length; i++) {
		__.addClassName(videos[i], 'nm-hide-controls');
		// vid = videos[i].querySelector('video');
		vid = __.getVideo(videos[i]);
		if (vid) {
			const nativeCtrl = vid.getAttribute('data-nativectrls');
			if (nativeCtrl !== 'false') {
				vid.setAttribute('controls', '');
			}
		}
	}
};

__.enableVideoFullStageMode = function() {
	document.querySelector('body').classList.add('nm-stage-full');
};

__.disableVideoFullStageMode = function() {
	document.querySelector('body').classList.remove('nm-stage-full');
};
// intro-stage Anpassung
__.handlerStagePlayButton = function() { // eslint-disable-line max-statements
	var stagePlayBtn = this,
		videoContainer = __.getCurrentVideoContainer(stagePlayBtn),
		video = __.getVideo(videoContainer),
		skipBtnContainer;
	if (video) {
		video.classList.remove('nm-hidden');
		video.play();
		video.addEventListener('ended', __.handlerStageSkipButton);
	}
	if (stagePlayBtn) {
		stagePlayBtn.classList.add('nm-hidden');
	}
	__.enableVideoFullStageMode();
	if (videoContainer) {
		skipBtnContainer = videoContainer.querySelector('.nm-introvideo');
		if (skipBtnContainer) {
			skipBtnContainer.classList.remove('nm-hidden');
		}
	}
	SWIPE.disableSwipe();
};

__.handlerStageSkipButton = function(event_) {
	var stageSkipBtn = this,
		videoContainer = __.getCurrentVideoContainer(stageSkipBtn),
		video = __.getVideo(videoContainer),
		skipBtnContainer = videoContainer ? videoContainer.querySelector('.nm-introvideo') : null,
		playBtn = videoContainer ? videoContainer.querySelector('.nm-j-play-btn-stage') : null;
	event_.preventDefault();
	if (video) {
		video.classList.add('nm-hidden');
		video.pause();
	}
	__.disableVideoFullStageMode();
	if (skipBtnContainer) {
		skipBtnContainer.classList.add('nm-hidden');
	}
	if (playBtn) {
		playBtn.classList.remove('nm-hidden');
	}
	SWIPE.enableSwipe();
};

__.handlerVideoClick = function() {
	var btn = this,
		container = __.getCurrentVideoContainer(btn),
		video = __.getVideo(container);
	if (!!video) {
		if (video.paused) {
			video.addEventListener('playing', __.handlerVideoPlaying);
			video.play();
		}
		else {
			video.addEventListener('pause', __.handlerVideoPause);
			video.pause();
		}
	}
};

/**
 * addStageAutoPlayEvent
 * Check if Stage video is in nm-strage container and if
 * autoplay and loop ist activated and video is loaded, add handleStageAutoplay
 * @returns {void} resturns nothing
 */
__.addStageAutoPlayEvent = function() {
	var container = document.querySelector('.nm-stage-container .nm-stage-media.nm-video'),
		video, sources, i;
	if (!!container) {
		if (container.getAttribute('data-autoplay') === 'true' || container.getAttribute('data-autoplay') === true) {
			video = __.getVideo(container);
			sources = video.querySelectorAll('source');
			if (!(navigator.userAgent.search('Safari') >= 0 && navigator.userAgent.search('Chrome') < 0)) {
				for (i = 0; i < sources.length; i++) {
					sources[i].media = sources[i].getAttribute('data-media');
				}
			}
			video.classList.remove('nm-hidden');
			video.addEventListener('canplay', __.handleStageAutoPlay);
			video.load();
		}
	}
};

/**
 * handleStageAutoPlay
 * Maximize Stage for 16:9 and play video muted in loop
 * @returns {void} returns nothing
 */
__.handleStageAutoPlay = function() {
	var stagePlayBtn = document.querySelector('.nm-stage-container .nm-stage-media .nm-j-play-btn-stage'),
		videoContainer = __.getCurrentVideoContainer(stagePlayBtn),
		video = __.getVideo(videoContainer);
	video.removeEventListener('canplay', __.handleStageAutoPlay);
	if (stagePlayBtn) {
		stagePlayBtn.classList.add('nm-hidden');
	}
	if (DEVICE.isMobile()) {
		video.controls = false;
	}
	__.enableVideoFullStageMode();
	SWIPE.disableSwipe();
	if (video) {
		video.classList.remove('nm-hidden');
		video.muted = true;
		video.play().catch(function(){
			// catching the known mobile browser restriction for autoplay
		});
		video.addEventListener('ended', function() {
			video.play().catch(function(){
				// catching the known mobile browser restriction for autoplay
			});
		});
	}
};
// End intro-stage Anpassung
/**
 * addAutoplayOnceEvent
 * get all videos with existing attribute data-autoplay-in-viewport
 * and add __.handleVideoInViewport on scroll listener
 * @returns {void} - returns nothing
 */
__.addAutoplayOnceEvent = function() {
	__.aAllAutoplayVideos = [].slice.call(document.querySelectorAll('video[data-autoplay-in-viewport]'));
	if (__.aAllAutoplayVideos.length > 0) {
		document.addEventListener('scroll', __.handleVideoInViewport);
	}
};

/**
 * handleVideoInViewport
 * When video comes to viewport play and remove from
 * __.aAllAutoplayVideos
 * @returns {void} returns nothing
 */
__.handleVideoInViewport = function() {
	__.aAllAutoplayVideos.forEach(function(video, index) {
		if (DOM_UTILS.isInViewport(video)) {
			video.addEventListener('playing', __.handlerVideoPlaying);
			video.muted = true;
			video.play().catch(function(){
				// catching the known mobile browser restriction for autoplay
			});
			__.aAllAutoplayVideos.splice(index, 1);
		}
	});
};

__.handlePageLoaded = function() {
	__.disableVideoFullStageMode();
	if (DEVICE.isMobile()) {
		__.initializeNativeControls();
	}
};

__.handlePageLeave = function () {
	__.removeEvents();
};

exports.resetVideoPlayer = function (videoContainer_, src_, poster_) {
	let video = videoContainer_.querySelector('video');
	if (video){
		if (src_){
			video.src = src_;
			video.load();
		}
		else {
			video.removeAttribute('src');
		}
		if (poster_){
			video.poster = poster_;
		}
		__.handleVideoEndEvents(video);
		__.removeClassName(videoContainer_, 'nm-video-playing');
		__.displayTimeInfos(videoContainer_, 0, 0);

	}
};

exports.enterFullscreen = function(elem_) {
	__.enterFullScreen(elem_);
};

__.addEvents = function() {
	__.eventBus.on(EVENTS.PAGE_READY, __.handlePageLoaded);
	__.eventBus.on(EVENTS.PAGE_LEAVE, __.handlePageLeave);
	__.domEventDelegate.on('click', '.nm-video .nm-j-play-btn .nm-video-button-play', __.handlerPlayButton);
	__.domEventDelegate.on('click', '.nm-video .nm-j-play-btn .nm-video-button-pause', __.handlerPauseButton);
	__.domEventDelegate.on('click', '.nm-video .nm-j-mute-btn', __.handlerMuteButton);
	__.domEventDelegate.on('click', '.nm-video .nm-video-loader', __.handlerSeekBar);
	__.domEventDelegate.on('click', '.nm-video .nm-j-fullscreen-btn', __.handlerFullScreenButton);
	// Stage Handler
	__.domEventDelegate.on('click', '.nm-video .nm-j-play-btn-stage', __.handlerStagePlayButton);
	__.domEventDelegate.on('click', '.nm-video .nm-j-skip-btn-stage', __.handlerStageSkipButton);
	// End Stage Handler
	// Visualizer Handler
	__.domEventDelegate.on('click', '.nm-prevent-video-click-visualizer', __.handlerVideoClick);
	// End Visualizer Handler*/
	__.addStageAutoPlayEvent();
	__.addAutoplayOnceEvent();
};

__.removeEvents = function () {
	__.eventBus.off(EVENTS.PAGE_READY, __.handlePageLoaded);
	document.removeEventListener('scroll', __.handleVideoInViewport);

	const videos = [...document.querySelectorAll('.nm-video video')];
	videos.forEach(video => {
		video.pause();
		video.removeEventListener('pause', __.handlerVideoPlaying);
		video.removeEventListener('playing', __.handlerVideoPlaying);
		video.removeEventListener('timeupdate', __.updateTimeInfo);
		video.removeEventListener('ended', __.handlerVideoEnded);
		video.removeEventListener('progress', __.handleVideoProgress);
		video.removeEventListener('canplay', __.handleStageAutoPlay);
	});
};

__.initializeNativeControls = function() {
	__.eventBus.on(EVENTS.PAGE_LOADED, __.handlerNativeControls);
	__.eventBus.on(EVENTS.LAYER_LOADED, __.handlerNativeControls);
	__.handlerNativeControls();
};

exports.handlerLayer = function(data) {
	if (DEVICE.isMobile()) {
		__.handlerNativeControls(data);
	}
};

exports.initialize = function(eventBus_) {
	__.eventBus = eventBus_;
	__.domEventDelegate = DOM_UTILS.getEventDelegate('body');
	__.removeEvents();
	__.addEvents();
	__.handlePageLoaded();
};

export {exports as videoNew};
