(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("microkernel"), require("core-application"), require("core-global"), require("core-utils"), require("core-vendor"));
	else if(typeof define === 'function' && define.amd)
		define("editorial", ["microkernel", "core-application", "core-global", "core-utils", "core-vendor"], factory);
	else if(typeof exports === 'object')
		exports["editorial"] = factory(require("microkernel"), require("core-application"), require("core-global"), require("core-utils"), require("core-vendor"));
	else
		root["editorial"] = factory(root["microkernel"], root["core-application"], root["core-global"], root["core-utils"], root["core-vendor"]);
})(globalThis, (__WEBPACK_EXTERNAL_MODULE__729__, __WEBPACK_EXTERNAL_MODULE__424__, __WEBPACK_EXTERNAL_MODULE__369__, __WEBPACK_EXTERNAL_MODULE__139__, __WEBPACK_EXTERNAL_MODULE__848__) => {
return 