const videoControlsTemplate=`<div class="nm-prevent-video-click"></div>
<div class="nm-controls-wrap-video">

	<div class="nm-video-loader">
		<div class="nm-loader-played"></div>
		<div class="nm-loader-loaded"></div>
	</div>
	<div class="nm-controls-video clearfix">
		<div class="nm-button-play-pause nm-play nm-j-play-btn">
			<button class="nm-video-button-play" type="button" title="Play/Pause"></button>
			<button class="nm-video-button-pause" type="button" title="Play/Pause"></button>
		</div>
		<div class="nm-title-wrap">
			<div class="nm-title"><span class="nm-el-lbl nm-el-lbl-01 nm-at-lbl-inv"></span></div>
			<div class="nm-time">
				<span class="nm-time-played ">00:00</span>
				<span class="nm-time-break"> | </span>
				<span class="nm-time-duration">00:00</span>
			</div>
		</div>

		<div class="nm-button-fullscreen nm-j-fullscreen-btn">
			<button class="" type="button" title="Fullscreen"></button>
		</div>

		<div class="nm-button-mute nm-mute nm-j-mute-btn">
			<button class="" type="button" title="Mute"></button>
		</div>
	</div>
</div>
<div class="nm-button-play nm-button-overlay nm-j-play-btn">
	<button class="nm-video-button-play" type="button" title="Play"><span class="nm-image-replace">Play</span></button>
</div>`;
export {videoControlsTemplate};
