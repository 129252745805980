import {appEvents} from 'core-application';
import {dom} from 'core-utils';

var __ = {},
	exports = {
		'__': __
	};

__.oDefaults = {
	'sClassNotificationBar': 'nm-md-notification-bar',
	'sSelectorNotificationBarCancel': '.nm-md-nb-cancel',
	'sSelectorNotificationBarLink': '.nm-md-nb-link',
	'sValueNotificationCanceled': 'nb-canceled'
};

/**
 * add events
 * @returns {void}
 */
__.addEvents = function () {
	__.eventBus.on(appEvents.PAGE_LOADED, __.checkForNotificationBar);
	__.domDelegate.on('click', __.oDefaults.sSelectorNotificationBarCancel, __.cancelNotificationbar);
	__.domDelegate.on('click', __.oDefaults.sSelectorNotificationBarLink, __.cancelNotificationbar);
};

/**
 * read an item from local storage
 * @param {string} key - key of the item to read
 * @returns {Object} item from local storage
 */
__.readLocalStorageFlag = function (key) {
	if (__.localStorageAlternative) {
		return __.localStorageAlternative[key];
	}
	else {
		return localStorage.getItem(key);
	}
};

/**
 * write a boolean to local storage
 * @param {string} key - key of the item to set
 * @returns {void}
 */
__.writeLocalStorageFlag = function (key) {
	try {
		localStorage.setItem(key, true);
	}
	catch (err) {
		__.localStorageAlternative = __.localStorageAlternative ? __.localStorageAlternative : {};
		__.localStorageAlternative[key] = true;
	}
};

/**
 * check whether notification bar should be displayed
 * @returns {void}
 */
__.checkForNotificationBar = function () {
	if (!__.readLocalStorageFlag(__.oDefaults.sValueNotificationCanceled)) {
		__.showNotificationBar();
	}
};

/**
 * show notification bar
 * @returns {void}
 */
__.showNotificationBar = function () {
	if (document.getElementsByClassName(__.oDefaults.sClassNotificationBar) && document.getElementsByClassName(__.oDefaults.sClassNotificationBar).length > 0) {
		document.getElementsByClassName(__.oDefaults.sClassNotificationBar)[0].classList.add('nm-j-notification-foldout');
	}
};

/**
 * hide notification bar
 * @returns {void}
 */
__.hideNotificationBar = function () {
	if (document.getElementsByClassName(__.oDefaults.sClassNotificationBar) && document.getElementsByClassName(__.oDefaults.sClassNotificationBar).length > 0) {
		document.getElementsByClassName(__.oDefaults.sClassNotificationBar)[0].classList.remove('nm-j-notification-foldout');
	}
};

/**
 * never again show the notification bar
 * @returns {void}
 */
__.cancelNotificationbar = function () {
	__.writeLocalStorageFlag(__.oDefaults.sValueNotificationCanceled);
	__.hideNotificationBar();
};

exports.initialize = function (eventEmitter) {
	__.eventBus = eventEmitter;
	__.domDelegate = dom.getEventDelegate('body');
	__.addEvents();
	__.checkForNotificationBar();
};

export {exports as notificationBar};
