import {appEvents as EVENTS} from 'core-application';
import {dom as DOM_UTILS} from 'core-utils';

const __ = {},
	exports = {
		__: __
	};

__.oDefaults = {
	sSelectorSliderContainer: '.nm-interactive-image-slider__image-container',
	sSelectorSliderMainImage: '.nm-interactive-image-slider__first-image',
	sSelectorSliderOverlayImage: '.nm-interactive-image-slider__second-image',
	sSelectorSliderControl: '.nm-interactive-image-slider__control'
};

__.addEvents = function() {
	__.eventBus.on(EVENTS.PAGE_LOADED, exports.handler);
	__.eventBus.on(EVENTS.LAYER_LOADED, exports.handler);
	exports.handler();
};

exports.dispose = function() {
	__.eventBus.off(EVENTS.PAGE_LOADED, exports.handler);
	__.eventBus.off(EVENTS.LAYER_LOADED, exports.handler);
};

exports.handler = function() {
	// click animation listeners
	__.domEventDelegate.on('click', __.oDefaults.sSelectorSliderMainImage, __.clickMainImageEventHandler);

	__.domEventDelegate.on('click', __.oDefaults.sSelectorSliderOverlayImage, __.clickOverlayImageEventHandler);
	// drag listener
	__.domEventDelegate.on('mousedown', __.oDefaults.sSelectorSliderControl, __.activateDragEventHandler);
	__.domEventDelegate.on('touchstart', __.oDefaults.sSelectorSliderControl, __.activateDragEventHandler);
};

__.clickMainImageEventHandler = function() {
	var overlayImage;
	var associatedContainer = DOM_UTILS.closest(this, __.oDefaults.sSelectorSliderContainer);
	overlayImage = DOM_UTILS.siblings(this, __.oDefaults.sSelectorSliderOverlayImage)[0];
	__.changeSliderControlPosition(overlayImage, associatedContainer.offsetWidth - 1);
};

__.clickOverlayImageEventHandler = function() {
	__.changeSliderControlPosition(this, 0);
};

__.changeSliderControlPosition = function(overlayImage, controlPosition) {
	var sliderControl, containerWidth = DOM_UTILS.closest(overlayImage, __.oDefaults.sSelectorSliderContainer).offsetWidth;
	sliderControl = DOM_UTILS.siblings(overlayImage, __.oDefaults.sSelectorSliderControl)[0];
	jQuery(sliderControl).animate({
		left: controlPosition
	}, 300, false); // TODO replace jQuery animation
	jQuery(overlayImage).animate({
		width: containerWidth - controlPosition
	}); // TODO replace jQuery animation
};

__.activateDragEventHandler = function() {
	this.removeEventListener('mousedown', __.activateDragEventHandler, true);
	this.removeEventListener('touchstart', __.activateDragEventHandler, true);
	__.domEventDelegate.off('mousemove', __.oDefaults.sSelectorSliderContainer, __.dragMovement);
	__.domEventDelegate.off('touchmove', __.oDefaults.sSelectorSliderContainer, __.dragMovement);
	__.domEventDelegate.on('mousemove', __.oDefaults.sSelectorSliderContainer, __.dragMovement);
	__.domEventDelegate.on('touchmove', __.oDefaults.sSelectorSliderContainer, __.dragMovement);
	__.domEventDelegate.off('mouseup', __.deactivateDragEventHandler);
	__.domEventDelegate.on('mouseup', __.deactivateDragEventHandler);
	__.domEventDelegate.off('touchend', __.deactivateDragEventHandler);
	__.domEventDelegate.on('touchend', __.deactivateDragEventHandler);
};

__.getSliderPositionX = function(event, elem) {
	var sliderPositionX = 0;
	if (event.type === 'touchmove') {
		sliderPositionX = event.touches[0].pageX - elem.getBoundingClientRect().left;
	}
	else {
		sliderPositionX = event.pageX - elem.getBoundingClientRect().left;
	}
	return sliderPositionX;
};

__.dragMovement = function(event) {
	var overlayWidth, containerWidth,
		sliderPositionX = __.getSliderPositionX(event, this);
	// make it snappy...
	if (sliderPositionX <= 20) {
		sliderPositionX = 0;
	}
	if (this.clientWidth - sliderPositionX <= 20) {
		sliderPositionX = this.clientWidth - 1;
	}
	overlayWidth = this.clientWidth - sliderPositionX;
	containerWidth = DOM_UTILS.closest(this, __.oDefaults.sSelectorSliderContainer).clientWidth;
	if (overlayWidth >= 0 && overlayWidth <= containerWidth) {
		DOM_UTILS.getElement(__.oDefaults.sSelectorSliderControl, this).style.left = sliderPositionX + 'px';
		DOM_UTILS.getElement(__.oDefaults.sSelectorSliderOverlayImage, this).style.width = overlayWidth + 'px';
	}
	else {
		__.deactivatedDrag(this);
	}
};

__.deactivateDragEventHandler = function() {
	__.deactivatedDrag(this);
};

__.deactivatedDrag = function(/* targetObject */) {
	__.domEventDelegate.off('mousedown', __.oDefaults.sSelectorSliderControl, __.activateDragEventHandler);
	__.domEventDelegate.off('touchstart', __.oDefaults.sSelectorSliderControl, __.activateDragEventHandler);
	__.domEventDelegate.on('mousedown', __.oDefaults.sSelectorSliderControl, __.activateDragEventHandler);
	__.domEventDelegate.on('touchstart', __.oDefaults.sSelectorSliderControl, __.activateDragEventHandler);
	__.domEventDelegate.off('mousemove', __.oDefaults.sSelectorSliderContainer, __.dragMovement);
	__.domEventDelegate.off('touchmove', __.oDefaults.sSelectorSliderContainer, __.dragMovement);
	__.domEventDelegate.off('mouseup', __.deactivateDragEventHandler);
	__.domEventDelegate.off('touchend', __.deactivateDragEventHandler);
};


exports.initialize = function(eventBus_) {
	__.eventBus = eventBus_;
	__.domEventDelegate = DOM_UTILS.getEventDelegate('body');
	__.addEvents();
};

export {exports as interactiveImageSlider};
