import {appEvents} from 'core-application';

var __, exports;

__ = {};

exports = {
	__: __
};

__.oDefaults = {
	sClassActive: 'nm-active',
	sClassDuringAnimation: 'nm-animating',
	sClassNoAnimation: 'no-animation',
	sClassForwardAnimation: 'nm-animation-forward',
	sClassBackwardAnimation: 'nm-animation-backward',

	sSelectorStage: '.nm-layer-multi-stage',
	sSelectorNext: '.nm-nextarrow',
	sSelectorPrevious: '.nm-backarrow',
	sSelectorLayerStageImageActive: '.nm-layer-image.nm-active',
	sSelectorLayerStageImage: '.nm-layer-image'
};

__.initialize = function () {
	__.addEvents();
};

__.addEvents = function () {
	__.eventBus.on(appEvents.PAGE_LOADED, __.handleLayerLoaded);
	__.eventBus.on(appEvents.LAYER_LOADED, __.handleLayerLoaded);
};

__.handleLayerLoaded = function (payload) {

	var domElement;

	if (payload.domElement) {
		domElement = payload.domElement;
	}
	else if (payload.element) {
		domElement = payload.element;
	}

	if (domElement) {
		__.addStageEvents(domElement);
	}
	else {
		console.log('no dom element available');
	}
};

__.addStageEvents = function (stageElement) {
	jQuery(stageElement).on('click', __.oDefaults.sSelectorNext, __.handleNextClick);
	jQuery(stageElement).on('click', __.oDefaults.sSelectorPrevious, __.handlePrevClick);
	jQuery(__.oDefaults.sSelectorStage).on('mousedown', __.activateSwipe);
	jQuery(__.oDefaults.sSelectorStage).on('touchstart', __.activateSwipe);
	jQuery(document).on('mouseup', __.deactivateSwipe);
	jQuery(document).on('touchend', __.deactivateSwipe);
};

__.handleNextClick = function (e) {
	var activeImg;

	e.preventDefault();

	if (jQuery(__.oDefaults.sSelectorStage).hasClass(__.oDefaults.sClassDuringAnimation)) {
		return;
	}

	jQuery(__.oDefaults.sSelectorStage).addClass(__.oDefaults.sClassDuringAnimation);

	activeImg = jQuery(__.oDefaults.sSelectorLayerStageImageActive);

	jQuery(__.oDefaults.sSelectorStage).addClass(__.oDefaults.sClassNoAnimation);
	jQuery(__.oDefaults.sSelectorStage).addClass(__.oDefaults.sClassForwardAnimation);

	setTimeout(function () {
		jQuery(__.oDefaults.sSelectorStage).removeClass(__.oDefaults.sClassNoAnimation);

		setTimeout(function () {
			jQuery(__.oDefaults.sSelectorStage).addClass(__.oDefaults.sClassNoAnimation);

			jQuery(__.oDefaults.sSelectorLayerStageImage).each(function () {
				if ($(this).is(activeImg)) {
					$(this).removeClass(__.oDefaults.sClassActive);
				}
				else {
					$(this).addClass(__.oDefaults.sClassActive);
				}
			});

			jQuery(__.oDefaults.sSelectorStage).removeClass(__.oDefaults.sClassForwardAnimation);

			setTimeout(function () {
				jQuery(__.oDefaults.sSelectorStage).removeClass(__.oDefaults.sClassNoAnimation);
				jQuery(__.oDefaults.sSelectorStage).removeClass(__.oDefaults.sClassDuringAnimation);
			}, 10);

		}, 600);

	}, 10);
};

__.handlePrevClick = function (e) {
	var activeImg;

	e.preventDefault();

	if (jQuery(__.oDefaults.sSelectorStage).hasClass(__.oDefaults.sClassDuringAnimation)) {
		return;
	}

	jQuery(__.oDefaults.sSelectorStage).addClass(__.oDefaults.sClassDuringAnimation);

	activeImg = jQuery(__.oDefaults.sSelectorLayerStageImageActive);

	jQuery(__.oDefaults.sSelectorStage).addClass(__.oDefaults.sClassNoAnimation);
	jQuery(__.oDefaults.sSelectorStage).addClass(__.oDefaults.sClassBackwardAnimation);

	setTimeout(function () {
		jQuery(__.oDefaults.sSelectorStage).removeClass(__.oDefaults.sClassNoAnimation);

		setTimeout(function () {
			jQuery(__.oDefaults.sSelectorStage).addClass(__.oDefaults.sClassNoAnimation);

			jQuery(__.oDefaults.sSelectorLayerStageImage).each(function () {
				if ($(this).is(activeImg)) {
					$(this).removeClass(__.oDefaults.sClassActive);
				}
				else {
					$(this).addClass(__.oDefaults.sClassActive);
				}
			});

			jQuery(__.oDefaults.sSelectorStage).removeClass(__.oDefaults.sClassBackwardAnimation);

			setTimeout(function () {
				jQuery(__.oDefaults.sSelectorStage).removeClass(__.oDefaults.sClassNoAnimation);
				jQuery(__.oDefaults.sSelectorStage).removeClass(__.oDefaults.sClassDuringAnimation);
			}, 10);

		}, 600);

	}, 10);
};

__.activateSwipe = function (e) {
	if (e.type === 'touchstart') {
		__.swipeX = e.originalEvent.pageX;
	}
	else {
		__.swipeX = e.pageX;
	}
	jQuery(document).on('mousemove', __.swipe);
	jQuery(document).on('touchmove', __.swipe);
};

__.deactivateSwipe = function () {
	jQuery(document).off('mousemove', __.swipe);
	jQuery(document).off('touchmove', __.swipe);
};

__.swipe = function (e) {
	var pos;
	if (e.type === 'touchmove') {
		pos = e.originalEvent.pageX;
	}
	else {
		pos = e.pageX;
	}

	if (pos >= __.swipeX + 5) {
		__.deactivateSwipe(e);
		__.handlePrevClick(e);
	}
	else if (pos <= __.swipeX - 5) {
		__.deactivateSwipe(e);
		__.handleNextClick(e);
	}
};

exports.initialize = function (eventEmitter) {
	__.eventBus = eventEmitter;
	__.initialize();
};

export {exports as layerMultiStage};
