import {appEvents as EVENTS} from 'core-application';
const __ = {},
	// Public API
	exports = {
		__: __
	};
__.oDefaults = {
	sSelectorCurrentDownloadBtn: '.nm-mediagallery-download-current',
	sSelectorDownloadAllBtn: '.nm-mediagallery-download-all',
	sIdCheckBox: '#nm-mediagallery-download-checkbox',
	sSelectorLinks: '.nm-mediagallery-download a',
	sSelectorDownloadBox: '.mediagallery-download-infobox'
};
__.addEvents = function() {
	__.eventBus.on(EVENTS.MEDIAGALLERY_ITEM_CHANGED, __.handleUpdates);
	jQuery('body').on('click.mg_download_link', __.oDefaults.sSelectorLinks, __.closeDownloadBox);
};
// update item´d downloadlink
__.handleUpdates = function(data_) {
	var $currentBtn = jQuery([__.oDefaults.sSelectorCurrentDownloadBtn, ' a'].join(''));
	var $downloadBox = jQuery('.nm-mediagallery-download');
	if (!!data_ && data_.item) {
		if (!!data_.item.downloadlink) {
			$currentBtn.attr('href', data_.item.downloadlink).removeClass('nm-hidden');
		}
		else {
			$currentBtn.addClass('nm-hidden');
			// hide box if no download allBtn is available
			if (jQuery(__.oDefaults.sSelectorDownloadAllBtn).length < 1) {
				$downloadBox.addClass('nm-hidden');
			}
			else {
				$downloadBox.removeClass('nm-hidden');
			}
		}
		if (data_.item.contenttype === 'video') {
			$currentBtn.addClass('nm-hidden');
		}
		else {
			$currentBtn.removeClass('nm-hidden');
		}
	}
	else {
		console.warn('no link url to update!!');
	}
};
__.closeDownloadBox = function() {
	jQuery(__.oDefaults.sIdCheckBox).prop('checked', false);
	jQuery(__.oDefaults.sSelectorDownloadBox).removeClass('nm-checked');
};

exports.initialize = function(eventEmitter_) {
	__.eventBus=eventEmitter_;
	__.addEvents();
};
export {exports as mediagalleryDownload};
