import {cookieModal} from './editorial/cookie-modal';
import {footnote} from './editorial/footnote';
import {gsaSearch} from './editorial/gsa-search';
import {iframe} from './editorial/iframe';
import {iframeHeight} from './editorial/iframe-height';
import {inlineMediagallery} from './editorial/inline-mediagallery';
import InteractiveImageRotation from './editorial/interactive-image-rotation';
import {interactiveImageSlider} from './editorial/interactive-image-slider';
import {interstitialLayer} from '../../../components/interstitial-layer/interstitial-layer';
import {mediagalleryController} from './editorial/mediagallery/controller';
import {mediagalleryCounter} from './editorial/mediagallery/counter';
import {mediagalleryDownload} from './editorial/mediagallery/download';
import {mediagalleryModel} from './editorial/mediagallery/model';
import {mediagalleryStage} from './editorial/mediagallery/stage';
import {mediagalleryThumbnailSlider} from './editorial/mediagallery/thumbnailslider';
import MessageLayerElement from '../../../components/message-layer/message-layer-element';
import ModalLayerInterstitialElement from '../../../components/interstitial-layer/modal-layer-interstitial-element';
import {notificationBar} from './editorial/notification-bar';
import {panorama} from './editorial/panorama';
import {pageService} from './editorial/page-service';
import {patchDealerLinks} from './editorial/patch-dealer-links';
import {renderStage} from './editorial/render-stage';
import {signal} from 'core-application';
import {stage} from './editorial/stage';
import {swipeStage} from './editorial/swipe-stage';
import {videoControlsTemplate} from './editorial/mediagallery/video-controls-template';
import {videoNew} from './editorial/video-new';
import {layerMultiStage} from './editorial/layer-multi-stage';
import {navigation} from './editorial/navigation';
import './editorial/page-store-subscriber';


/**
 * modules to be initialized with event emitter
 */
const toBeInitialized = [
	gsaSearch,
	iframe,
	iframeHeight,
	inlineMediagallery,
	interactiveImageSlider,
	interstitialLayer,
	layerMultiStage,
	mediagalleryController,
	mediagalleryCounter,
	mediagalleryDownload,
	mediagalleryModel,
	mediagalleryStage,
	mediagalleryThumbnailSlider,
	notificationBar,
	navigation,
	pageService,
	panorama,
	renderStage,
	stage,
	swipeStage,
	videoNew
];

/**
 * initialize modules and inject signal dependency
 */
const eventEmitter = signal.getEmitter();
toBeInitialized.forEach(module => {
	try {
		module.initialize(eventEmitter);
	}
	catch (err) {
		console.error(err);
	}
});

export {
	cookieModal,
	footnote,
	gsaSearch,
	iframe,
	iframeHeight,
	inlineMediagallery,
	InteractiveImageRotation,
	interactiveImageSlider,
	interstitialLayer,
	layerMultiStage,
	mediagalleryController,
	mediagalleryCounter,
	mediagalleryDownload,
	mediagalleryModel,
	mediagalleryStage,
	mediagalleryThumbnailSlider,
	MessageLayerElement,
	ModalLayerInterstitialElement,
	notificationBar,
	navigation,
	panorama,
	pageService,
	patchDealerLinks,
	renderStage,
	stage,
	swipeStage,
	videoControlsTemplate,
	videoNew
};
