import {appEvents} from 'core-application';

var __ = {},
	exports = {
		__: __
	};

__.stage = null;
__.isAnimating = false;
__.domElement = null;
__.lastRotationMillis = -1;
__.autorotateDisableWidth = 1023;
__.maxViewIndex = -1;
__.viewNamesArr = null;
__.currentView = null;
__.selectedCategory = null;
__.direction = null;

__.oDefaults = {
	sAttrAutorotation: 'data-autorotation',
	sAttrViewName: 'data-view-name',
	sAttrStartDate: 'data-startdate',
	sAttrEndDate: 'data-enddate',
	sAttrViewCategory: 'data-view-category',

	sClassHidden: 'nm-hidden',
	sClassHomepage: 'nm-homepage',
	sClassMobileActive: 'nm-mobile-ok',
	sClassBrowseForward: 'nm-nextarrow',
	sClassSelectedMenu: 'nm-bs-selected',
	sClassHasMobileImg: 'nm-stage-has-mobileimg',
	sClassExtendedCTA: 'nm-homepage-stage-extend-cta',
	sSelectorStage: '#nm-id-stage, .nm-module-mediathek-stage',
	sSelectorHomepage: '.nm-homepage',
	sSelectorInitialContentArea: '.nm-wrapper-content .nm-area-content:first-child',
	sSelectorNext: 'ul.nm-stage-browse>li.nm-nextarrow',
	sSelectorPrevious: 'ul.nm-stage-browse>li.nm-backarrow',
	sSelectorDot: 'ul.nm-dots-navi .nm-dot',
	sSelectorDotNavi: 'ul.nm-dots-navi',
	sSelectorStageItemScript: '.nm-stage-item script',
	sSelectorHomepageStageContainer: '.nm-homepage .nm-stage-container',
	sSelectorStageContainer: '.nm-stage-container',
	sSelectorMenuItems: 'li.nm-stage-item',
	sSelectorBsRemote: '.nm-bs-remote',
	sSelectorStageContent: '.nm-stage',
	sSelectorRenderImage: 'nm-stage-img.nm-j-configurator-renderimage',
	sSelectorMenuIcons: 'ul[class^=nm-stage-list-]',
	sSelectorStageContentWrap: '.nm-homepage-stage-teaser-content-wrap',
	sSelectorStageMobileImage: '.nm-stage-mobile-image',
	sSelectorCTALink: '.nm-homepage-stage-teaser-content a',
	sDotTemplate: '<li><a class="nm-dot" href="#"></a></li>'
};

__.initialize = function () {
	var domElement;
	__.addEvents();
	domElement = jQuery(__.oDefaults.sSelectorHomepage);
	if (domElement.length === 0) {
		domElement = jQuery(__.oDefaults.sSelectorInitialContentArea);
	}

	if (domElement.length !== 0) {
		__.initializeOnDomElement(domElement);
	}
	else {
		console.log('stage.js - no matching dom element');
	}
};

/*
 * DOM dependent initialization
 */
__.initializeOnDomElement = function(domElement_) {
	var domElement = domElement_;
	// can be called from LAYER-CLOSED event -> then domElement is empty
	// and needs to be initialized with the default selector
	if (domElement.length === 0) {
		domElement = jQuery(__.oDefaults.sSelectorInitialContentArea);
	}

	__.stage = domElement.find(__.oDefaults.sSelectorStage);
	__.addStageEvents(__.stage);
	__.domElement = domElement;
	__.filterUnpublishedTeasers(domElement);
	__.initializeAutorotationOfStage(__.stage);
	__.resetStage(domElement, __.stage);
};

/*
 * init autorotation of stage if needed
 */
__.initializeAutorotationOfStage = function (stage) {
	__.lastRotationMillis = new Date().getTime();
	let stageContainer = stage.find('[' + __.oDefaults.sAttrAutorotation + ']');

	if (stageContainer.length > 0) {
		let autorotationStr = stageContainer.attr(__.oDefaults.sAttrAutorotation);

		if (typeof autorotationStr !== 'undefined') {
			let autorotateMillis = Math.round(autorotationStr) * 1000;

			// autorotate every x seconds if needed
			if (autorotateMillis > 0) {
				window.setInterval(function () {

					if (jQuery(window).width() <= __.autorotateDisableWidth) {
						return;
					}

					let currTime = new Date().getTime();

					if (currTime > __.lastRotationMillis + autorotateMillis) {
						let view = __.showNextView();
						exports.setCurrentView(view);
					}
				}, autorotateMillis);
			}
		}
	}
};

/*
 * this method filters all teasers that should not be published by now
 * e.g. christmas special that should only be available on christmas
 */
__.filterUnpublishedTeasers = function (domElement) {

	domElement.find(__.oDefaults.sSelectorStageItemScript).each(function () { // eslint-disable-line complexity, max-statements

		var html = domElement.find(this).html();
		var $teaser = jQuery('<div/>').html(html).find(':first-child');

		if ($teaser.length > 0) {
			let startDateStr = $teaser.attr(__.oDefaults.sAttrStartDate);
			let endDateStr = $teaser.attr(__.oDefaults.sAttrEndDate);

			if (typeof startDateStr === 'undefined' || typeof endDateStr === 'undefined') {
				return;
			}

			let startDate = Math.round(startDateStr);
			let endDate = Math.round(endDateStr);

			let currentDate = new Date().getTime();

			if (startDate === 0 && endDate === 0) {
				// neither startDate nor endDate are set
				return;
			}
			else if (startDate > 0 && endDate > 0) {
				// startDate and endDate are set
				if (startDate <= currentDate && currentDate < endDate) {
					return;
				}
			}
			else if (startDate > 0) {
				// only startDate is set
				if (startDate <= currentDate) {
					return;
				}
			}
			else if (endDate > 0) {
				// only endDate is set
				if (currentDate < endDate) {
					return;
				}
			}
		}

		let parent = jQuery(this).parent();

		// if it is a view that is currently shown => remove it
		let dataViewName = parent.attr(__.oDefaults.sAttrViewName);
		$('[' + __.oDefaults.sAttrViewName + '="' + dataViewName + '"]').remove();
	});

	// If the first teaser was removed we will have to use a different teaser
	let stageContainer = domElement.find(__.oDefaults.sSelectorStageContainer);
	if (stageContainer.children().length < 1) {
		let firstTeaserHtml = domElement.find(__.oDefaults.sSelectorStageItemScript).first().html();
		stageContainer.html(firstTeaserHtml);
	}

	// show homepage stage (might be hidden)
	domElement.find(__.oDefaults.sSelectorHomepageStageContainer).css('visibility', 'visible');
};

/**
 * change the current selected view category
 * @param {Object} viewName_ - TODO
 * @returns {void}
 */
exports.setCurrentView = function (viewName_) { // eslint-disable-line max-statements

	// save previously selected category
	var previousSelection = __.currentView.attr(__.oDefaults.sAttrViewName),
		prevIndex, newIndex, stageContainer, templateHTML, template, contentWidth, xPos;

	// cancel if not change happened
	if (previousSelection === viewName_) {
		return false;
	}
	__.lastRotationMillis = new Date().getTime();
	__.isAnimating = true;

	prevIndex = parseInt(__.getCurrentViewIndex(previousSelection), 10);
	newIndex = parseInt(__.getCurrentViewIndex(viewName_), 10);

	__.direction = 'left';

	if (prevIndex > newIndex && !(prevIndex === __.maxViewIndex && newIndex === 0) ||
		(prevIndex === 0 && newIndex === __.maxViewIndex)) {
		__.direction = 'right';
	}

	stageContainer = __.domElement.find(__.oDefaults.sSelectorStageContainer).first();

	// verhindernt dass im bestehenden view renderbilder schon veraendert werden
	stageContainer.find('.nm-j-configurator-renderimage').removeClass('nm-j-configurator-renderimage');
	stageContainer.addClass('nm-stage-animation-start-' + __.direction);

	// append new view
	templateHTML = __.getTemplateByName(viewName_);
	template = jQuery(templateHTML);

	// _$currentView=$template.appendTo(jQuery(_oDefaults.sStageContainerSelector).first().empty());
	__.currentView = template.appendTo(__.domElement.find(__.oDefaults.sSelectorStageContainer));
	__.selectedCategory = __.currentView.attr(__.oDefaults.sAttrViewCategory);
	__.updateSelectionStatus(__.domElement, __.stage);

	// animate stage
	contentWidth = stageContainer.width();
	xPos = '-' + (contentWidth * 0.5) + 'px';
	__.updateRenderImageDataViewAttr(__.domElement);

	if (__.direction === 'left') {
		stageContainer.animate({
			'margin-left': xPos
		}, 600, 'easeOutSine', __.animationEnded);
	}
	else {
		stageContainer.css('margin-left', xPos).animate({
			'margin-left': 0
		}, 600, 'easeOutSine', __.animationEnded);
	}
};


exports.jumpToFirstRenderView = function () {
	var firstRenderViewName = __.getFirstRenderImageViewInList();

	if (firstRenderViewName) {
		exports.setCurrentView(firstRenderViewName);
	}
};

__.getFirstRenderImageViewInList = function () {
	var view = false;
	var arr = __.viewNamesArr;
	var len = arr.length;
	var tpl, i;

	for (i = 0; i < len; i++) {
		tpl = jQuery(__.getTemplateByName(arr[i])).find(__.oDefaults.sSelectorRenderImage);
		if (tpl.length) {
			view = arr[i];
			return view;
		}
	}
	return view;
};

__.animationEnded = function () {
	$(this)
		.removeClass(['nm-stage-animation-start-', __.direction].join(''))
		.removeAttr('style')
		.find('.nm-stage')
		.first()
		.remove();

	__.isAnimating = false;
	__.eventBus.emit(appEvents.STAGE_RENDERIMAGE_UPDATED);
};

__.buildViewIndexArr = function (domElement) {
	var arr = [];
	domElement.find(__.oDefaults.sSelectorMenuItems).each(function () {
		arr.push(this.getAttribute(__.oDefaults.sAttrViewName));
	});
	return arr;
};

__.getCurrentViewIndex = function (viewName_) {
	var pos, viewName = viewName_ || __.currentView.attr(__.oDefaults.sAttrViewName);
	pos = jQuery.inArray(viewName, __.viewNamesArr);
	return pos;
};

__.buildOrUpdateDotNavi = function (stage) { // eslint-disable-line max-statements
	var dotContainer = stage.find(__.oDefaults.sSelectorDotNavi);
	var stageNavi = stage.find(__.oDefaults.sSelectorBsRemote);
	var dotTemplate = __.oDefaults.sDotTemplate;

	if (dotContainer.children().length === __.maxViewIndex + 1) {
		jQuery(__.oDefaults.sSelectorDot).removeClass(__.oDefaults.sClassSelectedMenu);
	}
	else {
		dotContainer.empty();
		let templateString = '';
		for (let i = 0; i <= __.maxViewIndex; i++) {
			templateString += dotTemplate;
		}
		dotContainer.append(jQuery(templateString));
	}
	let currIndex = __.getCurrentViewIndex();
	let $currDot = jQuery(dotContainer.find('.nm-dot').get(currIndex));

	if (__.currentView.hasClass(__.oDefaults.sClassHasMobileImg)) {
		if (jQuery('body').hasClass(__.oDefaults.sClassHomepage)) {
			if (stage.hasClass(__.oDefaults.sClassMobileActive)) {
				dotContainer.addClass(__.oDefaults.sClassHasMobileImg);
				stageNavi.addClass(__.oDefaults.sClassHasMobileImg);
			}
			else {
				dotContainer.removeClass(__.oDefaults.sClassHasMobileImg);
				stageNavi.removeClass(__.oDefaults.sClassHasMobileImg);
			}
		}
		else {
			dotContainer.addClass(__.oDefaults.sClassHasMobileImg);
			stageNavi.addClass(__.oDefaults.sClassHasMobileImg);
		}
	}
	else {
		dotContainer.removeClass(__.oDefaults.sClassHasMobileImg);
		stageNavi.removeClass(__.oDefaults.sClassHasMobileImg);
	}
	$currDot.addClass(__.oDefaults.sClassSelectedMenu);
};


__.getViewAtIndex = function (index_) {
	var item = __.domElement.find(__.oDefaults.sSelectorMenuItems).get(index_);
	return __.domElement.find(item).attr(__.oDefaults.sAttrViewName);
};

__.getTemplateByName = function (viewName_) {
	// jQuery 1.9 BUG with client-side templates and whitespaces!!!=>trim()
	var template = jQuery(['#', viewName_, '-tpl'].join('')).html() || '';
	return template.trim();
};


__.handleMenuClick = function (event_) {
	var target, viewName;
	event_.preventDefault();

	if (__.isAnimating) {
		return;
	}
	// get clicked container
	target = jQuery(event_.currentTarget);
	viewName = target.find('li').first().attr(__.oDefaults.sAttrViewName);
	exports.setCurrentView(viewName);
};

__.handleDotClick = function (event_) {
	var index, viewName;
	event_.preventDefault();

	if (__.isAnimating) {
		return;
	}

	index = $(this).parent().index();
	viewName = __.getViewAtIndex(index);

	exports.setCurrentView(viewName);
};

__.handlePrevNextClick = function (event_) {
	var target, view;
	event_.preventDefault();
	if (__.isAnimating) {
		return;
	}
	target = jQuery(event_.currentTarget);

	if (target.attr('class') === __.oDefaults.sClassBrowseForward) {
		view = __.showNextView();
	}
	else {
		view = __.showPreviousView();
	}

	exports.setCurrentView(view);
};


__.getNextIndex = function (index_) {
	var index = index_ || __.getCurrentViewIndex(),
		max = __.maxViewIndex;

	index = (++index) % (max + 1);

	return index;
};

__.getPreviousIndex = function (index_) {
	var index = index_ || __.getCurrentViewIndex(),
		max = __.maxViewIndex;

	index--;
	if (index < 0) {
		index = max;
	}

	return index;
};

__.showNextView = function () {
	return __.getViewAtIndex(__.getNextIndex());
};

__.showPreviousView = function () {
	return __.getViewAtIndex(__.getPreviousIndex());
};


__.checkForExtendedCTA = function () {
	if (__.currentView.hasClass(__.oDefaults.sClassExtendedCTA)) {
		__.currentView.find(__.oDefaults.sSelectorStageContentWrap).on('click', __.extendedCTAClickHandler);
		__.currentView.find(__.oDefaults.sSelectorStageMobileImage).on('click', __.extendedCTAClickHandler);
	}
	else {
		__.currentView.find(__.oDefaults.sSelectorStageContentWrap).off('click', __.extendedCTAClickHandler);
		__.currentView.find(__.oDefaults.sSelectorStageMobileImage).off('click', __.extendedCTAClickHandler);
	}
};


__.extendedCTAClickHandler = function (e) {
	var target = $(e.target), linkURL, ctaLink = target.find(__.oDefaults.sSelectorCTALink);

	if (ctaLink && ctaLink.length > 0) {
		linkURL = ctaLink.attr('href');

		target = ctaLink.attr('target');

		if (linkURL && target) {
			window.open(linkURL, target);
		}
		else {
			location.href = linkURL;
		}
	}
};

/**
 * update selection status in the menu
 * @param {Object} domElement - TODO
 * @param {Object} stage - TODO
 * @returns {void}
 */
__.updateSelectionStatus = function (domElement, stage) {
	var categoryItems;
	__.checkForExtendedCTA();
	__.buildOrUpdateDotNavi(stage);

	// Icons aktualisieren
	categoryItems = domElement.find(__.oDefaults.sSelectorMenuIcons);
	categoryItems
		.removeClass(__.oDefaults.sClassSelectedMenu)
		.filter(['.nm-stage-list-', __.selectedCategory].join(''))
		.addClass(__.oDefaults.sClassSelectedMenu);
};

/**
 * update all renderimage data atributes matching the currenty selected car perspective
 * data-renderimage-type="thumbnail" data-renderimage-view="$item.View" data-renderimage-context="stage"
 * @param {Object} domElement - TODO
 * @returns {void}
 */
__.updateRenderImageDataViewAttr = function (domElement) {
	var $item, viewName;

	// current view of the car (exterior,exterior_front,exterior_back...)
	viewName = __.currentView.attr(__.oDefaults.sAttrViewName);

	__.domElement
		.find(__.oDefaults.sSelectorRenderImage, domElement)
		.each(function () {
			$item = jQuery(this);

			// Items mit fixed scope ignorieren!!! (z.B. die Renderbilderattribute 'data-renderimage-view' der Specials)
			if ($item.attr('data-renderimage-context') === 'fixed') {
				return;
			}

			// replace image src with 1x1 pixel Base64 Image to aviod flickering
			$item.attr({
				'data-renderimage-view': viewName
			}); // ,src:'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',alt:''});
		});

	// trigger Event for Image updates
	__.eventBus.emit(appEvents.UPDATE_VIEW_STATE, {
		'currentView': viewName,
		'domElement': domElement
	});
};

/**
 * handle page loaded
 * @param {Object} payload - TODO
 * @returns {void}
 */
__.handlePageLoaded = function (payload) {
	__.initializeOnDomElement(jQuery(payload.domElement || payload.element));
};

/**
 * reset stage
 * @param {Object} domElement - TODO
 * @param {Object} stage - TODO
 * @returns {void}
 */
__.resetStage = function (domElement, stage) {
	var dotNavi = stage.find(__.oDefaults.sSelectorDotNavi),
		bsNavi = domElement.find(__.oDefaults.sSelectorBsRemote);

	__.maxViewIndex = domElement.find(__.oDefaults.sSelectorMenuItems).length - 1;

	if (__.maxViewIndex === 0) {
		dotNavi.addClass(__.oDefaults.sClassHidden);
		bsNavi.addClass(__.oDefaults.sClassHidden);
	}
	else {
		dotNavi.removeClass(__.oDefaults.sClassHidden);
		bsNavi.removeClass(__.oDefaults.sClassHidden);
	}
	__.viewNamesArr = __.buildViewIndexArr(domElement);
	__.isAnimating = false;
	__.currentView = domElement.find([__.oDefaults.sSelectorStageContainer, '>div', __.oDefaults.sSelectorStageContent].join('')).first();
	__.selectedCategory = __.currentView.attr(__.oDefaults.sAttrViewCategory);
	__.updateSelectionStatus(domElement, stage);

	// Update Scrollayer image only
	const basketImage = domElement.find('.nm-basket-image');
	if (basketImage.length) {
		__.updateRenderImageDataViewAttr(basketImage);
	}
};

/**
 * add module-specific events
 * @returns {void}
 */
__.addEvents = function() {
	// handle page animation end
	__.eventBus.on(appEvents.PAGE_LOADED, __.handlePageLoaded);
	__.eventBus.on(appEvents.LAYER_LOADED, __.handlePageLoaded);
	__.eventBus.on(appEvents.LAYER_CLOSED, __.handlePageLoaded); // called to reset default page-stage
};

/**
 * add stage events
 * @param {Object} stageElement - TODO
 * @returns {void}
 */
__.addStageEvents = function (stageElement) {
	// handle menu click
	stageElement.on('click.stage.menuitem', __.oDefaults.sSelectorMenuIcons, __.handleMenuClick);
	// handle Arrow clicks
	stageElement.on('click.stage.remote', __.oDefaults.sSelectorNext, __.handlePrevNextClick);
	stageElement.on('click.stage.remote', __.oDefaults.sSelectorPrevious, __.handlePrevNextClick);
	// handle dot click
	stageElement.on('click.stage.dot touchend.stage.dots', __.oDefaults.sSelectorDot, __.handleDotClick);
};

/**
 * dispose module
 * @returns {void}
 */
exports.dispose = function () {
	__.eventBus.off(appEvents.PAGE_LOADED, __.handlePageLoaded);
	__.eventBus.off(appEvents.LAYER_LOADED, __.handlePageLoaded);
};

exports.initialize = function (eventEmitter) {
	__.eventBus = eventEmitter;
	__.initialize();
};

export {exports as stage};
