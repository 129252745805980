
export default class InteractiveImageRotation extends HTMLElement {
	// constants
	static get SELECTOR_ROTATION_IMAGE() { return '.nm-rotation-image-container'; }
	static get CLASS_ROTATION_IMAGE_ACTIVE() { return 'nm-rotation-image-container-is-active'; }

	/**
	* constructor (private)
	* @returns {void}
	*/
	constructor() {
		super();

		this.numOfImages = 0;
		this.sliderControl = null;
		this.sliderContainer = null;
		this.controlContainer = null;
		this.sliderPastLine = null;

		// for calculation
		this.clickOffset = 0;

		this._activateDragging = this._activateDragging.bind(this);
		this._dragSliderControls = this._dragSliderControls.bind(this);
		this._deactivateDragging = this._deactivateDragging.bind(this);
	}

	/**
	* connectedCallback - callback for adding element to dom/shadow dom
	* @returns {void}
	*/
	connectedCallback() {
		this._initializeModule();
	}

	/**
	* _initializeModule (private) - inits module, bind events
	* @returns {void}
	*/
	_initializeModule() {
		this._initializeElements();
		this._bindControlEvents();
		if (this._isCircularControl()) {
			this._initCircularControl();
		}
	}

	/**
	* _initializeElements (private) - init members of class
	* @returns {void}
	*/
	_initializeElements() {
		this.numOfImages = this.querySelectorAll(InteractiveImageRotation.SELECTOR_ROTATION_IMAGE).length;
		this.sliderControl = this.querySelector('.nm-rotation-control');
		this.sliderContainer = this.querySelector('.nm-slider-container');
		this.controlContainer = this.querySelector('.nm-rotation-control-container');
		this.sliderPastLine = this.querySelector('.nm-rotation-control-past-line');
	}

	/**
	* _isCircularControl
	* @returns {boolean} - is circular control or not
	*/
	_isCircularControl() {
		return this.sliderControl.classList.contains("nm-rotation-control-is-circular");
	}

	/**
	* _initCircularControl
	* @returns {void}
	*/
	_initCircularControl() {
		let pastLineWidth = this._calculateInitialPastLineWidth();
		this.sliderPastLine.style.width = pastLineWidth + 'px';
	}

	/**
	* _calculateInitialPastLineWidth
	* @returns {Number} - width
	*/
	_calculateInitialPastLineWidth() {
		return this.controlContainer.clientWidth / 2 - this.sliderControl.clientWidth / 2;
	}

	/**
	* _bindControlEvents - bind event on controls
	* @returns {void}
	*/
	_bindControlEvents() {
		this.sliderControl.addEventListener('mousedown', this._activateDragging);
		this.sliderControl.addEventListener('touchstart', this._activateDragging);
	}

	/**
	* _activateDragging
	* @param {Event} event_ - touch or click event
	* @returns {void}
	*/
	_activateDragging(event_) {
		let clickPosX = this._calculateClickPosX(event_);
		let controlOffset = this._offsetOfElement(this.sliderControl).left;

		this.clickOffset = clickPosX - controlOffset;
		this._setEventListenersForStartDragging();
	}

	_calculateClickPosX(event_) {
		let clickPosX;

		if (event_.type === 'touchstart') {
			clickPosX = event_.touches[0].pageX;
		}
		else {
			clickPosX = event_.pageX;
		}

		return clickPosX;
	}

	/**
	* _offsetOfElement (private)
	* @param {HTMLElement} element_ - html element
	* @returns {Object} - offset object
	*/
	_offsetOfElement(element_) {
		let rect = element_.getBoundingClientRect();
		return {
			top: rect.top + document.body.scrollTop,
			left: rect.left + document.body.scrollLeft
		};
	}

	/**
	* _setEventListenersForStartDragging
	* @returns {void}
	*/
	_setEventListenersForStartDragging() {
		this.sliderControl.removeEventListener('mousedown', this._activateDragging);
		this.sliderControl.removeEventListener('touchstart', this._activateDragging);

		this.sliderContainer.addEventListener('mousemove', this._dragSliderControls);
		this.sliderContainer.addEventListener('touchmove', this._dragSliderControls);

		this.addEventListener('mouseup', this._deactivateDragging);
		this.addEventListener('touchend', this._deactivateDragging);
	}

	/**
	* _setEventListenersForStopDragging
	* @returns {void}
	*/
	_setEventListenersForStopDragging() {
		this.sliderControl.addEventListener('mousedown', this._activateDragging);
		this.sliderControl.addEventListener('touchstart', this._activateDragging);

		this.sliderContainer.removeEventListener('mousemove', this._dragSliderControls);
		this.sliderContainer.removeEventListener('touchmove', this._dragSliderControls);

		this.removeEventListener('mouseup', this._deactivateDragging);
		this.removeEventListener('touchend', this._deactivateDragging);
	}

	/**
	* _dragSliderControls
	* @param {Event} event_ - touchmove or mousemove event
	* @returns {void}
	*/
	_dragSliderControls(event_) {
		let sliderPositionX = this._calculateSliderPositionX(event_);

		this._rotateImageBySliderPosition(sliderPositionX);
		this._moveControlsBySliderPosition(sliderPositionX);
	}

	_calculateSliderPositionX(event_) {
		let controllerOffset = this._offsetOfElement(this.controlContainer).left;
		let sliderPositionX = 0;

		if (event_.type === 'touchmove') {
			sliderPositionX = event_.touches[0].pageX - controllerOffset - this.clickOffset;
		}
		else {
			sliderPositionX = event_.pageX - controllerOffset - this.clickOffset;
		}

		return sliderPositionX;
	}

	/**
	* _rotateImageBySliderPosition
	* @param {number} sliderPositionX_ - slider position
	* @returns {void}
	*/
	_rotateImageBySliderPosition(sliderPositionX_) {
		let percentile = this._calculatePercentagePositionOfSliderByPositionX(sliderPositionX_);
		let activeImageIndex = this._calculateActiveImageIndexByPercentage(percentile);

		this._setActiveImageByIndex(activeImageIndex);
	}

	/**
	* _moveControlsBySliderPosition
	* @param {number} sliderPositionX_ - slider position
	* @returns {void}
	*/
	_moveControlsBySliderPosition(sliderPositionX_) {
		let isInsidePossibleValueRange = this._isSliderControlOverMinimum(sliderPositionX_) && this._isSliderControlUnderMaximum(sliderPositionX_);

		if (isInsidePossibleValueRange) {
			this._updateSliderForSliderPosition(sliderPositionX_);
		}
	}

	/**
	* _updateSliderForSliderPosition - set slider style
	* @param {number} sliderPositionX_ - slider position
	* @returns {void}
	*/
	_updateSliderForSliderPosition(sliderPositionX_) {
		this.sliderControl.style.left = sliderPositionX_ + 'px';
		this.sliderPastLine.style.width = sliderPositionX_ + 'px';
	}

	/**
	* _isSliderControlOverLeftEnd
	* @param {number} value_ - slider value
	* @returns {boolean} - true if slider control is over minimum value
	*/
	_isSliderControlOverMinimum(value_) {
		return value_ >= -this.sliderControl.clientWidth / 2;
	}

	/**
	* _isSliderControlOverLeftEnd
	* @param {number} value_ - slider value
	* @returns {boolean} - true if slider control is over minimum value
	*/
	_isSliderControlUnderMaximum(value_) {
		return value_ <= this.controlContainer.clientWidth - this.sliderControl.clientWidth / 2;
	}

	/**
	* _calculatePercentagePositionOfSliderByPositionX
	* @param {number} sliderPositionX_ - slider position
	* @returns {number} - percentile value of rotation
	*/
	_calculatePercentagePositionOfSliderByPositionX(sliderPositionX_) {
		return (sliderPositionX_ + this.sliderControl.clientWidth / 2) / (this.controlContainer.clientWidth) * 100;
	}

	/**
	* _calculateActiveImageIndexByPercentage
	* @param {number} percentile_ - percentile of slider movement / image rotation
	* @returns {number} - calculated active image index
	*/
	_calculateActiveImageIndexByPercentage(percentile_) {
		return Math.min(this.numOfImages - 1, Math.max(0, Math.floor(percentile_ / (100 / this.numOfImages))));
	}

	/**
	* _setActiveImageByIndex
	* @param {number} index_ - index
	* @returns {void}
	*/
	_setActiveImageByIndex(index_) {
		let oldActiveImage = this.querySelector('.' + InteractiveImageRotation.CLASS_ROTATION_IMAGE_ACTIVE);

		if (oldActiveImage) {
			oldActiveImage.classList.remove(InteractiveImageRotation.CLASS_ROTATION_IMAGE_ACTIVE);
		}

		this.querySelectorAll(InteractiveImageRotation.SELECTOR_ROTATION_IMAGE)[index_].classList.add(InteractiveImageRotation.CLASS_ROTATION_IMAGE_ACTIVE);
	}

	/**
	* _deactivateDragging
	* @returns {void}
	*/
	_deactivateDragging() {
		this._setEventListenersForStopDragging();
	}

	/**
	* disconnectedCallback - callback for removing element from dom/shadow dom
	* @returns {void}
	*/
	disconnectedCallback() {
		this._removeAllEventListeners();
	}

	/**
	* _removeAllEventListeners
	* @returns {void}
	*/
	_removeAllEventListeners() {
		this.sliderControl.removeEventListener('mousedown', this._activateDragging);
		this.sliderControl.removeEventListener('touchstart', this._activateDragging);

		this.sliderContainer.removeEventListener('mousemove', this._dragSliderControls);
		this.sliderContainer.removeEventListener('touchmove', this._dragSliderControls);

		this.removeEventListener('mouseup', this._deactivateDragging);
		this.removeEventListener('touchend', this._deactivateDragging);

		this.sliderControl.removeEventListener('mousedown', this._activateDragging);
		this.sliderControl.removeEventListener('touchstart', this._activateDragging);
	}
}
customElements.define('audi-interactive-image-rotation', InteractiveImageRotation);
